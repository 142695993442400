import {
    INIT_DATA_READY,
} from '../actions/types';

export default (state = {}, action) => {
    switch (action.type) {

        case INIT_DATA_READY:
            return { ...state, ...action.payload.data };

        default:
            return { ...state };
    }
}