import {
    styled,
    media
} from '@payright/web-components';

export const SCPaymentSection = styled.div`
`;

export const SCPaymentContent = styled.div`
   ${media.min.wide} { 
        width:50%;
   }
   ${media.min.tablet} {
        width:50%;
   }
   ${media.max.wide} {
    width:100%;
   }
   margin-left:auto;
   margin-right: auto;
`;

export const SCMakePaymentContainer = styled.div`

   .alert-box-msg {
       margin-top: 1em;
   }
   .text-block {
       margin-top: 1em;
       margin-bottom: 1em;
   }

   strong {
       font-weight: 600;
   }
    .error {
        right: 0;
        bottom: 0;
        color: #ff3b3b;
        text-align: right;
        font-size: 0.86em;
        margin-top: 0.5em;
    }
    .column-sub-heading {
        & h5 {
            color: #531dff;
            padding-bottom: 1em;
        }
    }
    .radio-group {
        & label {
            padding-right: 1em;
            font-weight: 700;
        }
        & input {
            margin-right: 1em;
        }
    }
    .btn-section {
        margin-top: 1em;
    }
    .cancel-btn {
        display: inline-block;
        margin-right: 1em;
    }
    .save-btn {
        display: inline-block;
    }
    background-color: #fff;
    margin-top:5em;
    padding: 3em;

    .link {
        text-decoration: underline;
    }

    .page-title {
        color: #531dff;
        margin-bottom: 0.5em; 
    }

    .account-details {
        margin-top: 2em;
        display: grid;
        grid-template-columns: minmax(10px, 30%) auto max-content;
        align-items: center;
        column-gap: 0.5rem;
  
        h5 {
            color: #531dff;
        }

        .self-end {
            justify-self: flex-end;
        }
    }
    
    .payment-method-section {
        display: inline-flex;
        margin-top: 1.5em;
    }
    .payment-method-row  {
        margin-top: 1em;
    }
    .make-payment-section {
        border: 1px solid #f2f2f2;
        padding: 2em;
        margin: 1.5em 0;

        & strong {
            font-weight: 700;
        }
    }
    .make-payment-section .content {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
    .make-payment-section .content h4 {
        margin-bottom: 1em;
        color: 
    }
    .expiry-month-section {
        display: inline-grid;
        width: 30%;
        padding-right: 1em;
    }
    .expiry-year-section {
        display: inline-grid;
        width: 30%;
        padding-right: 1em;
    }
    .csv-section {
        display: inline-grid;
        width: 40%;
    }
    .btn-section {
        margin-top: 1em;
        text-align: right;
    }
    .cancel-btn {
        display: inline-block;
        margin-right: 1em;
    }
    .save-btn {
        display: inline-flex;
    }
    hr {
        border: 0; border-top: 1px solid #ccc;
        margin-top: 2em;
        margin-bottom: 2em;
    }
    .input-field {
        margin-bottom: 1em;
    }
    .card-expiration {
        margin-top: 1em;
    }
    .alerts {
        margin-bottom: 1em;
        margin-top: 1em;
    }
    .payment-success-section {
        text-align: center;
        p {
            text-align: center;
        }
        & .success-image {
            background-image: url(/static/media/loginImage.acd747a5.png);
            background-repeat: no-repeat;
            background-size: 200px;
            width: 200px;
            height: 200px;
            margin-left: auto;
            margin-right: auto;
        }
        .button-collection {
            
            width: 50%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 1em;

            button {
                margin-bottom: 1em;
            }
        }
    }

    ${media.max.small} {
        .account-details {
            grid-template-columns: auto;
            row-gap: 1em;

            .mobile-self-start {
                justify-self: flex-start;
            }
        }

        .make-payment-section .content {
            width: 100%;
        }
        .btn-section {
            width: 100%;
        }

        button {
            margin-top: 1em;
            max-width: 100%;
        }
    }

`;

export const SCPaymentMessageContent = styled.div`
   margin-top:2em;
   width: 80%;
   margin-left:auto;
   margin-right:auto;
`;