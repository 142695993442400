import React, { useEffect } from "react";
import styled from "styled-components";
import { Alert, IconAttention } from "@payright/web-components";
import history from "../../history";
import { useFeatures, FeatureFlags } from "../../providers/features-provider";

interface MaintenancePageProps {
  children?: React.ReactNode;
}

const SCMaintenancePage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .content {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
  }
`;

const MaintenancePage = (props: MaintenancePageProps) => {
  const { features, flagsConfig } = useFeatures();

  let heading = "Maintenance";
  let message =
    "Payright services are currently undergoing some maintenance, we will be back online shortly.";

  const maintenanceConfig = features.getValue(FeatureFlags.MAINTENANCE_MODE);

  if (maintenanceConfig) {
    const maintenanceJson = JSON.parse(maintenanceConfig as string);

    heading = maintenanceJson?.heading || heading;
    message = maintenanceJson?.message || message;
  }

  // Direct back to '/' is maintenance mode is off
  useEffect(() => {
    if (!flagsConfig[FeatureFlags.MAINTENANCE_MODE]) {
      history.push("/");
    }
  }, [flagsConfig]);

  return (
    <SCMaintenancePage>
      <div className="content">
        <Alert
          title={heading}
          body={
            <div>
              <p>{message}</p>
            </div>
          }
          outcome="error"
          icon={<IconAttention />}
        />
      </div>
    </SCMaintenancePage>
  );
};

export default MaintenancePage;
