import React from 'react';
import { SCSubmitButton } from './css';

const SubmitButtonText = ({text}) => {

    return(
        <SCSubmitButton>
            {text} <div className='loader'/>
        </SCSubmitButton>
    );
}

export default SubmitButtonText;