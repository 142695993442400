import {
    styled,
    media
} from '@payright/web-components';

export const SCFooter = styled.div`
    background: #240050;
    max-width: 1440px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    top:100%;
    margin-top:50px;
    height: 50px;

    ${media.max.large}{
        margin-top:0px;
        height: 90px;
        margin-bottom:20px;
    }

    .row-content {
        display: inline-block;
        padding: 1em;
        width: 100%;

        .logo {
            display: inline-block;
        }

        ul {
            display: inline-block;
            float:right;
        }
        li {
           display: inline-block;
           padding-left: 1em;
        }
        li a {
            color: #fff;
            font-size: 0.80em;
            text-decoration: none;
        }
        li a:hover {
            text-decoration: underline;
        }
    }
`;

export const Content = styled.div`
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;

    h3, h2 {
        color: #531dff;
    }
    h4, h3 {
        margin-bottom: 1em;
    }
    p {
        line-height: 1.6;
    }
    ol {
        list-style: decimal;
    }
    ul {
        list-style: outside;
    }
    .line-item {
        padding-left: 2em;
        line-height: 1.7em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
    }
    ol, ul {
        padding-left: 2em;
        line-height: 1.7em;
        padding-top: 1em;
        padding-bottom: 1em;
    }
`;
export const SCFinancialHardship = styled.div`
    height: 100%;
    width: 100%;
    iframe {
        width: 100%;
        height: -webkit-fill-available;
        overflow: hidden;
    }
`;