import {
  media,
  IconAppPhonesWithLines,
  IconGooglePlayStore,
  IconAppleStore,
} from "@payright/web-components";
import React from "react";
import styled from "styled-components";
import * as CONSTANTS from "../../util/constants/messages";
import AnnouncementCards from "../storyblok/AnnouncementCards";

const AppleStoreUrl = process.env.REACT_APP_APPLE_STORE_URL;
const GooglePlayStoreUrl = process.env.REACT_APP_GOOGLE_PLAY_STORE_URL;

const Banner = () => {
  const SCContentBox = styled.div`
    margin-top: 1em;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;

    ${media.min.large} {
      grid-template-columns: repeat(2, 1fr);
    }

    .grid-item {
      background-color: #ffffff;
      padding: 12px;

      .get-app {
        display: grid;
        grid-template-columns: 120px auto;
        align-items: center;
        gap: 0.5em;

        ${media.min.large} {
          grid-template-columns: 200px auto;
        }

        .get-app__icon {
          float: left;

          img {
            width: 100%;
            max-width: 120px;
            height: auto;

            ${media.min.large} {
              max-width: 200px;
            }
          }
        }

        .get-app__caption {
          display: flex;
          flex-direction: column;
          gap: 1em;

          .get-app__caption__heading {
            color: #531dff;
          }

          .get-app__caption__paragraph {
            font-size: 1em;
            font-weight: bold;
            line-height: 1.3;
            color: #531dff;
          }

          .get-app__caption__stores-icon {
            display: flex;
            align-items: center;

            a {
              svg {
                width: 90px;

                ${media.min.large} {
                  width: 130px;
                }

                ${media.min.wide} {
                  width: unset;
                }
              }

              img {
                width: 110px;

                ${media.min.large} {
                  width: 150px;
                }

                ${media.min.wide} {
                  width: 158px;
                }
              }
            }
          }
        }
      }

      .announcement {
        display: grid;
        grid-template-columns: 120px auto;
        align-items: center;
        gap: 1em;
        height: 100%;

        ${media.min.large} {
          grid-template-columns: 160px auto;
        }

        .announcement__icon {
          display: flex;
          justify-content: center;

          .announcement__icon__img {
            width: 100%;
            height: auto;
            max-width: 120px;

            ${media.min.large} {
              max-width: 140px;
            }
          }

          .announcement__icon__svg {
            padding: 0.8em;
          }
        }

        .announcement__caption {
          display: flex;
          flex-direction: column;
          gap: 1em;

          .announcement__caption__heading {
            color: #531dff;
          }

          .announcement__caption__paragraph {
            font-size: 1em;
            font-weight: bold;
            line-height: 1.3;
            color: #531dff;
          }
        }
      }
    }
  `;

  const region = process.env.REACT_APP_CURRENT_REGION;

  return (
    <SCContentBox>
      {region === "au" && (
        <div className="grid-item">
          <div className="get-app">
            <div className="get-app__icon">
              <IconAppPhonesWithLines />
            </div>
            <div className="get-app__caption">
              <h4 className="get-app__caption__heading">
                {CONSTANTS.DASHBOARD_ANNOUNCEMENT_APP_TITLE}
              </h4>
              <p className="get-app__caption__paragraph">
                {CONSTANTS.DASHBOARD_ANNOUNCEMENT_APP_CONTENT}
              </p>
              <div className="get-app__caption__stores-icon">
                <a
                  href={AppleStoreUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconAppleStore />
                </a>
                <a
                  href={GooglePlayStoreUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconGooglePlayStore />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      <AnnouncementCards />
    </SCContentBox>
  );
};

export default Banner;
