import { combineReducers } from 'redux';
import plans from './planReducer';
import planDetail from './planDetailReducer';
import transactionReducer from "./transactionReducer";
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import customerReducer from './customerReducer';
import paymentReducer from './paymentReducer';
import configReducer from './configReducer';

import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';

const createRootReducer = (history) => combineReducers({
	router: connectRouter(history),
	auth: authReducer,
	form: formReducer,
	error: errorReducer,
	pageData: customerReducer,
	plans: plans,
	planDetail: planDetail,
	transactions: transactionReducer,
	payments: paymentReducer,
	init: configReducer,
});

export default createRootReducer;

