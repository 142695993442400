import * as React from "react";
import Moment from "moment";
import Loader from "../Common/Loader";
import { fetchTransactions } from "../../actions/transactionAction";
import {
  CUSTOMER_MENU_ITEMS,
  TRANSACTION_TABLE_HEADER,
  EXTRATRANSACTION_TABLE_HEADER,
  TRANSACTION_FILTER,
  RECORDS_PER_PAGE,
  LOAD_PER_PAGE,
  TRANSACTION_NOT_FOUND,
  TRANSACTION_DEFAULT_FILTER_VALUE,
  SUB_MENU_ACTIONS
} from "../../util/constants/types";
import SCCustomerTransactionsTable, {
  SCCustomerTransaction,
  SCCustomerFilterTransaction
} from "./css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Paging } from "../../util/Paging";
import { TransactionMainRow } from "./TransactionMainRow";
import { TransactionSubRow } from "./TransactionSubRow";
import { Notifications } from "../Notifications/Notifications";
import { loadPageData } from "../../actions";
import * as CONSTANTS from "../../util/constants/messages";

import {
  HeaderCustomer,
  Navigation,
  Pagination,
  Button,
  Dropdown,
  IconExport
} from "@payright/web-components";
import Footer from "../Footer";
import ExportCSV from "./ExportCSV";
import "@progress/kendo-react-data-tools";
import "@progress/kendo-react-buttons";
import "@progress/kendo-react-dropdowns";
import "@progress/kendo-react-inputs";
import "@progress/kendo-react-dateinputs";
import "@progress/kendo-react-animation";
import "@progress/kendo-react-popup";
import "@progress/kendo-drawing";

const title = "Transactions";

class TransactionsList extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      recordsPerPage: RECORDS_PER_PAGE,
      selectedFilter: "Last 30 days",
      loadPerPage: LOAD_PER_PAGE
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  _exportCsvComponent;
  export = () => {
    this._exportCsvComponent.export();
  };

  handlePageChange(selectedPage) {
    this.setState({
      currentPage: Number(selectedPage.selected + 1)
    });
  }

  handleLoadMore() {
    this.setState({
      recordsPerPage: Number(this.state.recordsPerPage + this.state.loadPerPage)
    });
  }

  componentDidMount() {
    if (this.props.customer === null) {
      this.props.dispatch(loadPageData());
    }
    this.props.dispatch(fetchTransactions(TRANSACTION_DEFAULT_FILTER_VALUE));
  }

  handleDropDownSelect = value => {
    this.setState({ selectedFilter: value });
    this.props.dispatch(fetchTransactions(value));
    this.setState({ currentPage: 1 });
  };
  render() {
    const { error, transactions, loading } = this.props;
    if (transactions === null || loading) {
      return <Loader text={CONSTANTS.LOADING_TEXT} />;
    }
    const { currentPage, recordsPerPage, selectedFilter } = this.state;

    let paginationComponent;
    let pageNumbers;
    let showTransaction;

    if (error != null) {
      return <div>Error! {error.message}</div>;
    }
    // Get the data and divided into header and subheaders
    showTransaction = Paging(currentPage, recordsPerPage, transactions);
    const transactionListArray = TransactionMainRow(showTransaction);
    const transactionPartsArray = TransactionSubRow(showTransaction);

    pageNumbers = Math.ceil(transactions.length / recordsPerPage);
    for (let index = 0; index < transactionListArray.length; index++) {
      transactionListArray[index].requestCreated = Moment(
        transactionListArray[index].requestCreated
      ).format("LL");
    }

    if (pageNumbers > 1) {
      paginationComponent = (
        <Pagination
          pageCount={pageNumbers}
          handlePageChange={this.handlePageChange}
          handleLoadMore={this.handleLoadMore}
        />
      );
    }

    return (
      <>
        <div className="customer-navigation">
          <Navigation
            breakpoint="wide"
            portalType="customer"
            menuItems={CUSTOMER_MENU_ITEMS}
            activePage="/my-transactions"
            notifications={Notifications(this.props.notification)}
            accountDetailsActions={SUB_MENU_ACTIONS}
          />
          <HeaderCustomer title={title} />
        </div>

        <SCCustomerFilterTransaction>
          <div>
            <ExportCSV
              csvData={transactions}
              ref={exportCsvRef => {
                this._exportCsvComponent = exportCsvRef;
              }}
            />
          </div>

          <div className="containerTransaction">
            <div className="right-col">
              <div className="showingResult">
                <h5>Showing</h5>
              </div>
              <div>
                <h5>
                  <Dropdown
                    className="customDropDown"
                    options={TRANSACTION_FILTER}
                    value={selectedFilter}
                    handleSelect={this.handleDropDownSelect}
                  />
                </h5>
              </div>
              <div className="exportButton">
                <h5>
                  <Button
                    colour="blue"
                    size="small"
                    outline
                    handleClick={this.export}
                    icon={<IconExport />}
                    iconPosition="left"
                  >
                    Export{" "}
                  </Button>
                </h5>
              </div>
              <div></div>
            </div>
          </div>
        </SCCustomerFilterTransaction>

        {transactions.length > 0 ? (
          <SCCustomerTransaction>
            <div>
              <SCCustomerTransactionsTable
                tableHeaders={TRANSACTION_TABLE_HEADER}
                tableRows={transactionListArray}
                subTableHeaders={EXTRATRANSACTION_TABLE_HEADER}
                subTableRows={transactionPartsArray}
              />
            </div>
            <div>{paginationComponent}</div>
          </SCCustomerTransaction>
        ) : (
          <SCCustomerTransaction>
            <div className="noRecords">{TRANSACTION_NOT_FOUND}</div>
          </SCCustomerTransaction>
        )}
        <Footer />
      </>
    );
  }
}

const mapStateToProps = state => ({
  customer: state.pageData.customer,
  transactions: state.pageData.transactions,
  error: state.transactions.error,
  loading: state.transactions.loading,
  notification: state.pageData.notification
});

export default withRouter(connect(mapStateToProps)(TransactionsList));
