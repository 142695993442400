import React from 'react';
import {
    renderInput,
    renderInputCreditCard,
    renderDropDown
} from '../Common/FormFields';
import { reduxForm, formValueSelector, Field } from 'redux-form';

import {
    Button,
    IconQuestion,
} from '@payright/web-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { SCMakePaymentContainer } from './css';
import ReactTooltip from 'react-tooltip';
import { handleChangePaymentMethod } from '../../actions/paymentActions';
import * as CONSTANT from '../../util/constants/messages';

const handleCancleClick = (props) => {
    props.dispatch(handleChangePaymentMethod());
}

let AddPaymentMethod = (props) => {
    const { handleSubmit, showMessages, error, expiryMonth, expiryYear, cardNumber, cardType, submitting, addPaymentMethods, gateway, monthList, yearList } = props;
    return (
        <SCMakePaymentContainer>
            <h3 className='page-title'>Add New Card</h3>
            {gateway.service_provider === 'Eway' ?
                <form className="addPayment" onSubmit={handleSubmit}>
                    <div className="alerts">
                        {showMessages(addPaymentMethods, error)}
                    </div>
                    <Field
                        placeholder='Name on Card'
                        name='fullName'
                        label='Name on Card' 
                        type='text'
                        className='full-Name'
                        component={renderInput} />

                    <Field
                        placeholder='Card No'
                        name='cardNumber'
                        label='Card No'
                        type='text'
                        className='cardNumber'
                        cardNo={cardNumber}
                        cardType={cardType}
                        component={renderInputCreditCard} />

                    <div className='card-expiration'>
                        <div className='expiry-month-section'>
                            <Field
                                name="expiryMonth"
                                label="Expiry"
                                type="select"
                                className="expiryMonth"
                                defaultValue={expiryMonth}
                                options={monthList}
                                maxWidth="100%"
                                component={renderDropDown} />
                        </div>
                        <div className='expiry-year-section'>
                            <Field
                                placeholder='YYYY'
                                name="expiryYear"
                                label="&nbsp;"
                                type="select"
                                className="expiryYear"
                                defaultValue={expiryYear}
                                options={yearList}
                                component={renderDropDown} />
                        </div>
                        <div className='csv-section'>
                            <Field
                                placeholder='CVC'
                                name='cvnNumber'
                                label='CVN'
                                type='text'
                                className='cvnNumber'
                                component={renderInput} />
                            <div className='help-text'>
                                <p data-tip={CONSTANT.CVN_TOOL_TIP}><IconQuestion /> <span className='link'> What is this? </span></p>
                                <ReactTooltip />
                            </div>
                        </div>
                        <hr />

                    </div>
                    <div className='btn-section'>
                        <Link to='#' className='cancel-btn' onClick={() => handleCancleClick(props)}>Cancel</Link>
                        <Button colour='blue' type='submit' maxWidth="20%" disabled={submitting}>
                            {submitting ?
                                <>Submitting...</>
                                :
                                <>Add</>
                            }
                        </Button>
                    </div>
                </form>
                :
                <>
                    <iframe 
                        src={gateway.service_provider_details.iframe_url} 
                        height='400' 
                        width='100%'
                        title='cloud_payment'
                        >
                    </iframe>  
                </>
            }
        </SCMakePaymentContainer>
    );
}


AddPaymentMethod = reduxForm({
    form: 'AddPaymentMethod',
})(AddPaymentMethod);

const selector = formValueSelector('AddPaymentMethod');

const mapStateToProps = state => {
    return {
        expiryMonth: selector(state, 'expiryMonth'),
        fullName: selector(state, 'fullName'),
        cardNumber: selector(state, 'cardNumber'),
        expiryYear: selector(state, 'expiryYear'),
        cardType: selector(state, 'cardType'),
        cvn: selector(state, 'cvn'),
        addPaymentMethods: state.payments.addPaymentMethods,
        gateway: state.pageData.init.gateway,
        monthList: state.init.monthsList,
        yearList: state.init.futureYearList,
    }
};


AddPaymentMethod = connect(
    mapStateToProps,
)(AddPaymentMethod);

export default AddPaymentMethod;