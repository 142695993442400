/**
 *
 * @param date string (expected format: "MM/YY")
 * @returns boolean
 */
export const isCardExpired = (date: string) => {
    const today = new Date();
    const dateParsed = date.split('/');

    const expiryMonth = parseInt(dateParsed[0]) - 1;
    const expiryYear = parseInt('20' + dateParsed[1]);

    const expiryDate = new Date(expiryYear, expiryMonth, 1);

    return expiryDate <= today;
};