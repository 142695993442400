import loginImage from "./loginImage.png";
import {
  theme,
  styled,
  media,
} from '@payright/web-components';

const SCForgotDetails = styled.div`
    display: flex;
    justify-content: space-around;
    ${media.max.wide} {
    padding: 1.6em;
    }
    ${media.min.large} {
      align-items: center;
    }
    ${media.max.medium} {
      padding: 1.6em;
      .login-header {
        border-bottom: 1px solid ${props => theme.colours.grey.light};
        padding-bottom: 1em;
        svg {
          width: 130px;
          height: 33px;
        }
        ${media.max.large} {
          svg {
            width: 115px;
          }
        }
        h4 {
        }
      }
    }
    height: 100%;
    .ecommerce-image {
      background-image: url(${loginImage});
      width: 100%;
      height: 100%;
      min-height: 100vh;
      background-size: contain;
      background-repeat: no-repeat;
      background-position-y: center;
      max-width: 790px;
      ${media.max.large} {
        display: none;
      }
    }
    .login-wrapper {
      ${media.min.medium} {
        max-width: 364px;
      }
      width: 100%;
      .login-header {
        display: flex;
        justify-content: center;
        ${media.max.medium} {
          justify-content: space-between;
        }
        margin-bottom: 3em;
        .logo {
          display: flex;
          align-items: center;
          margin-right: 1.23em;
        }
        h4 {
          font-weight: normal;
          justify-content: flex-start;
          padding-left: 0.86em;
          display: flex;
          align-items: center;
          ${media.max.medium} {
            font-size: 1.266em;
            width: unset;
            color: ${props => theme.colours.plum.base};
          }
          ${media.min.medium} {
            border-left: 1px solid ${props => theme.colours.grey.light};
          }
        }
      }
      .forgot-password {
        text-align: center;
        margin-bottom: 3.33em;
        font-weight: bold;
        font-size: 0.86em;
        display: flex;
        justify-content: center;
        height: 45px;
        align-items: center;
        .spacer {
          border-left: 1px solid ${props => theme.colours.grey.light};
          margin: 0 1em;
          height: 45px;
        }
      }
      .input-field {
        margin-bottom: 2em;
      }
      .validation-msg-box {
        background-color: #ffd2d3;
        border-radius: 5px;
        padding: 0.68em;
        color: #ff3b3b;
        margin-bottom: 0.5em;
        width: 100%;
      }
      .buttonClass {
        display:flex;
        justify-content: space-evenly;

      }
      .filler {
        padding: 10px 10px 10px 10px;
      }
    }
  `;

export default SCForgotDetails;