export const TransactionSubRow = (transactionSubRowArray) => {
    const transactionPartsArrayData = transactionSubRowArray.map(transactionPartData => {
        const returnValue = transactionPartData.transactionsParts;
        if (returnValue !== undefined) {
            returnValue.map(payment => {
                if (payment.paidAmount < 0) {
                    payment.cssClass = 'ReversePaymentClassName';
                } else {
                    payment.cssClass = '';
                }
                return payment;
            })
            return returnValue;
        }
    });
    return transactionPartsArrayData;
}