import React from 'react';
import { reduxForm, SubmissionError, formValueSelector } from 'redux-form';

import PersonalDetails from './PersonalDetails';
import ChangePassword from './ChangePassword';
import SubmitButtonText from '../SubmitButton';
import axios from '../../util/payrightAxios';
import { SCCustomerForm } from './css';
import { connect } from 'react-redux';
import { FetchCustomerDetails } from '../../actions';

import { 
		 Button,
		 Alert,
		 IconDone,
		 IconAttention
		} from '@payright/web-components';

const submit = (values, dispatch) => {

	const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
	const customerId = localStorage.getItem('customerId');
 	const authToken = localStorage.getItem('authToken');
	const url = `${apiBaseUrl}/customer/${customerId}`;

	axios.defaults.headers.common['auth-token'] = authToken;

	return axios(url, {
		method: 'PUT',
		data: values,
	}).then(function(response) {

		dispatch({ type: 'PROFILE_UPDATE', payload: response.data });
		window.scrollTo(0, 0);

	}).catch((error) => {
		const validationsErrors = JSON.parse(error.response.data.message);
		validationsErrors._error = "Please update the details and resubmit it again";

		dispatch({ type: 'API_UPDATE_FAILURE', payload: error });
		window.scrollTo(0, 0);
		throw new SubmissionError(validationsErrors)
	});
}

const showMessages = (submitSucceeded, error) => {
	if (submitSucceeded) {
		return (<Alert
	           title="Update successfully.!"
	           body="Your profile details updated successfully.!"
	           outcome="positive"
	           icon={<IconDone />}
	    />);
	} else if (error) {
		return (<Alert
	           title="Oops..! Update failure"
	           body={error}
	           outcome="error"
	           icon={<IconAttention />}
	    />);
	}
}

let ProfileForm = (props) => {
	const { handleSubmit, submitSucceeded, error, pristine, submitting, init } = props;

	return (
		<SCCustomerForm>
			<form  className="profilePage" onSubmit={handleSubmit}>
				<div className="profile-content">
					<div className="alerts">
						{ showMessages(submitSucceeded, error) }
	          		</div>
				    <div className="content-section">
						<div className="left-side">
						    <h4>My Details</h4>
						</div>
						<div className="right-side">
							<PersonalDetails config={init}/>
						    <hr className="details-hr" />
						</div>
					</div>
					{/* <div className="content-section">
					    <div className="left-side">
					     	<h4>Employment <div className='special-char'> & </div> Income Details</h4>
					    </div>
					    <div className="right-side">
							<EmploymentDetails config={init}
							 /> 
					        <hr className="details-hr" />
					    </div>
		         	</div>
		         	<div className="content-section">
					    <div className="left-side">
					        <h4>Identification</h4>
					    </div>
					    <div className="right-side">
							<Identification config={init}
								identityType={identityType}
							/>
					        <hr className="details-hr" />
					    </div>
		         	</div>	 */}
		         	<div className="content-section">
					    <div className="left-side">
					        <h4>Change Password</h4>
					    </div>
					    <div className="right-side">
					        <ChangePassword />
					        <Button
				        		withShadow
				        		maxWidth="20%"
				        		type="submit"
								className='align-right'
								disabled={pristine || submitting }
				        		>
								{submitting ?
									<SubmitButtonText text='Submitting ....' /> 
								:
								 <> Save Changes </>
								}
				    		</Button>	
					    </div>
		         	</div>          	
				</div>
		    </form>
	    </SCCustomerForm>
	);
}

const selector = formValueSelector('ProfileForm');

ProfileForm =  reduxForm({
	form: 'ProfileForm',
	enableReinitialize : true,
	onSubmit: submit,
}) (ProfileForm);

// You have to connect() to any reducers that you wish to connect to yourself
ProfileForm = connect(
	state => ({
	  initialValues: state.pageData.customer, // pull initial values from account reducer
	  identityType: selector(state, 'identityType'),
	  init: state.init,
	}),
	{ FetchCustomerDetails }
  )(ProfileForm)

  export default ProfileForm;
  