import {
  styled,
  media,
  Table,
} from '@payright/web-components';

interface CustomerTransactionsTableProps {
  tableRows: any;
}

interface SCCustomerTransactionsTableProps { }

const SCCustomerTransactionsTable = styled(Table) <SCCustomerTransactionsTableProps>`

.ReversePaymentClassName {
  color:red;
}

  td.principle,
  td.fees,
  td.paymentAmount {
    color: ${props => props.theme.colours.blue.base};
  }
  td {
    &.procDate {
      h5 {
        svg {
          vertical-align: middle;
        }
      }
    }
    &.trType {
      h5 {
        svg {
          vertical-align: middle;
        }
      }
    }
  }

  tbody tr {
    flex-wrap: wrap;
    margin-bottom: 0.66em;
    ${media.max.medium} {
    h5 {
      font-size: 1em;
    }
  }
    td {
      flex: 33%;
    }
  }

    ${media.max.medium} {
    tbody {
        tr.row {
          td {
            padding: 0.8rem 1rem;
            &.requestCreated {
             flex-basis: 100%;
            }
            &.paymentPurpose {
             flex-basis: 100%;
            }
          }
        }
      }
    }
    ${media.min.medium} {

      .head {
        th {
          padding: 1rem 1.86em;
        }
      }
      .row {
        
        td {
          padding: 1rem 1.86em;
        }
      }
      border-collapse: inherit;
      border-spacing: 0 0.6rem;
      thead {
        tr {
          th {
            &:first-child {
              border-bottom-left-radius: 0.4em;
            }
            &:last-child {
              border-bottom-right-radius: 0.4em;
            }
          }
        }
      }
      tbody {
        tr.row {
          margin-bottom: 1em;
          td {
            &:first-child {
              border-bottom-left-radius: 0.4em;
              border-top-left-radius: 0.4em;
            }
            &:last-child {
              border-bottom-right-radius: 0.4em;
              border-top-right-radius: 0.4em;
            }
          }
        }
      }
    }
  }
`;

export const SCCustomerTransaction = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 1em auto 0;

  .noRecords {
    text-align: center;
    font-size: 1.0em;
    font-weight: bold;
  }
 `;

export const SCCustomerFilterTransaction = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  margin: 1em auto 0;
  
  
  .containerTransaction{
      display: flex;
      width: 100%;
      position: relative;
        
      .right-col {
       display: flex;
       flex-grow: 1;
       justify-content: flex-end;
       
      
       .showingResult {
          flex-basis: 10%;
          padding-left: 50px;
        h5 {
          font-size: 1.2em;
          color: ${props => props.theme.colours.grey.medium};
          font-weight: bold;
          text-align:center;
          padding-top: 12px;

          ${media.max.medium} {
            font-size: 0.9em;
            padding-top: 14px;
          }
        }
       }

       
     }
  }

  .containerTransaction > .right-col > .exportButton {
    padding-top: 5px;
    padding-right: 5px;
    ${media.max.medium} {
      font-size: 0.9em;
      padding-right: 10px;
    }
  }
`;

export default SCCustomerTransactionsTable;