import * as React from "react";
import { connect } from "react-redux";
// import { fetchPlans } from "../../actions/planActions";
import { loadPageData } from "../../actions";
import SCCustomerPlansTable from "./css";
import {
  SCCustomerPlans,
  SCHeaderButton,
  SCCustomerNoPlans,
  SCPlanSearch
} from "./css";
import { withRouter } from "react-router-dom";
import {
  CUSTOMER_MENU_ITEMS,
  PLAN_TABLE_HEADER,
  RECORDS_PER_PAGE,
  LOAD_PER_PAGE,
  PLAN_NOT_FOUND,
  SUB_MENU_ACTIONS
} from "../../util/constants/types";
import Loader from "../Common/Loader";
import { Paging } from "../../util/Paging";
import { FilterResult } from "./FilterResult";
import { SearchResult } from "./SearchResult";
import history from "../../history";
import { Notifications } from "../Notifications/Notifications";

import {
  HeaderCustomer,
  Navigation,
  Pagination,
  Search
} from "@payright/web-components";
import Footer from "../Footer";

const lowercaseKeys = require("lowercase-keys");
const title = "My Plans";

const options = {
  Active: false,
  Approved: false,
  Incomplete: false,
  Declined: false,
  Closed: false,
  Cancelled: false,
  Review: false
};

class Plan extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      searchItem: "",
      searchFilterOptions: [],
      currentPage: 1,
      planPerPage: RECORDS_PER_PAGE,
      loadPerPage: LOAD_PER_PAGE
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  handleChangeValue = value => {
    this.setState({ searchItem: value });
  };

  handleUpdateFilter = filterValues => {
    const filterObject = lowercaseKeys(filterValues.options);
    Object.keys(filterObject).map(obj => {
      if (filterObject[obj] === true) {
        return obj;
      } else {
        delete filterObject[obj];
      }
    });
    let validKeys = Object.keys(filterObject);
    validKeys = validKeys.map(planFilterStatus => {
      if (planFilterStatus === "incomplete") {
        return "pending";
      }
      return planFilterStatus;
    });

    this.setState({ searchFilterOptions: validKeys });
  };

  handlePageChange(selectedPage) {
    this.setState({
      currentPage: Number(selectedPage.selected + 1)
    });
  }

  handleLoadMore() {
    this.setState({
      planPerPage: Number(this.state.planPerPage + this.state.loadPerPage)
    });
  }

  handleMakeAPayemnt() {
    history.push("/payments");
  }

  componentDidMount() {
    if (this.props.customer === null) {
      this.props.dispatch(loadPageData());
    }
  }

  render() {
    const { error, loading, customer, plans } = this.props;
    if (customer === null) {
      return <Loader text="Loading..." />;
    }

    const filterState = this.state.searchFilterOptions;
    const { currentPage, planPerPage, searchItem } = this.state;

    let paginationComponent;
    let pageNumbers;
    let currentPlans;
    let showPlans;

    if (searchItem !== "") {
      currentPlans = SearchResult(plans, searchItem);
    } else if (filterState.length > 0) {
      currentPlans = FilterResult(plans, filterState);
    } else {
      currentPlans = plans;
    }

    if (error != null) {
      return <div>Error! {error.message}</div>;
    }

    showPlans = Paging(currentPage, planPerPage, currentPlans);
    pageNumbers = Math.ceil(currentPlans.length / planPerPage);

    if (pageNumbers > 1) {
      paginationComponent = (
        <Pagination
          pageCount={pageNumbers}
          handlePageChange={this.handlePageChange}
          handleLoadMore={this.handleLoadMore}
        />
      );
    }
    return (
      <>
        <div className="customer-navigation">
          <Navigation
            breakpoint="wide"
            portalType="customer"
            menuItems={CUSTOMER_MENU_ITEMS}
            activePage="/my-plans"
            notifications={Notifications(this.props.notification)}
            accountDetailsActions={SUB_MENU_ACTIONS}
          />
          <HeaderCustomer
            title={title}
            component={
              <SCHeaderButton
                maxWidth="209px"
                handleClick={this.handleMakeAPayemnt}
              >
                Make a Payment
              </SCHeaderButton>
            }
          />
        </div>

        <SCPlanSearch>
          <Search
            placeholder="Search"
            inputChangeHandler={this.handleChangeValue}
            filterCheckboxes={options}
            updateFiltersHandler={this.handleUpdateFilter}
          />
        </SCPlanSearch>

        {currentPlans.length > 0 ? (
          <SCCustomerPlans>
            <div>
              <SCCustomerPlansTable
                tableHeaders={PLAN_TABLE_HEADER}
                tableRows={showPlans}
              />
            </div>
            <div>{paginationComponent}</div>
          </SCCustomerPlans>
        ) : (
          <SCCustomerNoPlans>{PLAN_NOT_FOUND}</SCCustomerNoPlans>
        )}
        <Footer />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    plans: state.pageData.plans,
    loading: state.plans.loading,
    error: state.plans.error,
    notification: state.pageData.notification,
    customer: state.pageData.customer
  };
};

export default withRouter(connect(mapStateToProps)(Plan));
