export const Notifications = (notification) => {
    let notificationText = notification;
    if (typeof notificationText !== 'undefined') {
        notificationText.map(chkNotificationText => {
            if (chkNotificationText.text === undefined) {
                notificationText = '';
            }
        });
    }
    return notificationText;
}

