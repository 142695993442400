import axios from "../util/payrightAxios";
import {
    FETCH_TRANSACTION_BEGIN,
    FETCH_TRANSACTION_SUCCESS,
    FETCH_TRANSACTION_FAILURE,
} from '../actions/types';

export function fetchTransactions(filterValue) {
    return (dispatch, getState) => {
        const customerId = getState().auth.customerId == null ? localStorage.getItem('customerId') : getState().auth.customerId;
        const authToken = getState().auth.authToken == null ? localStorage.getItem('authToken') : getState().auth.authToken;

        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

        const url = `${apiBaseUrl}/customer/${customerId}/transactions/${filterValue}`;
        axios.defaults.headers.common['auth-token'] = authToken

        dispatch(fetchTransactionsBegin());
        return axios({
            url: url,
            method: "get",
        })
            .then(response => {
                dispatch(fetchTransactionsSuccess(response.data.data.transactions));
                return response.data.data.transactions;
            })
            .catch(error => {
                dispatch(fetchTransactionsFailure(error));
            });


    }
}

export const fetchTransactionsBegin = () => ({
    type: FETCH_TRANSACTION_BEGIN
})

export const fetchTransactionsSuccess = (transactions) => ({
    type: FETCH_TRANSACTION_SUCCESS,
    payload: { transactions }
})

export const fetchTransactionsFailure = error => ({
    type: FETCH_TRANSACTION_FAILURE,
    payload: { error }
})