import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from '../reducers';
import history from '../history';
import reduxThunk from 'redux-thunk';

declare global {
	interface Window { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;}
}

export default function configureStore(preloadedState?: any) {

  const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(
      applyMiddleware(
        routerMiddleware(history),
        reduxThunk
      ),
    ),
  )

	return store;
}
