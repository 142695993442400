import React from "react";
import { connect } from "react-redux";
import { loadPageData } from "../../actions";
import { makePaymentLoad } from "../../actions/paymentActions";

import { SCPaymentSection, SCPaymentContent } from "./css";
import {
  Navigation,
  IconDone,
  IconAttention,
  Alert
} from "@payright/web-components";
import {
  CUSTOMER_MENU_ITEMS,
  SUB_MENU_ACTIONS
} from "../../util/constants/types";
import MakePayment from "./MakePayment";
import Loader from "../Common/Loader";
import ChangePayment from "./ChangePayment";
import AddPaymentMethod from "./AddPaymentMethod";
import {
  handlePaymentSubmit,
  handleAddCardSubmit,
  handleSelectCard,
  handleChangeCardSubmit,
  handleChangePaymentMethod,
  handleAddNewCard
} from "../../actions/paymentActions";
import { Notifications } from "../Notifications/Notifications";
import * as CONSTANT from "../../util/constants/messages";
import Footer from "../Footer";

interface PaymentProps {
  customerId?: any;
  loadPageData(): void;
  overDuePayments?: any;
  paymentMethods?: any;
  defaultPaymentMethod?: any;
  token?: any;
  selectedCardNumber?: any;
  visibleMakePayment?: boolean;
  visibleChangePayment?: boolean;
  visibleAddNewCard?: boolean;
  handleChangePaymentMethodClick?: Function;
  handleAddNewCardClick?: Function;
  dispatch?: Function;
  router?: any;
  totalBalanceOwing?: string;
  notification?: any;
}

class PaymentContainer extends React.Component<PaymentProps> {
  componentDidMount() {
    if (this.props.customerId === null) {
      this.props.loadPageData();
    }
    this.props.dispatch(makePaymentLoad());
    const mode = this.props.router.location.hash;
    if(mode === '#change-card') {
      this.props.dispatch(handleChangePaymentMethod());
    }
  }


  componentDidUpdate(newProps) {
    if (this.props.router.location.key !== newProps.router.location.key) {
      this.props.dispatch(makePaymentLoad());
    }
  }

  showMessages = (submitSucceeded, error, message) => {
    if (submitSucceeded) {
      return (
        <Alert
          title={CONSTANT.SUCCESS_ALERT_TITLE}
          body={message}
          outcome="positive"
          icon={<IconDone />}
        />
      );
    } else if (error) {
      return (
        <Alert
          title={CONSTANT.PAYMENT_FAILURE_TITLE}
          body={error}
          outcome="error"
          icon={<IconAttention />}
        />
      );
    }
  };

  showPaymentContent = payment => {
    if (this.props.visibleMakePayment) {
      return (
        <MakePayment
          defaultPaymentMethod={this.props.defaultPaymentMethod}
          handleChangePaymentMethod={this.props.handleChangePaymentMethodClick}
          paymentAmount={payment ? payment.paymentAmount : null}
          paymentId={payment ? payment.id : null}
          handlePayment={handlePaymentSubmit}
          onSubmit={handlePaymentSubmit}
          failureReason={payment ? payment.reasonForFailure : null}
          showMessages={this.showMessages}
          totalBalanceOwing={this.props.totalBalanceOwing}
        />
      );
    }

    if (this.props.visibleChangePayment) {
      return (
        <ChangePayment
          paymentMethods={this.props.paymentMethods}
          handleAddNewCard={this.props.handleAddNewCardClick}
          selectCard={handleSelectCard}
          selectedCardNumber={this.props.selectedCardNumber}
          onSubmit={handleChangeCardSubmit}
          showMessages={this.showMessages}
        />
      );
    }

    if (this.props.visibleAddNewCard) {
      return (
        <AddPaymentMethod
          showMessages={this.showMessages}
          onSubmit={handleAddCardSubmit}
        />
      );
    }
  };

  render() {
    const firstOverDuePayment =
      this.props.overDuePayments !== null &&
      this.props.overDuePayments.length > 0
        ? this.props.overDuePayments[0]
        : null;

    if (this.props.customerId !== null) {
      return (
        <SCPaymentSection>
          <Navigation
            breakpoint="wide"
            portalType="customer"
            menuItems={CUSTOMER_MENU_ITEMS}
            activePage="/payments"
            notifications={Notifications(this.props.notification)}
            accountDetailsActions={SUB_MENU_ACTIONS}
          />
          <SCPaymentContent>
            {this.showPaymentContent(firstOverDuePayment)}
          </SCPaymentContent>
          <Footer />
        </SCPaymentSection>
      );
    } else {
      return (
        <SCPaymentSection>
          <Loader text={CONSTANT.LOADING_TEXT} />
        </SCPaymentSection>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    customerId: state.auth.customerId,
    token: state.auth.authToken,
    isSignedIn: state.auth.isSignedIn,
    paymentMethods: state.pageData.paymentMethods,
    defaultPaymentMethod: state.pageData.defaultPaymentMethod,
    overDuePayments: state.pageData.overduePayments,
    selectedCardNumber:
      state.pageData.defaultPaymentMethod !== null
        ? state.pageData.defaultPaymentMethod.id
        : null,
    visibleMakePayment: state.payments.visibleMakePayment,
    visibleChangePayment: state.payments.visibleChangePayment,
    visibleAddNewCard: state.payments.visibleAddNewCard,
    router: state.router,
    totalBalanceOwing:
      state.pageData.customer !== null
        ? state.pageData.customer.totalBalanceOwing
        : null,
    notification: state.pageData.notification
  };
};

const mapDispatchToProps = dispatch => ({
  loadPageData: () => dispatch(loadPageData()),
  handleChangePaymentMethodClick: () => dispatch(handleChangePaymentMethod()),
  handleAddNewCardClick: () => dispatch(handleAddNewCard()),
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentContainer);
