import {
	FETCH_CUSTOMER,
	PROFILE_UPDATE,
	CHANGE_CARD,
	CHANGE_PAYMENT_METHOD_SUCCESS,
	CHANGE_PAYMENT_METHOD,
	ADD_PAYMENT_METHOD,
	PAY_NOW_BUTTON_CLICK,
	ADD_NEW_PAYMENT_METHOD_SUCCESS,
	SIGN_OUT,
	PAYMENT_SUCCESS,
	RESET_PASSWORD,
	GO_BACK_TO_MAKE_PAYMENT,
	MAKE_PAYMENT_LOAD,
	FETCH_TRANSACTION_SUCCESS,
	RESET_PASSWORD_COMPLETED,
	FETCH_STATEMENT_SUCCESS,
} from '../actions/types';

const INITIAL_STATE = {
	customer: null,
	selectedOverDuePayment: null,
	nextPayments: null,
	paymentMethods: null,
	defaultPaymentMethod: null,
	selectedCardNumber: null,
	visibleMakePayment: true,
	visibleChangePayment: false,
	visibleAddNewCard: false,
	overduePayments: null,
	previousActivePaymentMethod: null,
	init: null,
	plans: null,
	notifications: null,
	transactions: null,
	needResetPassword: true,
	temp_customer: null,
	statement: null,
}

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {

		case SIGN_OUT:

			return INITIAL_STATE;

		case FETCH_CUSTOMER:

			const selectedOverDuePayment = action.payload.data.overduePayments.length > 0 ? action.payload.data.overduePayments[0] : null;

			return {
				...state,
				...action.payload.data,
				selectedOverDuePayment: selectedOverDuePayment,
			};

		case PROFILE_UPDATE:

			return { ...state, customer: action.payload.data.customer };

		case RESET_PASSWORD:

			return { ...state, temp_customer: action.payload.data.customer };

		case RESET_PASSWORD_COMPLETED:

			return { ...state, customer: state.temp_customer };


		case CHANGE_CARD:

			const paymentMethods = state.paymentMethods;
			const selectedCard = state.paymentMethods.filter((method) => method.id === action.paymentId);
			let previousActivePaymentMethod = null;

			paymentMethods.map((method, i) => {
				if (method.enabled) {
					paymentMethods[i].enabled = false;
					previousActivePaymentMethod = paymentMethods[i];
				}
				if (method.id === action.paymentId) {
					paymentMethods[i].enabled = true;
				}
			})

			return {
				...state,
				paymentMethods: paymentMethods,
				defaultPaymentMethod: selectedCard[0],
				previousActivePaymentMethod: previousActivePaymentMethod,
			}
		case ADD_NEW_PAYMENT_METHOD_SUCCESS:

			state.paymentMethods.map((method, i) => {
				if (method.enabled) {
					state.paymentMethods[i].enabled = false;
				}
			});
			state.paymentMethods.unshift(action.payload.data);

			return {
				...state,
				paymentMethods: state.paymentMethods,
				defaultPaymentMethod: action.payload.data,
			}

		case CHANGE_PAYMENT_METHOD_SUCCESS:

			return {
				...state,
				visibleMakePayment: true,
				visibleChangePayment: false,
				visibleAddNewCard: false,
			}

		case CHANGE_PAYMENT_METHOD:

			return {
				...state,
				visibleMakePayment: false,
				visibleChangePayment: true,
				visibleAddNewCard: false,
			}

		case ADD_PAYMENT_METHOD:

			return {
				...state,
				visibleMakePayment: false,
				visibleChangePayment: false,
				visibleAddNewCard: true,
			}

		case PAY_NOW_BUTTON_CLICK:

			let selectedPayment = state.overduePayments.filter((payment) => payment.id === action.paymentId);

			return {
				...state,
				selectedOverDuePayment: selectedPayment.length > 0 ? selectedPayment[0] : null,
			}

		case PAYMENT_SUCCESS:

			if (state.overduePayments.length > 0) {
				const remainingPayments = [...state.overduePayments];
				let payment = remainingPayments.filter((payment) => payment.id === action.payload.data.paymentId);
				const paidIndex = remainingPayments.indexOf(payment[0]);

				const paidPayment = remainingPayments[paidIndex];

				state.customer.overDuePaymentsAmount = parseFloat(state.customer.overDuePaymentsAmount) - parseFloat(paidPayment.paymentAmount);
				state.overduePayments.splice(paidIndex, 1);
			}

			if (action.payload.data.paymentType === 'once_off') {
				state.customer.totalBalanceOwing = parseFloat(state.customer.totalBalanceOwing) - parseFloat(action.payload.data.paymentAmount);
			}

			return {
				...state,
				overduePayments: state.overduePayments,
				selectedOverDuePayment: typeof state.overduePayments[0] !== 'undefined' ? state.overduePayments[0] : null,
			};

		case RESET_PASSWORD:
			return { ...state, customer: action.payload.data };

		case GO_BACK_TO_MAKE_PAYMENT:
			const prevActiveCard = state.previousActivePaymentMethod !== null ? state.previousActivePaymentMethod : state.defaultPaymentMethod;
			const pMethods = state.paymentMethods;

			if (pMethods !== null && prevActiveCard !== null) {
				pMethods.map((method, i) => {
					if (method.enabled) {
						pMethods[i].enabled = false;

					}
					if (method.id === prevActiveCard.id) {
						pMethods[i].enabled = true;
					}
				})
			}

			return {
				...state,
				paymentMethods: pMethods,
				defaultPaymentMethod: prevActiveCard,
			};
		case MAKE_PAYMENT_LOAD:
			const activeCard = state.defaultPaymentMethod;
			
			return {
				...state,
				defaultPaymentMethod: activeCard,
			}

		case FETCH_TRANSACTION_SUCCESS:
			return {
				...state,
				transactions: action.payload.transactions,
			}

		case FETCH_STATEMENT_SUCCESS:
			return {
				...state,
				statement: action.payload,
			}
		default:

			return { ...state };
	}
}