import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { HeaderCustomer, Navigation } from "@payright/web-components";

import {
  CUSTOMER_MENU_ITEMS,
  SUB_MENU_ACTIONS
} from "../../util/constants/types";

import { loadPageData } from "../../actions";
import StatementForm from "./StatementForm";
import Loader from "../Common/Loader";
import Footer from "../Footer";
const title = "Your Statement";

class Statements extends React.Component<any, any> {
  componentDidMount() {
    if (this.props.customer === null) {
      this.props.loadPageData();
    }
  }

  render() {
    if (this.props.customer) {
      return (
        <div>
          <div className="customer-navigation">
            <Navigation
              breakpoint="wide"
              portalType="customer"
              menuItems={CUSTOMER_MENU_ITEMS}
              activePage="/statements"
              accountDetailsActions={SUB_MENU_ACTIONS}
            />
            <HeaderCustomer title={title} />
            <StatementForm />
          </div>
          <Footer />
        </div>
      );
    } else {
      return <Loader text="Fetching customer details..." />;
    }
  }
}

const mapStateToProps = state => ({
  customer: state.pageData.customer,
  statement: state.pageData.statement,
  error: state.transactions.error,
  loading: state.transactions.loading,
  notification: state.pageData.notification
});

export default withRouter(
  connect(mapStateToProps, { loadPageData })(Statements)
);
