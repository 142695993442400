import * as React from 'react';
import Moment from 'moment';
import { ExcelExport, ExcelExportColumn, ExcelExportColumnGroup } from '@progress/kendo-react-excel-export';


class ExportCSV extends React.Component {

    props = { csvData: [] }
    constructor(props) {
        super(props);
    }

    _exporter;
    export = () => {
        this._exporter.save();
    }

    render() {
        let returnVal = [];
        for (let index = 0; index < this.props.csvData.length; index++) {

            const DateProcessedRaw = this.props.csvData[index].transactionList.requestCreated;
            const formattedDate = Moment(DateProcessedRaw).format("LL");

            returnVal.push(
                {
                    DateProcessed: formattedDate,
                    PaymentDescription: this.props.csvData[index].transactionList.paymentPurpose,
                    PaymentReference: this.props.csvData[index].transactionList.paymentReference,
                    PaymentType: this.props.csvData[index].transactionList.paymentStatus,
                    PaymentAmount: this.props.csvData[index].transactionList.paymentAmount
                },
            );

            for (let j = 0; j < this.props.csvData[index].transactionsParts.length; j++) {

                const DateApplicationRaw = this.props.csvData[index].transactionsParts[j].dateApplied;
                const formattedApplicationDate = Moment(DateApplicationRaw).format("LL");

                returnVal.push(
                    {
                        DateOfApplication: formattedApplicationDate,
                        Reason: this.props.csvData[index].transactionsParts[j].transactionReason,
                        Relatesto: this.props.csvData[index].transactionsParts[j].paymentPlanName,
                        Payment: this.props.csvData[index].transactionsParts[j].paidAmount,
                        Payment1: this.props.csvData[index].transactionsParts[j].paidAmount

                    });

            }
            returnVal.push({ '': '' });
        }

        let now = Moment();
        const formattedFileName = 'payrighttransaction_' + now.format("YYYYMMDDHHmmss") + '.xlsx';

        return (
            <ExcelExport
                data={returnVal}
                fileName={formattedFileName}
                filterable={false}
                ref={(exporterRef) => { this._exporter = exporterRef; }}
            >
                <ExcelExportColumn field="DateProcessed" title="Date Processed" width={120} />
                <ExcelExportColumn field="PaymentDescription" title="Payment Description" width={150} />
                <ExcelExportColumn field="PaymentReference" title="Payment Reference" width={150} />
                <ExcelExportColumn field="PaymentType" title="Payment Type" width={120} />
                <ExcelExportColumn field="PaymentAmount" title="Payment Amount" width={120} />
                <ExcelExportColumnGroup
                    title="Transaction Details"
                    headerCellOptions={{ textAlign: 'center', background: '#531DFF' }}
                >
                    <ExcelExportColumn field="DateOfApplication" title="Date" width={120} cellOptions={{
                        color: '#531DFF',
                        textAlign: 'center'

                    }} headerCellOptions={{
                        background: '#531DFF',
                        textAlign: 'center'
                    }} />
                    <ExcelExportColumn field="Reason" title="Transaction Type" width={200} cellOptions={{
                        color: '#531DFF',
                        textAlign: 'center'
                    }} headerCellOptions={{
                        background: '#531DFF',
                        textAlign: 'center'
                    }} />
                    <ExcelExportColumn field="Relatesto" title="Relates To" width={120} cellOptions={{
                        color: '#531DFF',
                        textAlign: 'center'
                    }} headerCellOptions={{
                        background: '#531DFF',
                        textAlign: 'center'
                    }} />
                    <ExcelExportColumn field="Payment" title="Amount ($)" width={120} cellOptions={{
                        color: '#531DFF',
                        textAlign: 'center'

                    }} headerCellOptions={{
                        background: '#531DFF',
                        textAlign: 'center'
                    }} />
                </ExcelExportColumnGroup>
            </ExcelExport>
        )
    }
}

export default ExportCSV;
