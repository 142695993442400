import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { makePaymentLoad } from "../../actions/paymentActions";

import {
  HeaderCustomer,
  HeaderCustomerDashboard,
  CustomerDashboard,
  Navigation,
  IconDone,
  IconError,
  Alert,
} from "@payright/web-components";

import { PageContent, SCPaymentSection, SCDashboard } from "./css";
import {
  CUSTOMER_MENU_ITEMS,
  USER_PASSWORD_STATUS,
  SUB_MENU_ACTIONS,
} from "../../util/constants/types";
import { loadPageData } from "../../actions";
import Loader from "../Common/Loader";
import Modal from "react-modal";
import MakePayment from "../Payment/MakePayment";
import ChangePayment from "../Payment/ChangePayment";
import AddPaymentMethod from "../Payment/AddPaymentMethod";
import ResetPassword from "../ResetPassword/ResetPassword";
import { Notifications } from "../Notifications/Notifications";

import * as CONSTANTS from "../../util/constants/messages";

import {
  handleAddCardSubmit,
  handlePaymentSubmit,
  handleSelectCard,
  handleChangeCardSubmit,
  handleChangePaymentMethod,
  handleAddNewCard,
  handlePayNowClick,
} from "../../actions/paymentActions";
import { handleResetClosePopup } from "../../actions";
import Footer from "../Footer";
import Banner from "./Banner";

interface DashboardProps {
  customerId?: any;
  loadPageData(): void;
  customer?: any;
  overduePayments?: any;
  nextPayments?: any;
  paymentModalIsOpen?: boolean;
  defaultPaymentMethod?: any;
  paymentMethods?: any;
  selectedCardNumber?: any;
  visibleMakePayment?: boolean;
  visibleChangePayment?: boolean;
  visibleAddNewCard?: boolean;
  handleChangePaymentMethodClick?: Function;
  handleAddNewCardClick?: Function;
  handlePayNowClick?: Function;
  payNowButtonClick?: boolean;
  dispatch?: Function;
  selectedOverDuePayment?: any;
  totalBalanceOwing?: string;
  notification?: any;
  router?: any;
}

interface DashboardState {
  paymentModalIsOpen?: boolean;
  resetpasswordModalIsOpen?: boolean;
  clickChangePaymentMethod?: boolean;
  clickAddNewCard?: boolean;
}

const customStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "",
    maxHeight: "800px",
  },
  overlay: {
    zIndex: 15,
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    position: " fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },
};

const resetPasswordModalBoxStyle = {
  content: {
    bottom: "0",
    left: "0",
    margin: "auto",
    maxHeight: "500px",
    maxWidth: "600px",
    minWidth: "300px",
    position: "fixed",
    right: "0",
    top: "10%",
    backgroundColor: "white",
    borderRadius: "8px",
    border: "1px solid rgba(0, 0, 0, 0.3)",
    webkitBoxShadow: "0 3px 7px rgba(0, 0, 0, 0.3)",
    mozBoxShadow: "0 3px 7px rgba(0, 0, 0, 0.3)",
    boxShadow: "0 3px 7px rgba(0, 0, 0, 0.3)",
    webkiBackgroundClip: "padding- box",
    mozBackgroundClip: "padding - box",
    backgroundClip: "padding - box",
  },
  overlay: {
    zIndex: 9,
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    position: " fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

class Dashboard extends React.Component<DashboardProps, DashboardState> {
  componentDidMount() {
    if (this.props.customer === null) {
      this.props.loadPageData();
    }
    this.props.dispatch(makePaymentLoad());
  }
  componentDidUpdate(newProps) {
    if (this.props.router.location.key !== newProps.router.location.key) {
      this.closeModal();
    }
  }

  getPaymentContent = () => {
    const payment = this.props.selectedOverDuePayment;
    const paymentId =
      typeof payment !== "undefined" && payment !== null ? payment.id : null;
    const paymentAmount =
      typeof payment !== "undefined" && payment !== null
        ? payment.paymentAmount
        : null;
    const reasonForFailure =
      typeof payment !== "undefined" && payment !== null
        ? payment.reasonForFailure
        : null;

    if (this.props.visibleMakePayment) {
      return (
        <MakePayment
          defaultPaymentMethod={this.props.defaultPaymentMethod}
          handleChangePaymentMethod={this.props.handleChangePaymentMethodClick}
          paymentAmount={paymentAmount}
          paymentId={paymentId}
          handlePayment={handlePaymentSubmit}
          onSubmit={handlePaymentSubmit}
          showMessages={this.showMessages}
          failureReason={reasonForFailure}
          totalBalanceOwing={this.props.totalBalanceOwing}
        />
      );
    }

    if (this.props.visibleChangePayment) {
      return (
        <ChangePayment
          paymentMethods={this.props.paymentMethods}
          handleAddNewCard={this.props.handleAddNewCardClick}
          selectedCardNumber={this.props.selectedCardNumber}
          onSubmit={handleChangeCardSubmit}
          selectCard={handleSelectCard}
          showMessages={this.showMessages}
        />
      );
    }

    if (this.props.visibleAddNewCard) {
      return (
        <AddPaymentMethod
          showMessages={this.showMessages}
          onSubmit={handleAddCardSubmit}
        />
      );
    }
  };

  showMessages = (submitSucceeded, error) => {
    if (submitSucceeded) {
      return (
        <Alert
          title={CONSTANTS.SUCCESS_ALERT_TITLE}
          body={CONSTANTS.GENERAL_ALERT_SUCCESS_MSG}
          outcome="positive"
          icon={<IconDone />}
        />
      );
    } else if (error) {
      return (
        <Alert
          title={CONSTANTS.ERROR_ALERT_TITLE}
          body={error}
          outcome="negative"
          icon={<IconError />}
        />
      );
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      paymentModalIsOpen: false,
      resetpasswordModalIsOpen: true,
      clickChangePaymentMethod: false,
      clickAddNewCard: false,
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  openModal(paymentId) {
    this.props.dispatch(handlePayNowClick(paymentId));
    this.setState({ paymentModalIsOpen: true });
  }

  afterOpenModal() {}
  handleModalClose() {
    this.setState({
      resetpasswordModalIsOpen: !this.state.resetpasswordModalIsOpen,
    });
    this.props.dispatch(handleResetClosePopup());
  }

  closeModal() {
    this.setState({ paymentModalIsOpen: false });
  }

  render() {
    if (this.props.customer) {
      const title = `Hi ${this.props.customer.firstName}`;
      const defaultDashboardData = {
        plans: this.props.customer.numberOfActivePlans,
        balance: parseFloat(this.props.customer.totalBalanceOwing),
        overdue:
          this.props.customer.overDuePaymentsAmount !== null &&
          this.props.customer.overDuePaymentsAmount !== ""
            ? parseFloat(this.props.customer.overDuePaymentsAmount)
            : 0.0,
      };

      const showOverDuePaymentSection =
        this.props.overduePayments.length > 0 ? true : false;
      const overDuePayments = this.props.overduePayments;
      const nextPayments = this.props.nextPayments;
      const totalOverDuePayments = overDuePayments.length;

      return (
        <SCDashboard>
          <Navigation
            breakpoint="wide"
            portalType="customer"
            menuItems={CUSTOMER_MENU_ITEMS}
            activePage="/dashboard"
            notifications={Notifications(this.props.notification)}
            accountDetailsActions={SUB_MENU_ACTIONS}
          />
          <HeaderCustomer
            title={title}
            subtitle={CONSTANTS.PAGE_SUBTITLE}
            component={
              <HeaderCustomerDashboard dashboardData={defaultDashboardData} />
            }
          />
          <PageContent>
            {this.props.customer.passwordStatus === USER_PASSWORD_STATUS ? (
              <Modal
                isOpen={this.state.resetpasswordModalIsOpen}
                style={resetPasswordModalBoxStyle}
              >
                <ResetPassword handleModalClickEvent={this.handleModalClose} />
              </Modal>
            ) : null}

            <Modal
              isOpen={this.state.paymentModalIsOpen}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={this.closeModal}
              style={customStyles}
            >
              <SCPaymentSection>{this.getPaymentContent()}</SCPaymentSection>
            </Modal>

            {showOverDuePaymentSection ? (
              <>
                <h5>Overdue Payments {totalOverDuePayments}</h5>

                {overDuePayments.map((payment, i) => {
                  return (
                    <div key={i}>
                      <CustomerDashboard
                        key={i}
                        paymentAmount={payment.paymentAmount}
                        dueDate={new Date(payment.dueDate)}
                        overdue={true}
                        paymentFrequency={this.props.customer.paymentFrequency}
                        noOfOverdueDays={payment.daysOverdue}
                        handleClick={() => this.openModal(payment.id)}
                      />
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <div className="row">
                  <div className="item">
                    <img
                      src={CONSTANTS.DASHBOARD_PAYMENT_UPTO_DATE_IMAGE_PATH}
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <h3>{CONSTANTS.DASHBOARD_PAYMENT_UPTO_DATE_TITLE}</h3>
                    <div className="content">
                      {CONSTANTS.DASHBOARD_PAYMENT_UPTO_DATE_CONTENT}
                    </div>
                  </div>
                </div>
                <>
                  <br />
                  <Banner></Banner>
                </>
                <br />
              </>
            )}

            {nextPayments.length > 0 ? (
              <>
                <h5>Your Next Payment </h5>
                {nextPayments.map((payment, i) => {
                  return (
                    <CustomerDashboard
                      key={i}
                      dueDate={
                        payment.nextPaymentDate
                          ? new Date(payment.nextPaymentDate)
                          : ""
                      }
                      overdue={false}
                      paymentFrequency={this.props.customer.paymentFrequency}
                      paymentAmount={payment.repaymentAmount}
                      noOfOverdueDays={0}
                    />
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </PageContent>
          <Footer />
        </SCDashboard>
      );
    } else {
      return <Loader text={CONSTANTS.LOADING_TEXT} />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    customerId: state.auth.customerId,
    token: state.auth.authToken,
    isSignedIn: state.auth.isSignedIn,
    customer: state.pageData.customer,
    overduePayments: state.pageData.overduePayments,
    nextPayments: state.pageData.nextPayments,
    defaultPaymentMethod: state.pageData.defaultPaymentMethod,
    paymentMethods: state.pageData.paymentMethods,
    selectedCardNumber:
      state.pageData.defaultPaymentMethod !== null
        ? state.pageData.defaultPaymentMethod.id
        : null,
    visibleMakePayment: state.payments.visibleMakePayment,
    visibleChangePayment: state.payments.visibleChangePayment,
    visibleAddNewCard: state.payments.visibleAddNewCard,
    payNowButtonClick: state.pageData.payNowButtonClick,
    selectedOverDuePayment: state.pageData.selectedOverDuePayment,
    totalBalanceOwing:
      state.pageData.customer !== null
        ? state.pageData.customer.totalBalanceOwing
        : null,
    notification: state.pageData.notification,
    router: state.router,
  };
};

const mapDispatchToProps = (dispatch) => ({
  //FetchCustomerDetails: () => dispatch(FetchCustomerDetails()),
  loadPageData: () => dispatch(loadPageData()),
  handleChangePaymentMethodClick: () => dispatch(handleChangePaymentMethod()),
  handleAddNewCardClick: () => dispatch(handleAddNewCard()),
  handlePayNowClick: (paymentId) => dispatch(handlePayNowClick(paymentId)),
  dispatch,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
