import * as React from "react";
import { PlanStatus, Brand } from "@payright/web-components";
import { SCPlanDetailDashboard } from "./css";
import genericlogo from "./generic_logo1.png";

export interface PlanDetailDashboardProps {
  /** plan dashboard data */
  planDashBoardData: {
    planName: string;
    storeName: string;
    paymentFrequency: number;
    paidAmount: number;
    planStatus: string;
    remainingBalance: number;
    icon: string;
  };
}

const PlanDetailDashboard = ({
  planDashBoardData
}: PlanDetailDashboardProps) => (
  <SCPlanDetailDashboard>
    <div className="plan-container">
      <div className="left-col">
        <Brand
          reference={planDashBoardData.storeName}
          icon={genericlogo}
          name={planDashBoardData.planName}
        />
      </div>
      <div className="right-col">
        <div className="estPayments">
          <h6>Payments Remaining</h6>
          <h5>{planDashBoardData.paymentFrequency}</h5>
        </div>
        <div className="paid">
          <h6>Paid</h6>
          <h5>${planDashBoardData.paidAmount}</h5>
        </div>
        <div className="slash">
          <h5>/</h5>
        </div>
        <div className="remaining">
          <h6>Remaining</h6>
          <h5>${planDashBoardData.remainingBalance}</h5>
        </div>
        <div className="planStatus">
          <h6>Plan Status</h6>
          <h5>
            <PlanStatus status={planDashBoardData.planStatus} />
          </h5>
        </div>
      </div>
    </div>
  </SCPlanDetailDashboard>
);

export default PlanDetailDashboard;
