import {
    CHANGE_PAYMENT_METHOD_SUCCESS,
    CHANGE_PAYMENT_METHOD,
    ADD_PAYMENT_METHOD,
    ADD_NEW_PAYMENT_METHOD_SUCCESS,
    MAKE_PAYMENT_LOAD,
    PAYMENT_SUCCESS,
} from '../actions/types';

const INITIAL_STATE = {
    visibleMakePayment: true,
    visibleChangePayment: false,
    visibleAddNewCard: false,
    addPaymentMethodSuccess: false,
    changePaymentMethodSucess: false,
    paymentSuccess: false
}

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case CHANGE_PAYMENT_METHOD_SUCCESS:

            return {
                ...state,
                visibleMakePayment: true,
                visibleChangePayment: false,
                visibleAddNewCard: false,
            }

        case CHANGE_PAYMENT_METHOD:

            return {
                ...state,
                visibleMakePayment: false,
                visibleChangePayment: true,
                visibleAddNewCard: false,
                changePaymentMethodSucess: true,
            }

        case ADD_PAYMENT_METHOD:

            return {
                ...state,
                visibleMakePayment: false,
                visibleChangePayment: false,
                visibleAddNewCard: true,
            }
        case ADD_NEW_PAYMENT_METHOD_SUCCESS:

            return {
                ...state,
                visibleMakePayment: false,
                visibleChangePayment: true,
                visibleAddNewCard: false,
                addPaymentMethodSuccess: true,
            }

        case MAKE_PAYMENT_LOAD:
            return {
                ...state,
                visibleMakePayment: true,
                visibleChangePayment: false,
                visibleAddNewCard: false,
                addPaymentMethodSuccess: false,
                changePaymentMethodSucess: false,
                paymentSuccess: false,
            }

        case PAYMENT_SUCCESS:

            return {
                ...state,
                visibleMakePayment: true,
                visibleChangePayment: false,
                visibleAddNewCard: false,
                changePaymentMethodSucess: false,
                paymentSuccess: true,
            }

        default:

            return { ...state };
    }
}