import React from 'react';
import ContactUsForm from './ContactUsForm';
import { CustomerEnquiry } from '../../actions';
import { connect } from 'react-redux';
import { loadPageData } from '../../actions';
import {
    IconDone,
    IconAttention,
    Alert,
} from '@payright/web-components';
import * as CONSTANT from '../../util/constants/messages';
import Loader from '../Common/Loader';

class ContactUs extends React.Component<any, any>{

    showMessages = (submitSucceeded, error, message) => {
        if (submitSucceeded) {
            return (<Alert
                title={CONSTANT.SUCCESS_ALERT_TITLE}
                body={message}
                outcome="positive"
                icon={<IconDone />}
            />);
        } else if (error) {
            return (<Alert
                title={CONSTANT.ERROR_ALERT_TITLE}
                body={error}
                outcome="error"
                icon={<IconAttention />}
            />);
        }
    }

    componentDidMount() {
        if (this.props.customer === null) {
            this.props.loadPageData();
        }
    }

    render() {
        if (this.props.customer) {
            return (
                <ContactUsForm
                    onSubmit={CustomerEnquiry}
                    showMessages={this.showMessages}
                    notification={this.props.notification}
                />
            );
        } else {
            return (
                <Loader text={CONSTANT.LOADING_TEXT} />
              );
        }
    }
}

const mapStateToProps = state => {
    return {
        customer: state.pageData.customer,
        notification: state.pageData.notification,
    };
};

const mapDispatchToProps = dispatch => ({
    loadPageData: () => dispatch(loadPageData()),
    dispatch,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ContactUs);