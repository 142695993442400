import React from 'react';
import {
	Loader as PayrightLoader,
} from '@payright/web-components';
import { SCLoaderWrapper } from './css';

interface LoaderProps {
	text?: any;
}
const Loader = ({ text }: LoaderProps) => {

	return (
		<SCLoaderWrapper>
			<PayrightLoader text={text} />
		</SCLoaderWrapper>
	);
}

export default Loader;