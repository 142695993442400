import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import configureStore from "./configs/configureStore";
import history from "./history";
import App from "./components/App";
import * as Sentry from '@sentry/react';
import { FeaturesProvider } from "./providers/features-provider";

import LogRocket from "logrocket";

if ((process.env.REACT_APP_ENABLE_LOGGING || "").trim() === "true") {
  if (process.env.REACT_APP_LOGROCKET_ID) {
    LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);
  }
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    });
  }
}

const store = configureStore({});

ReactDOM.render(
  <Provider store={store}>
    <FeaturesProvider>
      <App history={history} />
    </FeaturesProvider>
  </Provider>,
  document.querySelector("#root")
);
