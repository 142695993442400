import { theme, media, styled, ButtonRounded } from "@payright/web-components";

interface SCCustomerDashboardProps {
  overdue: boolean;
  dueDate: Date;
}

export const SCDashboard = styled.div`
  min-height: 100vh;

  .content {
    button {
      border: 2px solid #49316b;
    }
  }
`;

export const SCCustomerDashboard = styled.div<SCCustomerDashboardProps>`
  box-shadow: 0 12px 24px 0 rgba(175, 187, 209, 0.22);
  background-color: ${(props) => props.theme.colours.white};
  padding: 1.06em;
  border-radius: 5px;
  margin-bottom: 2em;
  .overdue-capsule {
    margin-bottom: 1.06em;
    background-color: ${(props) => props.theme.colours.red.light};
    color: ${(props) => props.theme.colours.red.base};
    font-weight: bold;
    display: inline-flex;
    padding: 0 10px;
    height: 27px;
    align-items: center;
    border-radius: 27px;
    font-size: 0.73em;
  }
  .title {
    svg {
      display: none;
    }
    h5 {
      color: ${(props) => props.theme.colours.plum.base};
    }
    h6 {
      margin-top: 0.25em;
      color: #524fa1;
    }
    border-bottom: 1px solid
      ${(props) =>
        props.dueDate ? props.theme.colours.grey.altlight : "transparent"};
    padding-bottom: 1.06em;
    margin-bottom: 1.06em;
  }

  .payment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .amount {
      .label {
        display: none;
      }
      .value {
        font-size: 1.53em;
        color: ${(props) =>
          props.overdue
            ? props.theme.colours.red.base
            : props.theme.colours.blue.base};
      }
    }
    button {
      width: 110px;
      height: 45px;
    }
  }

  ${media.min.medium} {
    display: flex;
    justify-content: space-between;
    padding: 1.6em;
    align-items: center;
    .title {
      padding-bottom: 0;
      border-bottom: 0;
      margin-bottom: 0;
      h5 {
        font-size: 1.26em;
      }
      h6 {
        font-size: 1em;
      }
      order: 1;
    }
    .overdue-capsule {
      order: 2;
      margin-bottom: 0;
    }
    .payment {
      order: 3;
      button {
        margin-left: 2.2em;
      }
    }
  }

  ${media.min.large} {
    padding: 2.4em 2.66em;
    .overdue-capsule {
      height: 34px;
      font-size: 0.93em;
      padding: 0 1.3em;
      margin-right: 4.1em;
    }
    .title {
      display: flex;
      align-items: center;
      flex: 1;
      svg {
        display: block;
        margin-right: 2.66em;
      }
      h5 {
        font-size: 1.33em;
      }
    }
    .payment {
      .amount {
        display: flex;
        flex-direction: column;
        text-align: right;
        .label {
          font-weight: bold;
          display: block;
          margin-bottom: 0.3em;
        }
        .value {
          font-size: 2em;
        }
      }
      button {
        width: 132px;
        margin-left: 3.53em;
      }
    }
  }
`;

export const PageContent = styled.div`
  margin-top: 3em;
  padding-bottom: 2.5em;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  h5 {
    margin-top: 1em;
    margin-bottom: 1em;
    ${media.max.large} {
      margin-left: 0.2em;
    }
  }

  /* PT-209 */
  .row {
    display: table;
  }
  .item {
    padding-top: 1em;
    display: table-cell;
    vertical-align: middle;
    align-items: center;

    > h3 {
      font-size: 1.86em;
      color: #531dff;
    }

    &--header {
      color: #531dff;
      font-size: 1.43em;
      font-weight: bold;
    }

    > img {
      position: relative;
      top: 10px;
      right: 5px;
      height: 140px;
    }

    > .content {
      padding-top: 1.1em;
    }
  }
`;

export const DashboardContent = styled.div`
  margin-top: 1em;
`;

export const SCPaymentSection = styled.div`
  width: 100%;
`;

export const SCHeaderCustomerDashboard = styled.div`
  display: flex;
  color: ${(props) => theme.colours.white};
  h5 {
    font-size: 1.06em;
    margin: 0 1em;
    margin-bottom: 1em;
  }
  .balance {
    margin-left: 2em;
    &__wrapper {
      display: flex;
    }
    &__detail {
      padding-right: 0.3em;
      &:first-of-type {
        border-right: 1px solid ${(props) => theme.colours.plum.light};
      }
    }
  }
  .plans,
  .balance {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    button {
      ${media.min.tablet} {
        margin-top: 1.5em;
      }
      width: 100%;
    }
    .overdue {
      color: ${(props) => theme.colours.red.base};
    }
    .not-overdue {
      color: ${(props) => theme.colours.plum.light};
    }
    .value {
      font-size: 2.53em;
      &--mobile {
        display: none;
      }
    }
  }

  ${media.max.large} {
    h5 {
      font-size: 0.93em;
    }
    .plans,
    .balance {
      .value {
        font-size: 1.73em;
      }
    }
  }

  ${media.max.tablet} {
    flex-direction: column;
    button {
      max-width: fit-content;
    }
    width: 100%;
    margin-top: 2em;
    .plans,
    .balance {
      flex-direction: row;
      text-align: left;
      justify-content: space-between;
      align-items: center;
      button {
        height: unset;
        padding: 0.5em 1em;
      }
      .value {
        display: none;
        &--mobile {
          display: flex;
          align-items: center;
          padding-left: 1em;
        }
      }
      h5 {
        margin: 0;
        display: flex;
      }
    }
    .balance {
      margin-left: 0;
      border-top: 1px solid ${(props) => theme.colours.plum.light};
      padding-top: 1em;
      margin-top: 1em;
      &__wrapper {
        flex-direction: column;
        margin-right: 1em;
      }
      &__detail {
        padding-right: 0.3em;
        h5 {
          margin: 0.378em 0;
        }
        &:first-of-type {
          border-right: unset;
        }
      }
    }
  }
`;

export const SCHeaderCustomerDashboardButton = styled(ButtonRounded)`
  background-color: transparent;
  span {
    color: ${(props) => theme.colours.white};
  }
  border: 1px solid ${(props) => theme.colours.plum.light};
  font-size: 0.8em;
  height: 30px;
  transition: background-color 200ms ${(props) => theme.cubicBezier};
  &:hover {
    background-color: ${(props) => theme.colours.white}05;
  }
`;
