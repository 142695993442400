import _ from 'lodash';

export const SearchResult = (plans, searchItem) => {
    let searchPlansResult;
    searchPlansResult = _.filter(plans, plan => {
        let searchParam = searchItem;
        if (!isNaN(searchParam)) {
            searchParam = parseFloat(searchItem.toString());
        }
        return (plan.planName.name.toLowerCase().includes(searchItem.toLowerCase()) ||
            plan.planName.ref.toLowerCase().includes(searchItem.toLowerCase()) ||
            ((plan.payRemain >= (searchParam)) && (plan.payRemain <= (searchParam))) ||
            ((plan.paidLoanAmount >= (searchParam)) && (plan.paidLoanAmount <= (searchParam))) ||
            ((plan.balanceRemaining >= (searchParam)) && (plan.balanceRemaining <= (searchParam)))
        )
    });
    return searchPlansResult;
}