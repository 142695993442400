import React from 'react';

import SCEcommerceLogin from "./css";
import { Link } from 'react-router-dom';

import { reduxForm, Field, SubmissionError } from 'redux-form';
import axios from '../../util/payrightAxios';
import history from '../../history';
import { renderInput } from '../Common/FormFields';
import { connect } from 'react-redux';

import {
	Button,
	IconLogo,
} from '@payright/web-components';

const submit = (values, dispatch) => {

	const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
	const url = `${apiBaseUrl}/customer/login`;

	return axios(url, {
		method: 'POST',
		data: {
			username: values.username,
			password: values.password,
		},
	}).then(function (response) {

		dispatch({ type: 'SIGN_IN', payload: response });
		history.push('/dashboard');

	}).catch((error) => {

		dispatch({ type: 'API_UPDATE_FAILURE', payload: error });
		throw new SubmissionError({
			_error: "Invalid Username or Password. Please try again"
		})

	});
}

let LoginForm = (props) => {

	const { error, handleSubmit, pristine, submitting, apiErrors } = props;

	if (localStorage.getItem('isSignedIn')) {
		history.push('/dashboard');
	}


	return (
		<SCEcommerceLogin>
			<div className="ecommerce-image" />
			<div className="login-wrapper">
				<div className="login-header">
					<div className="logo">
						<IconLogo width={180} height={74} />
					</div>
					<h4>Login</h4>
				</div>
				<form onSubmit={handleSubmit} className="loginForm">
					{(error || apiErrors) && <div className="validation-msg-box">{error || apiErrors}</div>}
					<Field
						placeholder="Email address"
						name="username"
						label="Email address"
						type="text"
						component={renderInput} />

					<Field
						placeholder="Password"
						name="password"
						type="password"
						label="Password"
						component={renderInput} />

					<div className="forgot-password">
						<Link to="/forgot-password">
							<span className="hide-mobile">I've forgotten my password</span>
							<span className="mobile-only">Forgot password?</span>
						</Link>
					</div>
					<Button
						withShadow
						maxWidth="100%"
						type="submit"
						disabled={pristine || submitting}
					>
						Login
		          	</Button>
				</form>
			</div>
		</SCEcommerceLogin>
	)
}

LoginForm = reduxForm({
	form: 'LoginForm',
	onSubmit: submit
})(LoginForm);


const mapStateToProps = state => {
	return {
		apiErrors: state.error.errors,
	};
};

export default connect(
	mapStateToProps
)(LoginForm);

