import React from 'react';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { renderInput } from '../Common/FormFields';
import SCRestPassword from "./ResetPasswordCss";
import {
    Button,
    Alert,
    IconDone,
    IconError
} from '@payright/web-components';
import axios from '../../util/payrightAxios';
import { PASSWORD_SUCCESSFUL_TITLE, PASSWORD_NEXT_LOGIN } from '../../util/constants/types';


const submit = (values, dispatch) => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const customerId = localStorage.getItem('customerId');
    const authToken = localStorage.getItem('authToken');
    const url = `${apiBaseUrl}/customer/resetPassword/${customerId}`;

    axios.defaults.headers.common['auth-token'] = authToken;

    return axios(url, {
        method: 'PUT',
        data: values,
    }).then(function (response) {
        dispatch({ type: 'RESET_PASSWORD', payload: response.data });

    }).catch((error) => {
        const validationsErrors = JSON.parse(error.response.data.message);
        dispatch({ type: 'API_UPDATE_FAILURE', payload: error});
        throw new SubmissionError(validationsErrors)
    });
}

const showMessages = (submitSucceeded, error) => {
    if (submitSucceeded) {
        return (<Alert
            title={PASSWORD_SUCCESSFUL_TITLE}
            body={PASSWORD_NEXT_LOGIN}
            outcome="positive"
            icon={<IconDone />}
        />);
    } else if (error) {
        return (<Alert
            title="Ops..! Update failure"
            body={error}
            outcome="negative"
            icon={<IconError />}
        />);
    }
}

const ResetPassword = ({
    handleSubmit,
    pristine,
    submitting,
    handleModalClickEvent,
    submitSucceeded,
    formDataShow,
    error
}) => {

    if (submitSucceeded === false) {
        formDataShow = (
            <>
                <div className="resetPassword-header">
                    <h4>Generate New Password</h4>
                </div>

                <form className="ResetPassword" onSubmit={handleSubmit}>
                    <Field
                        placeholder="Temporary Password"
                        name="tempPassword"
                        label="Temporary Password"
                        type="password"
                        component={renderInput} />
                    <Field
                        placeholder="New Password"
                        name="newPassword"
                        label="New Password"
                        type="password"
                        component={renderInput} />
                    <Field
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        component={renderInput} />

                    <Button
                        withShadow
                        maxWidth="100%"
                        type="submit"
                        disabled={pristine || submitting}
                    >
                        Reset Password
             </Button>
                </form>
            </>
        )
    } else {
        formDataShow = (
            <>
                <div className="alerts">
                    {showMessages(submitSucceeded, error)}
                </div>

                <Button
                    withShadow
                    maxWidth="100%"
                    type="submit"
                    className="buttonClass"
                    handleClick={handleModalClickEvent}
                >
                    Close
          </Button>
            </>
        )
    }

    return (
        <SCRestPassword>
            <div className="resetPassword-wrapper">
                {formDataShow}

            </div>
        </SCRestPassword>
    )
}
export default reduxForm({
    form: 'ResetPassword',
    onSubmit: submit
})(ResetPassword);


