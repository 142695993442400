import {
	styled
} from '@payright/web-components';

export const SCAlertBox = styled.div `
    margin-top: 1em;
    margin-bottom: 1em;
    border: 1px solid #f44336;
    padding: 2em;
    border-radius: 0.5em;
    background-color: #FFECEB ;
    color: #f44336;
    line-height: 17pt;
    text-align: justify;
    display: inline-flex;
    width: 100%;

    .icon {
        display: inline-flex;
        width: 10%;
    }
    .content {
        display: inline-flex;
    }
`;