import {
	API_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
	errors: null
}

export default (state = INITIAL_STATE, action) => {

	switch (action.type) {
		case API_FAILURE:

			return { errors: action.payload.message };

		default:

			return { ...state };
	}
}