import {
	SIGN_IN,
	SIGN_OUT,
	FETCH_CUSTOMER,
} from '../actions/types';

const INITIAL_STATE = {
	isSignedIn: null,
	customerId: null,
	authToken: null,
}

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SIGN_IN:
			localStorage.setItem('customerId', action.payload.data.data.customer_id);
			localStorage.setItem('authToken', action.payload.data.data.auth_token);
			localStorage.setItem('isSignedIn', 'true');
			localStorage.setItem('saved', `${new Date().getTime()}`);
			return { ...state, isSignedIn: true, customerId: action.payload.data.data.customer_id, authToken :  action.payload.data.data.auth_token };

		case FETCH_CUSTOMER:
			return { ...state, isSignedIn: true, customerId: localStorage.getItem('customerId'), authToken :  localStorage.getItem('authToken') };

		case SIGN_OUT:
			localStorage.removeItem('customerId');
			localStorage.removeItem('authToken');
			localStorage.removeItem('isSignedIn');
			return { ...state, isSignedIn: false, customer: null, authToken: null };

		default:

			return { ...state, isSignedIn: localStorage.getItem('isSignedIn') };
	}
}