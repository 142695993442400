import React, { useRef, useState, useEffect } from "react";
import {
  SCShowExportPDF,
  SCCustomerFilterStatement,
  SCCustomerStatement,
  SCCustomerDateError
} from "./css";
import {
  TRANSACTION_NOT_FOUND,
  FROM_DATE_LESS_THAN_TO_DATE
} from "../../util/constants/types";
import { connect } from "react-redux";
import { PDFExport } from "@progress/kendo-react-pdf";
import Moment from "moment";
import { renderCalendarStandard } from "../Common/FormFields";

import { Button, IconExport } from "@payright/web-components";

import "@progress/kendo-react-data-tools";
import "@progress/kendo-react-buttons";
import "@progress/kendo-react-dropdowns";
import "@progress/kendo-react-inputs";
import "@progress/kendo-react-dateinputs";
import "@progress/kendo-react-animation";
import "@progress/kendo-react-popup";
import "@progress/kendo-drawing";
import axios from "../../util/payrightAxios";
import {
  COMPANY_BANK_DETAILS_AU,
  COMPANY_BANK_DETAILS_NZ
} from "../../util/constants/messages";

import {
  reduxForm,
  SubmissionError,
  submit as triggerSubmit
} from "redux-form";
import { Field } from "redux-form";
import ExportPDF from "./ExportPDF";
import ScreenPDF from "./ScreenPDF";

const submit = (values, dispatch) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const customerId = localStorage.getItem("customerId");
  const authToken = localStorage.getItem("authToken");
  const url = `${apiBaseUrl}/customer/${customerId}/transaction_statement/${values.fromDate}/${values.toDate}`;

  axios.defaults.headers.common["auth-token"] = authToken;

  return axios(url, {
    method: "GET",
    data: values
  })
    .then(function(response) {
      dispatch({
        type: "FETCH_STATEMENT_SUCCESS",
        payload: response.data.data
      });
      window.scrollTo(0, 0);
    })
    .catch(error => {
      const validationsErrors = JSON.parse(error.response.data.message);
      validationsErrors._error = "From Date Shold be less than To Date";

      dispatch({ type: "API_UPDATE_FAILURE", payload: error });
      window.scrollTo(0, 0);
      throw new SubmissionError(validationsErrors);
    });
};

let StatementForm = props => {
  const {
    error,
    customer,
    nextPayments,
    statement,
    handleSubmit,
    submitSucceeded,
    dispatch
  } = props;
  const exportStatementReference = useRef(null);
  const [autoExportOnRetrieve, setAutoExportOnRetrieve] = useState(false);
  // Export
  const onButtonClick = () => {
    if (exportStatementReference.current !== null) {
      exportStatementReference.current.save();
    }
  };

  // Action for mobile button
  const exportFromMobile = () => {
    dispatch(triggerSubmit("StatementForm"));
    setAutoExportOnRetrieve(true);
  };

  // Automatically exports to pdf if the autoExportOnRetrieve flag is set to true (i.e. the exportFromMobile buttton is clicked)
  useEffect(() => {
    if (
      exportStatementReference.current !== null &&
      autoExportOnRetrieve === true &&
      submitSucceeded === true
    ) {
      setAutoExportOnRetrieve(false); // Reset the flag
      onButtonClick();
    }
  }, [submitSucceeded, autoExportOnRetrieve, exportStatementReference]);

  const region = process.env.REACT_APP_CURRENT_REGION;

  const PDFExportPageTemplate = props => {
    return (
      <div>
        <div style={{ position: "absolute", top: "10px", left: "10px" }}>
          <header
            className="pdfheader"
            style={{ height: 200, background: "#EFF1F6" }}
          ></header>
        </div>
        <div style={{ position: "absolute", bottom: "10px" }}>
          <div
            style={{
              fontSize: "10px",
              color: "#5431FF",
              float: "left",
              marginLeft: "20px"
            }}
          >
            {" "}
            {props.pageNum} / {props.totalPages}
          </div>
          <div
            style={{
              fontSize: "10px",
              color: "#5431FF",
              float: "right",
              textAlign: "center",
              marginLeft: "250px"
            }}
          >
            {region === "au" ? (
              <span>{COMPANY_BANK_DETAILS_AU}</span>
            ) : (
              <span> {COMPANY_BANK_DETAILS_NZ} </span>
            )}
          </div>
        </div>
      </div>
    );
  };
  let displayStatement;
  let displayStatementForScreen;

  if (submitSucceeded === true) {
    if (statement !== null && statement.length > 0) {
      displayStatementForScreen = (
        <ScreenPDF
          statementRows={statement}
          customerRow={customer}
          nextPaymentRow={nextPayments}
        />
      );

      displayStatement = (
        <PDFExport
          paperSize="A4"
          margin={{ top: 0, bottom: 40 }}
          ref={exportStatementReference}
          scale={0.9}
          pageTemplate={PDFExportPageTemplate}
          fileName={
            "Payright_Statement_" + Moment().format("DDMMYYYY") + ".pdf"
          }
        >
          <div>
            <ExportPDF
              exportStatementRows={statement}
              exportCustomerStatementRow={customer}
              exportNextPaymentRow={nextPayments}
            />
          </div>
        </PDFExport>
      );
    } else {
      displayStatementForScreen = (
        <SCCustomerStatement>
          <div className="noRecords">{TRANSACTION_NOT_FOUND}</div>
        </SCCustomerStatement>
      );
    }
  } else if (error !== undefined) {
    displayStatementForScreen = (
      <SCCustomerDateError>{FROM_DATE_LESS_THAN_TO_DATE}</SCCustomerDateError>
    );
  }
  return (
    <>
      <SCCustomerFilterStatement>
        <form className="StatementForm" onSubmit={handleSubmit}>
          <div className="containerStatement">
            <div>
              <h5>
                <Field
                  label="From Date"
                  name="fromDate"
                  maxWidth="50%"
                  component={renderCalendarStandard}
                />
              </h5>
            </div>
            <div>
              <h5>
                <Field
                  label="To Date"
                  name="toDate"
                  maxWidth="50%"
                  component={renderCalendarStandard}
                />
              </h5>
            </div>
            <div className="statementButton">
              <h5>
                <Button
                  colour="blue"
                  size="small"
                  outline
                  icon={<IconExport />}
                  iconPosition="left"
                  type="submit"
                  className="export-btn"
                >
                  {" "}
                  Generate Statement
                </Button>
              </h5>
            </div>

            <div className="mobileStatementButton">
              <h5>
                <Button
                  type="button"
                  colour="blue"
                  size="small"
                  outline
                  handleClick={exportFromMobile}
                  className="export-btn"
                >
                  {" "}
                  Generate and export Statement
                </Button>
              </h5>
            </div>

            {submitSucceeded === true &&
            statement !== null &&
            statement.length > 0 ? (
              <div className="statementButton">
                <h5>
                  <Button
                    type="button"
                    colour="blue"
                    size="small"
                    outline
                    handleClick={onButtonClick}
                    icon={<IconExport />}
                    iconPosition="left"
                    className="export-btn"
                  >
                    {" "}
                    Export Statement
                  </Button>
                </h5>
              </div>
            ) : (
              ""
            )}
          </div>
        </form>
      </SCCustomerFilterStatement>

      <SCShowExportPDF>
        <div className="pdfMainContent pdf-screen-setting">
          {displayStatement}
        </div>
      </SCShowExportPDF>

      <SCShowExportPDF>
        <div className="pdfMainContent pdf-no-show-for-tablet">
          {displayStatementForScreen}
        </div>
      </SCShowExportPDF>
    </>
  );
};

StatementForm = reduxForm({
  form: "StatementForm",
  enableReinitialize: true,
  onSubmit: submit
})(StatementForm);

const mapStateToProps = function(state) {
  return {
    initialValues: {
      fromDate: Moment()
        .subtract(1, "days")
        .subtract(1, "months")
        .format("YYYY-MM-DD"),
      toDate: Moment().format("YYYY-MM-DD")
    },
    statement: state.pageData.statement,
    customer: state.pageData.customer,
    nextPayments: state.pageData.nextPayments[0],
    error: state.pageData.error
  };
};
export default connect(mapStateToProps)(StatementForm);
