import React from 'react';
import {
	SIGN_OUT
} from '../../actions/types';
import history from '../../history';
import { connect } from 'react-redux';

interface LogoutProps {
	dispatch: any;
}

class Logout extends React.Component<LogoutProps> {

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch({ type: SIGN_OUT });
		history.push('/');
	}

	render() {
		return(
			<div></div>
		);
	}
}

const mapStateToProps = state => {
  return {
  };
};

export default connect(
	mapStateToProps
)(Logout);
