import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { HeaderCustomer, Navigation } from "@payright/web-components";

import {
  CUSTOMER_MENU_ITEMS,
  SUB_MENU_ACTIONS
} from "../../util/constants/types";

import { SCProfile } from "./css";

import { loadPageData } from "../../actions";
import ProfileForm from "./ProfileForm";
import Loader from "../Common/Loader";
import Footer from "../Footer";

interface ProfileProps {
  customerId?: any;
  loadPageData(): void;
  customer?: any;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  phoneMobile: string;
  dateOfBirth: string;
  street: string;
  suburb: string;
  state: string;
  postcode: string;
  residentialStatus: string;
  employmentType: string;
  employerName: string;
  employerPhoneNumber: string;
  timeInJob: string;
  paymentFrequency: string;
  totalIncome: string;
  otherIncome: string;
  licenseNo: string;
  licenceState: string;
  expiry: string;
}

class Profile extends React.Component<ProfileProps> {
  componentDidMount() {
    if (this.props.customer === null) {
      this.props.loadPageData();
    }
  }

  render() {
    if (this.props.customer) {
      return (
        <SCProfile>
          <div className="profile-content">
            <Navigation
              breakpoint="wide"
              portalType="customer"
              menuItems={CUSTOMER_MENU_ITEMS}
              activePage="account"
              accountDetailsActions={SUB_MENU_ACTIONS}
            />
            <HeaderCustomer title="Account Profile" />
            <ProfileForm />
          </div>
          <Footer />
        </SCProfile>
      );
    } else {
      return <Loader text="Fetching customer details..." />;
    }
  }
}

const mapStateToProps = state => {
  return {
    customerId: state.auth.customerId,
    token: state.auth.authToken,
    isSignedIn: state.auth.isSignedIn,
    customer: state.pageData.customer
  };
};

export default withRouter(connect(mapStateToProps, { loadPageData })(Profile));
