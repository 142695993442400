import React, {
  useContext,
  useMemo,
  useEffect,
  useState,
  createContext,
  ReactNode,
} from "react";
import { useSelector } from "react-redux";
import flagsmith from "flagsmith";
import { IFlagsmith, IFlags, IRetrieveInfo, IState } from "flagsmith/types";
import history from "../../history";

type FeaturesProviderProps = {
  children?: ReactNode;
};

// Subset of the initialisation options for Flagsmith
// https://docs.flagsmith.com/clients/javascript/#initialisation-options
type FlagsmithConfig = {
  environmentID: string; // your Flagsmith environment id
  api?: string; // the api you wish to use, important if self hosting
  headers?: object; // pass custom headers for flagsmith api calls
  AsyncStorage?: any; // an AsyncStorage implementation
  cacheFlags?: boolean; // whether to local storage flags, needs AsyncStorage defined
  preventFetch?: boolean; // whether to prevent fetching flags on init
  enableAnalytics?: boolean; // Enable sending flag analytics for getValue and hasFeature evaluations.
  enableLogs?: boolean; // whether to enable logs
  onChange?: (previousFlags: IFlags, params: IRetrieveInfo) => void; // triggered when the flags are retrieved
  state?: IState; // set a predefined state, useful for isomorphic applications
  onError?: (res: { message: string }) => void; // triggered if there was an api error
  defaultFlags?: IFlags;
};

export enum FeatureFlags {
  DEBUG = "gladiator/flagsmith-debug",
  MAINTENANCE_MODE = "global/maintenance-mode",
}

type FlagsConfig = {
  [key in FeatureFlags]?: boolean;
};
type Context = {
  features: IFlagsmith;
  flagsConfig: FlagsConfig;
};

const FeaturesContext = createContext<Context | undefined>(undefined);

// useFeatures will return the initialised Flagsmith instance
export const useFeatures = () => {
  const context = useContext(FeaturesContext);
  if (context === undefined) {
    throw new Error("useFeatures must be used within a FeaturesProvider");
  }
  return context;
};

export const FeaturesProvider = ({ children }: FeaturesProviderProps) => {

  const [flagsConfig, setFlagsConfig] = useState<FlagsConfig>({});
  // Function to update the flagsConfig state
  const handleFlagsConfigUpdate = () => {
    let updatedFlagsConfig: FlagsConfig = {};
    for (const flagName of Object.values(FeatureFlags)) {
      updatedFlagsConfig[flagName] = flagsmith.hasFeature(flagName);
    }
    setFlagsConfig(prevFlagsConfig => ({
      ...prevFlagsConfig,
      ...updatedFlagsConfig,
    }));
  };

  const flagsmithConfig: FlagsmithConfig = useMemo(
    () => ({
      environmentID: process.env.REACT_APP_FLAGSMITH_ENV_ID ?? "",
      api: "https://api.flagsmith.com/api/v1/",
      cacheFlags: true,
      enableAnalytics: true,
      enableLogs: false,
      onChange: (prevFlags, params) => {
        handleFlagsConfigUpdate();
        if (flagsmith.hasFeature(FeatureFlags.MAINTENANCE_MODE)) {
          history.push("/maintenance");
        }
      },
      onError: (res: { message: string }) => {
        if (flagsmith.hasFeature(FeatureFlags.DEBUG)) {
          if (res.message) console.error("Flagsmith Error: ", res.message);
        }
      },
      defaultFlags: {
        [FeatureFlags.MAINTENANCE_MODE]: {
          id: "22188",
          enabled: false,
        },
        [FeatureFlags.DEBUG]: {
          id: "28044",
          enabled: false,
        },
      },
    }),
    [history]
  );

  if (flagsmith.hasFeature(FeatureFlags.DEBUG)) {
    console.log("Flagsmith Initialised");
  }

  const userType = "customer";
  const customerId = useSelector((state) => state.auth.customerId);
  const [isInitialised, setIsInitialised] = useState(false);
  const [isDebugEnabled, setIsDebugEnabled] = useState(false);

  // Initialise Flagsmith
  useEffect(() => {
    // Wait for the customerId before initialising Flagsmith
    if (customerId === "" || customerId === null) return;
    if (isInitialised) return;

    console.log(customerId);

    // Init Flagsmith -- we identify as the contact who initiated the checkout
    flagsmith
      .init({ ...flagsmithConfig, identity: `${userType}_${customerId}` })
      .then(() => {
        setIsInitialised(true);

        flagsmith.startListening(5 * 60 * 1000); // poll every 5 minutes for any updates to the flags

        if (flagsmith.hasFeature(FeatureFlags.DEBUG)) {
          console.log("Flagsmith initialised");
        }

        const flagsmithState = flagsmith.getState();

        if (!flagsmithState.traits?.id || !flagsmithState.traits?.user_type) {
          flagsmith.setTraits({
            id: customerId,
            user_type: userType,
          });

          if (flagsmith.hasFeature(FeatureFlags.DEBUG)) {
            console.log("Base traits added");
          }
        }
      });
  }, [flagsmithConfig, isInitialised, customerId]);

  // Enable Flagsmith debug output via Flagsmith feature flag (after initialise)
  useEffect(() => {
    if (
      isInitialised &&
      flagsmith.hasFeature(FeatureFlags.DEBUG) &&
      !isDebugEnabled
    ) {
      console.log("Flagsmith enabling debug");

      setIsDebugEnabled(true);

      const debugConfig = {
        ...flagsmithConfig,
        enableLogs: true,
      };

      flagsmith
        .init(debugConfig)
        .then(() => {
          console.log("Flagsmith reinitialised with debug enabled");
        })
        .catch(() => {
          console.log("Flagsmith failed to initialise with debug enabled");
        });
    }
  }, [isInitialised, flagsmithConfig, isDebugEnabled]);

  return (
    <FeaturesContext.Provider value={{ features: flagsmith, flagsConfig }}>
      {children}
    </FeaturesContext.Provider>
  );
};

export default FeaturesProvider;
