import React from "react";
import { SCFooter } from "./css";
import * as CONSTANT from "../../util/constants/messages";
import { Link } from "react-router-dom";

const Footer = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let FOOTER_LINKS;

  if (process.env.REACT_APP_CURRENT_REGION === "au") {
    FOOTER_LINKS = CONSTANT.FOOTER_LINKS_AU;
  } else {
    FOOTER_LINKS = CONSTANT.FOOTER_LINKS_NZ;
  }

  return (
    <SCFooter>
      <footer>
        <div className="row-content">
          <div className="logo">
            <img
              src="https://payright.com.au/wp-content/uploads/logo-payright-white.png"
              width="100"
              alt=""
            />
          </div>
          <ul>
            {FOOTER_LINKS.map((item, i) => {
              return (
                <li key={i}>
                  <Link to={{ pathname: item.link }} target="_blank">
                    {item.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </footer>
    </SCFooter>
  );
};

export default Footer;
