import {
  styled,
  media,
  Table,
  Button
} from '@payright/web-components';

//import Table, { TableProps } from "../Core/Tables/index";

interface CustomerPlansTableProps {
  tableRows: any;
}
interface SCCustomerPlansTableProps { }

const SCCustomerPlansTable = styled(Table) <SCCustomerPlansTableProps>`
	td {
		&.balancePayment{
			display: flex;
				.title-block{
          flex-basis: 70%;
          &:last-child{
						h5{
							color: ${props => props.theme.colours.blue.base};
						}
					}
				}
			}
		}
  }


	${media.min.wide} {
		thead {
			tr {
				th {
					&:last-of-type {
						padding: 1rem 1.86rem;
					}
				}
			}
		}
		tbody {
			tr.row {
				td {
					padding: 2rem;
				}
			}
		}
	}
	${media.min.large} {
    border-collapse: inherit;
		border-spacing: 0 1rem;
		thead {
			tr {
				th {
					&:first-child {
						border-bottom-left-radius: 0.4em;
					}
					&:last-child {
						border-bottom-right-radius: 0.4em;
					}
				}
			}
		}
		tbody {
			tr.row {
				box-shadow: 1rem 0 2rem ${props => props.theme.colours.grey.light};
    		border-radius: 0.8em;
				td {
					padding: 2rem 1rem;
					&:first-child {
						border-bottom-left-radius: 0.4em;
						border-top-left-radius: 0.4em;
					}
					&:last-child {
						border-bottom-right-radius: 0.4em;
						border-top-right-radius: 0.4em;
					}
					&.balanceCustomer{
						.title-block{
							&:last-child{
								padding-left: 2rem;
								h5{
									color: ${props => props.theme.colours.blue.base};
								}
							}
						}
					}
				}
			}
		}
	}
	${media.max.large} {
   	tbody {
			tr {
        display:flex;
        margin-bottom: 1rem;
				align-items: center;
				td {
					.title-block{
						h6{

							font-size: 0.8em;
							color: ${props => props.theme.colours.grey.medium};
						}
          }
					&.planName{
						flex-basis: 40%;
					}
					&.statusCust{
						flex-basis: 20%;
					}
					&.payRemain{
						flex-basis: 10%;
					}
					&.balanceCustomer{
						flex-basis: 30%;
					}
					&.buttonUrl {
           font-size: 0.8em;
           margin-left: auto;
           
          }
				}
			}
		}
	}
	${media.max.medium} {
		tbody {
			tr.row {
				td {
					padding: 0.8rem 1rem;
					&.planName{
						flex-basis: 60%;
						flex-grow: 1;
					}
					&.statusCust{
						flex-basis: auto;
					}
					&.payRemain{
						flex-basis: 30%;
						border-top: 1px solid ${props => props.theme.colours.grey.xlight};
					}
					&.balanceCustomer{
						flex-basis: 70%;
						border-top: 1px solid ${props => props.theme.colours.grey.xlight};
					}
				}
			}
		}
  }
  

`;

export const SCCustomerPlans = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 1em auto 0;
`

export const SCHeaderButton = styled(Button)`
  ${media.max.large} {
   display: none;
  }
`

export const SCCustomerNoPlans = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 1em auto 0;
  text-align: center;
  color: ${props => props.theme.colours.blue.base};
  font-size: 1.1em;
  font-weight: bold;

`

export const SCPlanSearch = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: -2em auto 0;

   ${media.max.tablet} {
      margin: 0em auto 0;
  }
`

export const SCCustomerNavigation = styled.div`
  background-color: white;
`


export const SCPlanDetailPage = styled.div`
  display:flex;
  background-color: ${props => props.theme.colours.secondary3};
  padding: 1em 1em;
  align-content:flex

 ${media.max.large} {
  .parent {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    overflow: hidden;
    font-weight:normal;
   
      .column {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;
        margin: 1.2em;
        font-weight:normal;
        font-size:0.75rem;
    }

     .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        flex-width: 100%;
        justify-content: space-between;
        padding: 1em 0em 1em 0em;
        height:50px;
        border-bottom: 1px dashed ${props => props.theme.colours.grey.light};
       
        label {
            font-weight: normal;
            flex: 1;
            color:${props => props.theme.colours.blue.base};
            font-size:0.75rem;
        }
      }
  }
} // Phone Css ends here
${media.min.medium} {
  .parent {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    display: flex;
    width: 90%;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
  
   .column {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;
        margin: 2em;
    }
    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      flex-width: 100%;
      justify-content: space-between;
      padding: 1.7em 0;
      border-bottom: 1px dashed ${props => props.theme.colours.grey.light};
      font-size: 0.80rem;
      label {
            font-weight: bold;
            flex: 1;
            color:${props => props.theme.colours.blue.base};
            font-size: 0.80rem;
        }
    }
  }
  
}

`;


export const SCPlanDetailDashboard = styled.div`


${media.max.large} {
  display:flex;
  height:130px;
  align-content:flex
  .plan-container {
      width: 100%;
      padding: 1em 2.0em;

      .left-col {
        flex: 0 0 30%;
        margin-top:10px;
        h4 {
          color: ${props => props.theme.colours.blue.base};
          font-size: .70rem;
          font-weight: bold;
        }
        h6 {
          color: ${props => props.theme.colours.grey.dark};
          font-size: .70rem;
          font-weight: normal;
        }
      }
    }
    .right-col {
      display: flex;
      justify-content: space-between;
      .estPayments {
        flex: 0 0 22%;
        border-right: 1px solid ${props => props.theme.colours.grey.xlight};
        h5 {
          color: ${props => props.theme.colours.blue.base};
          font-size: 0.70rem;
          font-weight: normal;
        }
        h6 {
            color: #524FA1;
            font-size: 0.70em;
            text-align:left;
            font-weight:bold;
            padding-top:10px;
        }
      }
      .paid {
        flex: 0 0 2%;
        h5 {
          color: ${props => props.theme.colours.plum.base};
          font-size: 0.70rem;
          font-weight: normal;
          text-align:left;
          padding-left: 1px;
          padding-top:10px;
        } 
        h6 {
          color: #524FA1;
          font-size: 0.70rem;
          text-align:left;
          padding-left: 1px;
          padding-top:10px;
        }
      }
      .slash {
          flex: 0 0 5%;
          h5 {
          color: ${props => props.theme.colours.grey.light};
          font-size: 1rem;
          font-weight: normal;
          text-align: left;
          padding-top:30px;
          padding-left:10px;
          margin-bottom:20px;
        }
      }
    .remaining {
        flex: 0 0 25%;
        margin-right: 2%
        border-right: 1px solid ${props => props.theme.colours.grey.xlight};
        h5 {
          color: ${props => props.theme.colours.blue.base};
          font-size: 0.70rem;
          font-weight: normal;
          padding-left: 10px;
          text-align:left;
          padding-top:10px;
        }
        h6 {
          color: #524FA1;
          font-size: 0.70rem;
          text-align:left;
          padding-left: 10px;
          padding-top:10px;
        }
      }
    .planStatus {
        font-weight: normal;
        h5 {
          background-color: ${props => props.theme.colours.grey.altlight};
          border-radius: 2em;
          padding: 0em 1em 0.28em 0.7em;
        }
        h6 {
          color: #524FA1;
          font-size: 0.70rem;
          padding-top:10px;
          margin-left: 10%
        }
      }    
    }
  }

  // for tablet
  ${media.min.medium} {
    display:flex;
    height:200px;
    align-content:flex
    padding: 1em 5em 1em 5em;
      .plan-container {
     width: 100%;
      .left-col {
        flex: 0 0 30%;
       
        h4 {
          color: ${props => props.theme.colours.blue.base};
          font-size: 1.5rem;
          font-weight: bold;
         }
        h6 {
          color: ${props => props.theme.colours.grey.dark};
          font-size: 1rem;
          font-weight: normal;
        }
      }
    }

     .right-col {
       display: flex;
      justify-content: space-between;
      .estPayments {
        flex: 0 0 22%;
        border-right: 1px solid ${props => props.theme.colours.grey.xlight};
        h5 {
          color: ${props => props.theme.colours.blue.base};
          font-size: 1.2rem;
          font-weight: normal;
          padding-top:10px;
        }
        h6 {
            color: #524FA1;
            font-size: 1.3em;
            text-align:left;
            font-weight:bold;
            padding-top:10px;
        }
    }

     .paid {
      flex: 0 0 2%;
      h5 {
        color: ${props => props.theme.colours.plum.base};
        font-size: 1.2rem;
        font-weight: normal;
        text-align:left;
        padding-left: 1px;
        padding-top: 10px;
      } 
      h6 {
        color: #524FA1;
        font-size: 1.3em;
        text-align:left;
        padding-left: 1px;
        padding-top:10px;
      }
    }
     .slash {
          flex: 0 0 5%;
          h5 {
          color: ${props => props.theme.colours.grey.light};
          font-size: 2rem;
          font-weight: normal;
          text-align: left;
          margin-top:20px;
          padding-left:10px;
          margin-bottom:20px;
          padding-top: 12px;
        }
    }

     .remaining {
        flex: 0 0 25%;
        margin-right: 2%
        border-right: 1px solid ${props => props.theme.colours.grey.xlight};
        h5 {
          color: ${props => props.theme.colours.blue.base};
          font-size: 1.2em;
          font-weight: normal;
          padding-left: 1.2em;
          text-align:left;
          padding-top: 10px;
        }
        h6 {
          color: #524FA1;
          font-size: 1.3em;
          text-align:left;
          padding-left: 10px;
          padding-top:10px;
        }
    }

    .planStatus {
        font-weight: normal;
        h5 {
          background-color: ${props => props.theme.colours.grey.altlight};
          padding: 0.2rem 1.4rem 0.1rem 0.8rem;
          border-radius: 1.4rem;
        }
        h6 {
          color: #524FA1;
          font-size: 1.10rem;
          padding-top:10px;
          margin-left: 10%
          padding-bottom:12px;
      
        }
      }    
    }
  }

  
  // for laptop
  ${media.min.wide} {
    display: flex;
    height:150px;
    padding: 1em 0em 1em 1.3em;

    .plan-container {
      display: flex;
      width: 90%;
      flex-wrap: no-wrap;

      position: relative;
      margin: 0 auto;

      overflow: hidden;
      flex-direction: row;

      justify-content: space-between;
      align-items: center;

      .left-col {
        flex: 0 0 30%;
        margin-left: 2%
        margin-top:10px;
        h4 {
          color: ${props => props.theme.colours.blue.base};
          font-size: 1.5rem;
          font-weight: bold;
        }
        span {
            color: #524FA1;
            font-weight: bold;
        }
      }
    }
      
    .right-col {
      display: flex;
      flex-flow: row wrap;
      flex-grow: 1;
      justify-content: space-between;
      
    
      .estPayments {
        flex: 0 0 30%;
        border-right: 1px solid ${props => props.theme.colours.grey.xlight};
        h5 {
          color: ${props => props.theme.colours.blue.base};
          font-size: 2rem;
          font-weight: normal;
          text-align:center;
          padding-top:17px;
        }
        h6 {
            color: #524FA1;
            font-size: 1.10em;
            text-align:center;
            padding-top:10px;
            
        }
      }
    
      .paid {
        flex-basis: 10%;
        padding-left: 15px;
        h5 {
          color: ${props => props.theme.colours.plum.base};
          font-size: 2rem;
          font-weight: normal;
          margin-top:10px;
        } 
        h6 {
          color: #524FA1;
          font-size: 1.1em;
        }
      }
    
      .slash {
        flex-basis: 4%;
        margin-top:4%;
        h5 {
          color: ${props => props.theme.colours.grey.light};
          font-size: 2rem;
          font-weight: normal;
          text-align: center;
          margin-top:10px;
          padding-left:15px;
        }
      }
    
      .remaining {
        flex: 0 0 20%;
        margin-right: 2%
        
        border-right: 1px solid ${props => props.theme.colours.grey.xlight};
        h5 {
          color: ${props => props.theme.colours.blue.base};
          font-size: 2rem;
          font-weight: normal;
          margin-top:11px;
          padding-left: 4.2px;
          
        }
        h6 {
          color: #524FA1;
          font-size: 1.10em;
        }
      }
    
      .planStatus {
        flex: 0 0 12%;
        padding-left: 60px;
        padding-right: 50px;
        padding-top:10px;
        h5 {
          font-size: 1.0rem;
          font-weight: bold;
          background-color: ${props => props.theme.colours.grey.altlight};
          border-radius: 3rem;
        }
        h6 {
          color: #524FA1;
          font-size: 1em;
          text-align:center;

       
        }
      }
    }
  }
}
`;

export default SCCustomerPlansTable;