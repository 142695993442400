import * as React from 'react';
import { SCPlanDetailPage } from "./css";
import Footer from '../Footer';


//import Status from '@payright/web-components/src/components/Tables/Status';
export interface PlanDetailPageProps {
    /** plan dashboard data */
    planDetailPageData: {
        merchantReference: string;
        approvalDate: Date;
        activationDate: Date;
        planDateAdded: string;
        saleAmount: number;
        status: string;
        deposit: number;
        loanAmount: number;
        estalishmentFees: number;
        totalCreditProvided: number;
        remainingBalance: number;
        planTerm: number;
        repaymentFrequency: number;
        numberOfRepayments: number;
        repaymentAmount: number;
        estimateRepaymentRemaining: number;
        estRepaymentDate: Date;
        nextRepaymentDate: Date;
    };
}

const PlanDetailPage = ({
    planDetailPageData
}: PlanDetailPageProps) => (
        <SCPlanDetailPage>
            <div className="parent">
                <div className="column">

                    <div className="row">
                        <label>Approval Date</label>
                        <span>{planDetailPageData.approvalDate}</span>
                    </div>
                    <div className="row">
                        <label>Application Created Date</label>
                        <span>{planDetailPageData.planDateAdded}</span>
                    </div>
                    <div className="row">
                        <label>Plan Activation Date</label>
                        <span>{planDetailPageData.activationDate}</span>
                    </div>
                    <div className="row">
                        <label>Sale Amount</label>
                        <span>${planDetailPageData.saleAmount}</span>

                    </div>
                    <div className="row">
                        <label>Deposit</label>
                        <span>${planDetailPageData.deposit}</span>
                    </div>
                    <div className="row">
                        <label>Loan Amount</label>
                        <span>${planDetailPageData.loanAmount}</span>
                    </div>

                    <div className="row">
                        <label>Establishment Fee</label>
                        <span>${planDetailPageData.estalishmentFees}</span>

                    </div>
                    <div className="row">
                        <label>Total Credit Provided</label>
                        <span>${planDetailPageData.totalCreditProvided}</span>

                    </div>
                </div>
                <div className="column">
                    <div className="row">
                        <label>Balance Remaining</label>
                        <span>${planDetailPageData.remainingBalance}</span>
                    </div>
                    <div className="row">
                        <label>Plan Term</label>
                        <span>{planDetailPageData.planTerm}</span>
                    </div>
                    <div className="row">
                        <label>Repayment Frequency</label>
                        <span>{planDetailPageData.repaymentFrequency}</span>
                    </div>
                    <div className="row">
                        <label>Number of Repayments</label>
                        <span>{planDetailPageData.numberOfRepayments}</span>
                    </div>
                    <div className="row">
                        <label>Repayment Amount(ex fees)</label>
                        <span>${planDetailPageData.repaymentAmount}</span>
                    </div>
                    <div className="row">
                        <label>Repayments Remaining</label>
                        <span>{planDetailPageData.estimateRepaymentRemaining}</span>
                    </div>
                    <div className="row">
                        <label>Final Repayment Date</label>
                        <span>{planDetailPageData.estRepaymentDate}</span>
                    </div>
                    <div className="row">
                        <label>Next Repayment date</label>
                        <span>{planDetailPageData.status === 'Active' ? planDetailPageData.nextRepaymentDate : 'N/A'}</span>
                    </div>
                </div>

            </div>

        <Footer />
    </SCPlanDetailPage>
  );
  export default PlanDetailPage;
