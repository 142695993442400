import { styled, media } from "@payright/web-components";
import "@progress/kendo-data-query";
import "@progress/kendo-react-dropdowns";
import "@progress/kendo-react-inputs";
import "@progress/kendo-react-dateinputs";
import "@progress/kendo-react-animation";
import "@progress/kendo-react-popup";
import "@progress/kendo-react-pdf";
import "@progress/kendo-drawing";

export const SCCustomerFilterStatement = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 1em auto 0;

  ${media.max.medium} {
    max-width: 1500px;
  }

  .containerStatement {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 1em auto 0;

    h5 {
      font-size: 1em;
      color: ${props => props.theme.colours.grey.medium};
      font-weight: bold;
      text-align: center;
      padding-top: 12px;

      ${media.max.medium} {
        font-size: 0.8em;
        padding-top: 14px;
      }
    }
  }

  ${media.max.medium} {
    .containerStatement {
      display: flex;
      width: 100%;
      justify-content: flex-start;
    }
  }

  .containerStatement > .statementButton {
    padding-top: 25px;
    padding-right: 5px;
    padding-left: 10px;
    ${media.max.medium} {
      font-size: 0.9em;
      padding-right: 5px;
      display: none;
      border: 1px solid red;
    }
  }

  .containerStatement > .mobileStatementButton {
    ${media.max.medium} {
      font-size: 0.9em;
      padding-right: 5px;
      padding-top: 25px;
      padding-right: 5px;
      padding-left: 10px;
    }
  }

  .containerStatement > .mobileStatementButton button.export-btn {
    display: none;
    ${media.max.medium} {
      width: auto;
      display: block;
    }
  }

  .export-btn {
    height: 47px;
    ${media.max.medium} {
      height: 42px;
      width: 80px;
    }
  }
`;

export const SCCustomerStatement = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 1em auto 0;

  .noRecords {
    text-align: center;
    font-size: 1em;
    font-weight: bold;
  }
`;

export const SCCustomerDateError = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 1em auto 0;
  text-align: center;
  color: ${props => props.theme.colours.blue.base};
  font-size: 1.1em;
  font-weight: bold;
`;
export const Statement = styled.div`
  font-family: Arial, monospace;
  .pdfheader {
    height: 200px;
    background-color: #eff1f6;
    width: auto;
    &.pdf-screen-header {
      margin: 1em auto 0;
      max-width: 1200px;
      width: 100%;
    }
  }

  .pdfmain {
    background-color: yellow;
  }

  .pdf {
    font-size: 12px;
    &.pdf-font-size {
      font-size: 10px;
    }
    &.pdf-margin-left {
      margin-left: 20px;
    }

    color: #240050;
    margin-bottom: 15px;
    text-align: -webkit-center;
    margin-left: 60px;
    margin-right: 20px;

    ${media.max.medium} {
      margin-left: 10px;
      margin-right: 10px;
      display: inline-table;
    }
  }

  .logo {
    padding-top: 50px;
    color: #f93253;
    float: left;
    font-size: 12px;
    max-width: 50%;

    &.extra-padding {
      padding-right: 20px;
      padding-left: 20px;
    }
    &.pdf-font-size {
      font-size: 10px;
    }

    ${media.max.medium} {
      max-width: 50%;
      padding-top: 10%;
      padding-left: 3%;
    }
  }

  .logo img {
    ${media.max.medium} {
      max-width: 80%;
    }
    ${media.min.medium} {
      max-width: 80%;
    }
  }

  .pdftopright {
    &.extra-padding {
      padding-right: 20px;
    }
    float: right;
    padding-top: 50px;
    max-width: 50%;
    ${media.max.medium} {
      float: right;
      padding-top: 10%;
      padding-right: 3%;
      max-width: 50%;
      font-size: 70%;
    }
  }

  .pdfcustomer {
    height: 180px;
    font-size: 10px;
  }
  .pdfScreencustomer {
    height: 200px;
    font-size: 15px;
    max-width: 1360px;
    width: 100%;
  }

  .pdfmleft {
    margin-left: 122px;
    margin-top: 20px;
    &.pdf-left-margin {
      margin-left: 20px;
    }
    float: left;
    ${media.max.medium} {
      max-width: 50%;
      margin-left: 3%;
      margin-top: 5%;
    }
  }

  .pdfmright {
    margin-top: 20px;
    margin-right: 10px;
    &.pdf-margin {
      margin-top: 20px;
      margin-right: 20px;
    }
    border-radius: 10px 0px 10px 10px;
    padding: 10px;
    float: right;
    background-color: #eff1f6;
    ${media.max.medium} {
      max-width: 50%;
      margin-right: 3%;
      margin-top: 5%;
    }
  }

  .pdf table {
    max-width: 1250px;
    width: 100% !important;
    text-align: left !important;
  }

  thead {
    background-color: #240050;
  }

  td {
    padding: 5px;
    border-right: 1px solid #afbbd0;
  }
  td.balance {
    border-right: 0px;
    text-align: right;
  }
  td.planId {
    max-width: 40px;
    width: 40px;
  }

  td.principalAmount,
  td.estFees,
  td.OtherFees,
  tfoot > tr > td {
    text-align: right;
  }

  td.date {
    width: 45px;
    max-width: 45px;
  }
  td.purpose {
    width: 113px;
    max-width: 113px;
  }

  .pdf a {
    color: white;
  }

  tfoot {
    background-color: #524fa1;
    color: white;
  }

  tfoot tr td {
    border: 0px;
  }

  .pdf th {
    vertical-align: middle;
    border-right: 1px solid #afbbd0;
    padding: 3px;
  }

  .pdf tr {
    border-bottom: 1px solid #afbbd0;
  }

  .other {
    font-size: 12px;
    width: 100%;
    margin-left: 100px;
    padding-left: 10px;
    max-width: 1270px;

    &.pdf-font-size {
      font-size: 10px;
    }
    &.pdf-margin-left {
      margin-left: 20px;
    }
    &.pdf-max-width {
      max-width: 95%;
    }
    strong,
    b {
      font-weight: 700;
    }
  }

  .other p,
  span {
    color: #240050;
    padding: 5px;
  }

  kendo-pdf-document footer,
  kendo-pdf-document p {
    position: "absolute";
    fontsize: 8px;
    bottom: 30;
    left: 0;
    right: 0;
    textalign: "center";
    color: "grey";
  }
  .k-pdf-export footer p {
    font-size: 8px;
    background-color: black;
  }

  .k-pdf-export footer {
    background: black;
  }

  footer p {
    position: "absolute";
    font-size: 8px;
    bottom: auto;
    left: 0;
    right: 0;
    textalign: "center";
    color: "grey";
  }

  .content {
    height: 10%;
    max-height: 10%;
  }
  footer {
    height: 10%;
    max-height: 10%;
    font-size: 8px;
    bottom: auto;
  }

  .k-grid-header {
    padding: 0px !important;
  }

  .k-grid-footer {
    padding: 0px !important;
  }

  td.closingBalance {
    text-align: left !important;
    padding: 5px;
  }
`;

export const SCShowExportPDF = styled.div`
  .pdfMainContent {
    ${media.max.tablet} {
      margin-right: 0%;
      margin-left: 0%;
      &.pdf-no-show-for-tablet {
        display: none;
      }
    }

    ${media.min.large} {
      &.pdf-screen-setting {
        position: absolute;
        margin-left: 10%;
        margin-left: -2500px;
        margin-top: 0;
      }
      margin-right: 3px;
      margin-left: 10px &.pdf-no-show-for-tablet {
        display: block;
      }
    }

    ${media.max.medium} {
      left: 2500px;
      position: fixed;
    }
  }
`;
