import {
    FETCH_PLANS_BEGIN,
    FETCH_PLANS_SUCCESS,
    FETCH_PLANS_FALIURE,
} from "../actions/types";

const initialState = {
    items: [],
    loading: false,
    error: null,
}

export default function planReducer(
    state = initialState,
    action
) {
    switch(action.type) {
        case FETCH_PLANS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            }

        case FETCH_PLANS_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.plans
            }
        
        case FETCH_PLANS_FALIURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                items:[]
            }
        default:
            return state;
    }
}