import { styled } from "@payright/web-components";

export const NotFoundHeader = styled.div`
  font-size: calc( 22px + 2 * (100vw - 576px) / (1200 - 576));
  text-align: center;
  color: #5431FF;
  margin: 2rem 2rem 1.5rem 2rem;
  font-weight: 700;
  font-family: 'quicksand', Arial, serif;
`;

export const NotFoundContent = styled.div`
  font-size: calc( 18px + 0 * (100vw - 576px) / (1200 - 576));
  text-align: center;
  line-height: 1.5;
  color: #240050;
  margin: 1.5rem 2rem 2rem 2rem;
  font-weight: 700;
  font-family: 'quicksand', Arial, serif;
`;
