import React from "react";
import { Navigation } from "@payright/web-components";
import Footer from "../Footer";
import { SCNeedHelp } from "./css";
import {
  CUSTOMER_MENU_ITEMS,
  SUB_MENU_ACTIONS
} from "../../util/constants/types";
import { Notifications } from "../Notifications/Notifications";
import { connect } from "react-redux";
import { loadPageData } from "../../actions";

class NeedHelp extends React.Component<any, any> {
  render() {

    return (
      <SCNeedHelp>
        <Navigation
          breakpoint="wide"
          portalType="customer"
          menuItems={CUSTOMER_MENU_ITEMS}
          activePage="/accounts"
          notifications={Notifications(this.props.notification)}
          accountDetailsActions={SUB_MENU_ACTIONS}
        />
        <Footer />
      </SCNeedHelp>
    );
  }
}

const mapStateToProps = state => {
  return {
    customer: state.pageData.customer,
    notification: state.pageData.notification
  };
};

const mapDispatchToProps = dispatch => ({
  loadPageData: () => dispatch(loadPageData()),
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(NeedHelp);
