import {
  styled,
  media,
} from '@payright/web-components';

export const SCCustomerForm = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

	margin-bottom: 3em;
	hr {
     border: 0; border-top: 1px solid #ccc;
  	}
	.alerts {
		margin-bottom: 2em;
	}
	button {
		float: right;
	}
`;

export const SCCustomerDetails = styled.div`
   margin-bottom: 2em;
  .details-body {
    display: flex;
    flex-wrap: wrap;
  }

  hr {
     border: 0; border-top: 1px solid #ccc;
  }

  .align-right {
    float:right;
  }

  .alerts {
    margin-bottom: 2em;
  }

  ${media.max.medium} {
    .input-field, .dropdown {
      width: 100%;
      max-width: 100%;
    }
    .title-firstname-wrapper {
      width: 100%;
      & > div:first-of-type {
        margin-bottom: 1.33em;
      }
    }
    .date-picker {
      width: 100%;
    }
    .details-body > div, .address-body .input-field, .address-body .dropdown {
      margin-bottom: 1.33em;
    }
  }
  
  ${media.min.medium} {
    .title-firstname-wrapper {
      flex: 50%;
      display: flex;
      padding-left: 5px;
     .title {
        margin-right: 1.5em;
       }
      .first-name {
        margin: 0 0.75em;
      }
    }
  
    .last-name {
      margin-left: 0.75em;
    }
  
    .email {
      margin-right: 0.75em;
    }
    .email-confirm {
      margin-left: 0.75em;
    }
  
    .phone {
      width: calc(50% - 0.75em);
      margin-right: 0.75em;
    }
    .date-picker {
      width: calc(50% - 0.75em);
      margin-left: 0.75em;
    }
  
    .input-field {
      flex: calc(50% - 0.75em);
      margin-bottom: 2.46em;
    }
    
    hr {
      &.details-hr {
        margin-top: 2.27em;
        margin-bottom: 4.4em;
      }
      margin-top: 1.15em;
      margin-bottom: 2em;
    }
  
    .address-wrapper {
      display: flex;
      flex-wrap: wrap;
      padding-left: 5px;
      .input-field,
      .dropdown {
        max-width: calc(50% - 0.75em);
      }
      .input-field {
        margin-right: 0.75em;
      }
      .dropdown {
        margin-left: 0.75em;
        padding-right: 5px;
      }
    }
     .details-body > div, .address-body .street {
     padding-left: 5px;
     padding-right: 5px;
    }
  }
  
`;


export const SCProfile = styled.div`
 
  div[readonly] {

    & .dropdown__value {
      background: transparent;
      border: none;
      padding-left: 0;
      color: #531dff;
    }
  }
  input:read-only {
    background: transparent;
    border: none;
    padding-left: 0;
  }
  .profile-content {
    padding-bottom: 2em;
  }
 	.content-section {
 		width: 100%;
    display: inline-block;
    
    .special-char {
        padding-left: 1em;
    }
 	}
 	.left-side {
 		width: 25%;
 		display: inline-block;
    vertical-align: top;
    padding-left:5px;
 	}
 	.right-side {
 		width: 65%;
    display: inline-block;

 	}
 	h4 {
 		color: #531dff;
 	}

 	.details-body {
    display: flex;
    flex-wrap: wrap;
  }

  ${media.max.medium} {
    button {
      max-width: 100%;
    }
    .left-side {
      width: 100%;
      margin-bottom:1em;
      padding-left:5px;
    }
    .right-side {
      width: 100%;
      padding-left:5px;
    }
    .input-field, .dropdown {
      width: 100%;
      max-width: 100%;
      padding: 0px 5px 0px 5px;
    }
    .title-firstname-wrapper {
      width: 100%;
      & > div:first-of-type {
        margin-bottom: 1.33em;
      }
    }
    .date-picker {
      width: 100%;
    }
    .details-body > div, .address-body .input-field, .address-body .dropdown {
      margin-bottom: 1.33em;
      padding: 0px 5px 0px 5px;
    }
  }

  ${media.max.tablet} {
    .left-side {
      width: 100%;
      margin-bottom:1em;
    }
    .right-side {
      width: 100%;
    }
  }
  
  ${media.min.medium} {
    .title-firstname-wrapper {
      flex: 50%;
      display: flex;
      .title {
        margin-right: 1.5em;
      }
      .first-name {
        margin: 0 0.75em;
      }
    }
  
    .last-name {
      margin-left: 0.75em;
    }
  
    .email {
      margin-right: 0.75em;
    }
    .email-confirm {
      margin-left: 0.75em;
    }
  
    .phone {
      width: calc(50% - 0.75em);
      margin-right: 0.75em;
    }
    .date-picker {
      width: calc(50% - 0.75em);
      margin-left: 0.75em;
    }
  
    .input-field {
      flex: calc(50% - 0.75em);
      margin-bottom: 2.46em;
    }
  
    hr {
      &.details-hr {
        margin-top: 2.27em;
        margin-bottom: 4.4em;
      }
      margin-top: 1.15em;
      margin-bottom: 2em;
    }
  
    .address-wrapper {
      display: flex;
      flex-wrap: wrap;
      .input-field,
      .dropdown {
        max-width: calc(50% - 0.75em);
      }
      .input-field {
        margin-right: 0.75em;
      }
      .dropdown {
        margin-left: 0.75em;
      }
    }
  }
 `;

export const SCEmploymentDetails = styled.div`
  .details-body {
    .input-field,
    .dropdown {
      margin-bottom: 2.46em;
    }
    .current-employer {
      margin-top: 1em;
      width: 100%;
      ${media.max.medium} {
        .dropdown {
          width: 100%;
          max-width: 100%;
        }
      }
      ${media.min.medium} {
        display: flex;
        & > div {
          margin: 0 0.75em;
          &:first-of-type {
            margin-left: 0;
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
      .income-details {
          margin-top: 2em;
          display: flex;
          width:100%;
      }
      .other-income {
        width:100%;
      }
  }
`;

export const SCIdentification = styled.div`
  margin-bottom: 3em;
  ${media.max.medium} {
    .input-field, .dropdown {
      margin-bottom: 1.33em;
    }
    .dropdown {
      max-width: 100%;
    }
  }
  ${media.min.medium} {
    .input-field {
      margin-bottom: 2.46em;
    }
    .state-expiry-wrapper {
      display: flex;
      margin-top: 1em;
      .date-picker {
        width: calc(50% - 0.75em);
        margin-left: 0.75em;
      }
      .input-field {
        flex: calc(50% - 0.75em);
        margin-bottom: 1em;
      }
      & > .dropdown {
        width: calc(50% - 0.75em);
        margin-right: 0.75em;
      }
    }
  }
  .id-type {
    margin-bottom: 1em;
  }
  .passportNumber {
    margin-right: 1.2em;
  }
`;

export const SCChangePassword = styled.div`
`;

