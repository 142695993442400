import React from 'react';
import queryString from 'query-string';
import { handleCloudPaymentSubmit } from '../../actions/paymentActions';
import { loadPageData } from '../../actions';
import { connect } from 'react-redux';
import history from '../../history';

import { SCPaymentSection } from './css';
import Loader from '../Common/Loader';

interface CloudPaymentProps {
    location?: string;
    dispatch?: Function;
    customer?: any;
}
class  CloudPayment extends React.Component<CloudPaymentProps>
{
    componentDidMount() {
        if (this.props.customer === null) {
            this.props.dispatch(loadPageData());
        }
        
        const values = queryString.parse(this.props.location.search);
        handleCloudPaymentSubmit(values, this.props.dispatch);

        history.push('/payments');
    }

    render() {
        return (
            <SCPaymentSection>
                <Loader text='Fetching details...' />
            </SCPaymentSection>
        )
    }

}

const mapStateToDispatch = state => {
    return {
        customer: state.pageData.customer,
    }
};

const mapDispatchToProps = dispatch => ({
    loadPageData: () => dispatch(loadPageData()),
    dispatch,
});

export default connect(
    mapStateToDispatch,
    mapDispatchToProps,
)(CloudPayment);
