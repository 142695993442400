import {
    styled
} from '@payright/web-components';


export const SCNeedHelp = styled.div`
    .content {
        margin-top: 2em;
    }
    .accordion .accordion__header {
        font-family: inherit;
    }
    .accordion label {
        font-size: inherit;
        font-weight: 600;
    }
    .accordion .accordion__content .accordion__body {
        font-family: inherit;
    }
`;