import React from 'react';

import { SCCustomerDetails } from './css';
import { renderInput } from '../Common/FormFields';
import { Field } from 'redux-form';

const ChangePassword = () => {
		return(
			<SCCustomerDetails>
				<div className="title-firstname-wrapper">
					<Field
	            		placeholder='Password'
	            		name='password' 
		            	label='Password' 
		            	type='password'
		            	className='email'
		            	component={renderInput} />

		            <Field
	            		placeholder='Confirm Password'
	            		name='confirmPassword'
		            	label='Confirm Password'
		            	type='password'
		            	className='confirmEmail'
		            	component={renderInput} />
				</div>
			</SCCustomerDetails>
		);
}
export default ChangePassword;