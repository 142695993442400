import StoryblokClient, {
  StoryblokResult,
  StoryData,
  StoryblokComponent,
} from "storyblok-js-client";

const Storyblok = new StoryblokClient({
  accessToken: process.env.REACT_APP_STORYBLOK_PREVIEW_TOKEN,
  cache: {
    clear: "auto",
    type: "memory",
  },
});

export type StoryblokDashboard = StoryData<
  StoryblokComponent<"dashboard"> & {
    announcements: Array<{
      _uid: string;
      component: "announcement";
      custom_css: string;
      heading: string;
      region: string;
      first_publish: string;
      last_publish: string;
      icon: {
        id: string;
        alt?: string;
        name?: string;
        focus?: string;
        title?: string;
        filename: string;
        copyright?: string;
        fieldtype?: "asset";
      };
      content: {
        type: string;
        content: any;
      };
      footer: {
        type: string;
        content: any;
      };
    }>;
  }
>;

export const StoryblokGetDashboard = async (): Promise<StoryblokDashboard> => {
  return await Storyblok.get("cdn/stories/customer-portal/dashboard", {
    version: "published",
    cv: Date.now(),
  })
    .then((response: StoryblokResult) => {
      return response.data.story;
    })
    .catch((e) => {
      throw e;
    });
};

export default Storyblok;
