import React, { useState, useEffect } from "react";
import {
  StoryblokDashboard,
  StoryblokGetDashboard,
} from "../../services/storyblok";
import { DateTime } from "luxon";

const localRegion = process.env.REACT_APP_CURRENT_REGION;

const toggleShowRegion = (itemRegion: string) => {
  return localRegion === itemRegion || itemRegion === "all";
};

// provide first_publish hide/show control with storyblok component, 'first_publish' field
const toggleShowFirstPublish = (firstPublish: string) => {
  const defaultDatetime = DateTime.now().toFormat("yyyy-MM-dd T").toString();
  const convertedFirstPublish = firstPublish ? firstPublish : defaultDatetime;

  return firstPublish
    ? DateTime.fromFormat(convertedFirstPublish, "yyyy-MM-dd T", {
        zone: "UTC",
      }).diffNow(["minutes"]).minutes <= 0
    : true; // `first_publish` is nullable, if null then still show announcement
};

// provide last_publish hide/show control with storyblok component, 'last_publish' field
const toggleShowLastPublish = (lastPublish: string) => {
  return lastPublish
    ? DateTime.fromFormat(lastPublish, "yyyy-MM-dd T", { zone: "UTC" }).diffNow(
        ["minutes"]
      ).minutes >= 0
    : true; // `last_publish` is nullable, if null then still show announcement
};

const AnnouncementCards = () => {
  const [announcements, setAnnouncements] = useState<StoryblokDashboard | null>(
    null
  );

  let announcementElement: JSX.Element = <div className="grid-item"></div>;

  useEffect(() => {
    // Add flag for cancel all subscriptions and asnyc tasks
    let isSubscribed = true;

    if (isSubscribed) {
      StoryblokGetDashboard()
        .then((result) => {
          setAnnouncements(result);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    return () => (isSubscribed = false);
  }, []);

  if (announcements !== null) {
    announcementElement = (
      <React.Fragment>
        {announcements.content.announcements
          .filter((announcement) => {
            return (
              toggleShowRegion(announcement.region) &&
              toggleShowLastPublish(announcement.last_publish) &&
              toggleShowFirstPublish(announcement.first_publish)
            );
          })
          .map((announcement) => (
            <div key={announcement._uid} className="grid-item">
              <div className="announcement">
                <div className="announcement__icon">
                  <img
                    src={announcement.icon.filename}
                    alt=""
                    className={`
                      announcement__icon__img
                      ${
                        announcement.icon.filename.includes(".svg")
                          ? "announcement__icon__svg"
                          : ""
                      }`}
                  />
                </div>
                <div className="announcement__caption">
                  <h4 className="announcement__caption__heading">
                    {announcement.heading}
                  </h4>
                  <p className="announcement__caption__paragraph">
                    {announcement.content.content.map((item) =>
                      item.content.map((item, index) => {
                        if (item.marks) {
                          switch (item.marks[0].type) {
                            case "link":
                              return (
                                <a
                                  href={item.marks[0].attrs.href}
                                  target={item.marks[0].attrs.target}
                                  key={index}
                                >
                                  {item.text}
                                </a>
                              );
                            case "bold":
                              return <strong key={index}>{item.text}</strong>;
                            default:
                              return item.text;
                          }
                        }
                        return item.text;
                      })
                    )}
                  </p>
                  <div className="announcement__caption__footer">
                    {announcement.footer.content &&
                      announcement.footer.content.map((item) => {
                        if (item.content)
                          item.content.map((item, index) => {
                            if (item.marks) {
                              switch (item.marks[0].type) {
                                case "link":
                                  return (
                                    <a
                                      href={item.marks[0].attrs.href}
                                      target={item.marks[0].attrs.target}
                                      key={index}
                                    >
                                      {item.text}
                                    </a>
                                  );
                                default:
                                  return item.text;
                              }
                            }
                            return item.text;
                          });
                      })}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </React.Fragment>
    );
  }

  return announcementElement;
};

export default AnnouncementCards;
