import React from "react";
import { SCMakePaymentContainer } from "./css";
import { ButtonRounded, Button, Alert } from "@payright/web-components";
import AlertBox from "../Common/AlertBox";
import { Field, formValueSelector } from "redux-form";
import {
  renderInput,
  renderHidden,
  renderRadioButtonGroup
} from "../Common/FormFields";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import {
  goBackToDashBoard,
  goBackToMakePayment
} from "../../actions/paymentActions";
import * as CONSTANT from "../../util/constants/messages";
import Parser from "html-react-parser";
import CurrencyFormat from "react-currency-format";
import moment from "moment";

const selectedPaymentMethodDetails = defaultPaymentMethod => {
  if (defaultPaymentMethod !== null) {
    if (defaultPaymentMethod.payment_method_type === "Card") {
      return (
        <div className="self-end mobile-self-start">
          {defaultPaymentMethod.cardNumber}{" "}
          {defaultPaymentMethod.cardExpiry !== null && (
            <>
              {" "}
              - <strong> Exp : </strong> {defaultPaymentMethod.cardExpiry}
            </>
          )}
        </div>
      );
    } else {
      return <span className="self-end mobile-self-start">{CONSTANT.DIRECT_DEBIT}</span>;
    }
  } else {
    return <span className="self-end mobile-self-start">{CONSTANT.NO_DEFAULT_PAYMENT_TEXT}</span>;
  }
};

const PaymentOptions = [
  {
    label: CONSTANT.ADDITIONAL_REGULAR_REPAYMENT_LABEL,
    defaultValue: "unscheduled_regular",
    value: "unscheduled_regular",
    readOnly: false
  },
  {
    label: CONSTANT.ONCE_OFF_PAYMENT_LABEL,
    defaultValue: "once_off",
    value: "once_off",
    readOnly: false
  }
];

const validate = (values, props) => {
  const errors = {};
  if (
    values.paymentType &&
    values.paymentType == "once_off" &&
    values.paymentAmount &&
    /^(0|[1-9][0-9]*)$/i.test(values.paymentAmount) &&
    parseFloat(values.paymentAmount) > parseFloat(props.totalBalanceOwing)
  ) {
    errors["paymentAmount"] = "You can't pay more than you owe.";
  }

  return errors;
};

let MakePayment = props => {
  const {
    defaultPaymentMethod,
    handleChangePaymentMethod,
    error,
    handleSubmit,
    submitting,
    failureReason,
    changePaymentMethodSucess,
    showMessages,
    totalBalanceOwing,
    paymentType,
    paymentSuccess,
    pristine,
    numberOfActivePlans,
    nextPaymentDate,
    paymentProcessingFees
  } = props;
  const amountReadOnly =
    typeof paymentType == "undefined" ||
    paymentType === "existing" ||
    paymentType === "unscheduled_regular"
      ? true
      : false;
  const submitReadOnly =
    typeof paymentType == "undefined" || (pristine && paymentType == "once_off")
      ? true
      : false;

  return (
    <SCMakePaymentContainer>
      {paymentSuccess ? (
        <>
          <div className="payment-success-section">
            <div className="success-image"></div>
            <h3 className="page-title">{CONSTANT.PAYMENT_COMPLETED_TEXT}</h3>
            <div className="button-collection">
              <Button handleClick={goBackToDashBoard}>
                {CONSTANT.BACK_TO_DASHBOARD}
              </Button>
              <Button handleClick={goBackToMakePayment} colour="blue" outline>
                {CONSTANT.MAKE_ANOTHER_PAYMENT}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <h3 className="page-title">{CONSTANT.MAKE_PAYMENT_TITLE}</h3>

          {paymentType === "existing" && (
            <div>{CONSTANT.CLEAR_OVER_DUE_MESSAGE}</div>
          )}
          <div className="account-details">
            <h5>Selected Account</h5>
            {selectedPaymentMethodDetails(defaultPaymentMethod)}
            <div className="self-end mobile-self-start">
              <ButtonRounded handleClick={() => handleChangePaymentMethod()}>
                Change
              </ButtonRounded>
            </div>
          </div>

          {failureReason !== null && failureReason !== "" && (
            <AlertBox content={failureReason}></AlertBox>
          )}

          {defaultPaymentMethod !== null &&
          defaultPaymentMethod.payment_method_type !== "Card" ? (
            <AlertBox
              content={CONSTANT.DEFAULT_PAYMENT_DIRECT_DEBIT_MESSAGE}
            ></AlertBox>
          ) : (
            <>
              {numberOfActivePlans > 0 ? (
                <>
                  <div className="alerts">
                    {showMessages(
                      changePaymentMethodSucess,
                      error,
                      CONSTANT.CHANGED_CARD_SUCCESSFULLY_MESSAGE
                    )}
                  </div>
                  <form onSubmit={handleSubmit}>
                    {paymentType !== "existing" && (
                      <>
                        <div className="column-sub-heading">
                          <h5>Select Payment Type</h5>
                        </div>
                        <div className="radio-group">
                          <Field
                            name="paymentType"
                            options={PaymentOptions}
                            readOnly={false}
                            component={renderRadioButtonGroup}
                          />
                        </div>
                      </>
                    )}
                    {paymentType === "unscheduled_regular" &&
                      nextPaymentDate !== "" && (
                        <div className="small-text text-block">
                          {Parser(CONSTANT.UNSCHEDULE_REGULAR_PAYMENT_NOTE)}{" "}
                          {moment(nextPaymentDate).format("Do of MMM")}
                        </div>
                      )}

                    <div className="make-payment-section">
                      <div className="content">
                        <h4>Make a Payment of</h4>
                        <Field
                          placeholder=""
                          name="paymentAmount"
                          label=""
                          className="paymentAmount"
                          prefix="$"
                          readOnly={amountReadOnly}
                          component={renderInput}
                        />
                      </div>
                      {paymentType === "once_off" && (
                        <div className="small-text">
                          Maximum{" "}
                          <strong>
                            {totalBalanceOwing !== "" || totalBalanceOwing ? (
                              <CurrencyFormat
                                value={parseFloat(totalBalanceOwing).toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            ) : (
                              0.0
                            )}{" "}
                          </strong>
                          -{" "}
                          {Parser(CONSTANT.PAYMENT_PROCESSING_FEE_TEXT_BEFORE)}{" "}
                          <strong>${paymentProcessingFees}</strong>{" "}
                          {Parser(CONSTANT.PAYMENT_PROCESSING_FEE_TEXT_AFTER)}
                        </div>
                      )}

                      {paymentType === "unscheduled_regular" && (
                        <div className="small-text">
                          {Parser(
                            CONSTANT.ADDITIONAL_TEXT_FOR_UNSCHEDULE_PAYMENT
                          )}{" "}
                          ${paymentProcessingFees}
                        </div>
                      )}
                    </div>
                    <Field
                      name="id"
                      type="hidden"
                      className="paymentId"
                      component={renderHidden}
                    />

                    <Button
                      type="submit"
                      maxWidth="100%"
                      disabled={submitReadOnly || submitting}
                    >
                      Pay Now
                    </Button>
                  </form>
                </>
              ) : (
                <div className="alert-box-msg">
                  <Alert
                    title=""
                    icon={<></>}
                    body={CONSTANT.NOTHING_TO_PAY}
                    outcome="pending"
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </SCMakePaymentContainer>
  );
};

const selector = formValueSelector("AddPayment");

MakePayment = reduxForm({
  form: "AddPayment",
  enableReinitialize: true,
  validate
})(MakePayment);

const mapStateToProps = state => {
  let paymentType =
    state.pageData.selectedOverDuePayment !== null
      ? "existing"
      : selector(state, "paymentType");
  const initValues =
    state.pageData.selectedOverDuePayment !== null
      ? state.pageData.selectedOverDuePayment
      : {};
  const nextRepatmentAmount =
    state.pageData.nextPayments !== null &&
    typeof state.pageData.nextPayments[0] !== "undefined"
      ? state.pageData.nextPayments[0].repaymentAmount
      : 0.0;
  const nextPaymentDate =
    state.pageData.nextPayments !== null &&
    typeof state.pageData.nextPayments[0] !== "undefined"
      ? state.pageData.nextPayments[0].nextPaymentDate
      : "";
  const repaymentAmount =
    nextRepatmentAmount > 0.0
      ? (
          parseFloat(nextRepatmentAmount) -
          parseFloat(state.pageData.customer.accountKeepingFee)
        ).toFixed(2)
      : 0.0;
  const paymentProcessingFees = parseFloat(
    state.pageData.customer.paymentProcessingFee
  ).toFixed(2);

  if (paymentType === "unscheduled_regular") {
    initValues.paymentAmount = repaymentAmount;
  }
  initValues.paymentType = paymentType;

  return {
    defaultPaymentMethod: state.pageData.defaultPaymentMethod,
    initialValues: initValues,
    changePaymentMethodSucess: state.payments.changePaymentMethodSucess,
    paymentType: paymentType,
    paymentSuccess: state.payments.paymentSuccess,
    numberOfActivePlans: state.pageData.customer.numberOfActivePlans,
    nextPaymentDate: nextPaymentDate,
    paymentProcessingFees: paymentProcessingFees
  };
};

export default connect(mapStateToProps)(MakePayment);
