import React from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { 
    renderCreditCard,
    renderHidden,
} from '../Common/FormFields';
import { SCMakePaymentContainer } from './css';
import {
    CreditCardAddNew,
    Button,
} from '@payright/web-components';
import { Link } from 'react-router-dom'; 
import { reduxForm } from 'redux-form';
import { setCurrentSelectedCard, handleGoBackToMakePayment } from '../../actions/paymentActions';
import * as CONSTANT from '../../util/constants/messages';
import { isCardExpired } from '../../util/helpers';

const handleCancleClick = (props) => {
    props.dispatch(handleGoBackToMakePayment());
}

let ChangePayment = (props) => {

    const { handleSubmit, paymentMethods, handleAddNewCard, setSelectedCard, selectedCardNumber, showMessages,  error, submitting, addPaymentMethodSuccess } = props;
    props.change('paymentMethodId', selectedCardNumber);

    const cardPayments = paymentMethods.filter((method) => method.payment_method_type === 'Card');

    const hasValidCardSelected = () => paymentMethods.some((method: {enabled: boolean, cardExpiry: string}) => method.enabled && !isCardExpired(method.cardExpiry));

    return(
        <SCMakePaymentContainer>
                <form  className="addPayment" onSubmit={handleSubmit}>
                    <h3 className='page-title'>{ CONSTANT.CHANGE_PAYMENT_METHOD_PAGE_TITLE}</h3>
                    <div className="alerts">
                        { showMessages(addPaymentMethodSuccess, error, CONSTANT.CARD_ADDED_SUCCESSFULLY_MESSAGE )}
                    </div>
                    { cardPayments.map((method, i) => {
                        const expired = isCardExpired(method.cardExpiry);
                        return(
                            <div className='payment-method-row' key={i}>
                                <Field
                                    name="payment-method"
                                    label='Make default method' 
                                    className='paymentType'
                                    defaultValue="default-method"
                                    selected={method.enabled}
                                    last4Digits={method.cardLastDigits}
                                    cardType={method.cardProvider.toLowerCase()}
                                    cardExpiry={method.cardExpiry}
                                    expired={expired}
                                    selectCard={setSelectedCard(method.id)}

                                    component={renderCreditCard} /> 
                            </div>
                        );
                    })}
                    <div className='payment-method-row'>
                        <CreditCardAddNew handleClick={handleAddNewCard} />    
                    </div>
                    <Field 
                        type='hidden'
                        name='paymentMethodId'
                        defaultValue={selectedCardNumber}
                        component={renderHidden}
                    />
                    <div className='btn-section'>
                        <Link to='#' className='cancel-btn' onClick={() => handleCancleClick(props)}>Cancel</Link>
                        <Button colour='blue'  type='submit' maxWidth="20%" disabled={submitting || !hasValidCardSelected()}>
                        { submitting ?
                            <>
                                { CONSTANT.SUBMITTING_TEXT }
                            </>
                        :
                            <>
                                { CONSTANT.CHANGE_CARD_BUTTON }
                            </>
                       }
		          	    </Button>
                    </div>
                </form>
        </SCMakePaymentContainer>
    );
}

const mapDispatchToProps = dispatch => ({
    setSelectedCard (id) {
        return () => {
            dispatch(setCurrentSelectedCard(id))
        }
    }
});

const mapStateToProps = state =>  {
    return {
        selectedCard : state.selectedCard,
        addPaymentMethodSuccess: state.payments.addPaymentMethodSuccess,
    };
}

ChangePayment = reduxForm({
	form: 'SelectPaymentMethod',
}) (ChangePayment);

export default connect(mapStateToProps, mapDispatchToProps)(ChangePayment);