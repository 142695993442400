import React from "react";
import {
  InputField,
  Dropdown,
  DatePicker,
  DropdownWithInput,
  InputCreditCard,
  Checkbox,
  Calendar,
  CalendarStandard,
  CreditCard,
  IconInfo,
  TextArea
} from "@payright/web-components";
import Tooltip from "react-tooltip-lite";
import Moment from "moment";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const renderInput = ({
  input,
  label,
  className,
  type,
  maxWidth,
  encryptName,
  prefix,
  readOnly,
  tooltip,
  placeholder,
  meta: { error }
}) => (
  <InputField
    {...input}
    handleInputChange={input.onChange}
    error={error}
    className={className}
    type={type}
    maxWidth={maxWidth}
    encryptName={encryptName}
    prefix={prefix}
    readOnly={readOnly}
    placeholder={placeholder}
  >
    {label}{" "}
    {tooltip && (
      <>
        <Tooltip
          content={tooltip}
          className="tooltip"
          eventOff={isMobile ? "touchstart" : undefined}
        >
          <IconInfo />
        </Tooltip>
      </>
    )}
  </InputField>
);

export const renderHidden = ({ input }) => <input {...input} type="hidden" />;

export const renderTextArea = ({
  input,
  label,
  className,
  type,
  maxWidth,
  placeholder,
  readOnly,
  meta: { error }
}) => (
  <TextArea
    {...input}
    handleInputChange={input.onChange}
    error={error}
    className={className}
    type={type}
    maxWidth={maxWidth}
    readOnly={readOnly}
    placeholder={placeholder}
  >
    {label}
  </TextArea>
);

export const renderDropDown = ({
  input,
  label,
  className,
  maxWidth,
  options,
  readOnly,
  meta: { error }
}) => (
  <Dropdown
    {...input}
    handleSelect={input.onChange}
    error={error}
    className={className}
    options={options}
    maxWidth={maxWidth}
    readOnly={readOnly}
  >
    {label}
  </Dropdown>
);

export const renderDatePicker = ({
  input,
  label,
  date,
  className,
  maxWidth,
  readOnly,
  tooltip,
  meta: { error }
}) => (
  <DatePicker
    {...input}
    handleDateChange={input.onChange}
    date={date}
    className={className}
    label={label}
    maxWidth={maxWidth}
    error={error}
    readOnly={readOnly}
    tooltip={tooltip}
  />
);

export const renderDropdownWithInput = inputProps => (
  <DropdownWithInput
    {...inputProps}
    handleSelect={inputProps.input.onChange}
    handleInputChange={inputProps.input.onChange}
  >
    {inputProps.label}
  </DropdownWithInput>
);

export const renderCalendar = inputProps => (
  <Calendar
    {...inputProps}
    handleSelect={inputProps.input.onChange}
    name={inputProps.input.name}
  />
);

export const renderCalendarStandard = inputProps => (
  <CalendarStandard
    {...inputProps}
    value={
      inputProps.input.value ? new Date(inputProps.input.value) : new Date()
    }
    handleDateChange={date => {
      inputProps.input.onChange(Moment(date).format("YYYY-MM-DD"));
    }}
    name={inputProps.input.name}
  />
);

export const renderInputCreditCard = ({
  input,
  cardNo,
  encryptName,
  cardType,
  meta: { error }
}) => (
  <InputCreditCard
    {...input}
    cardNo={cardNo}
    error={error}
    cardType={cardType}
    handleInputChange={input.onChange}
    handleClick={input.onChange}
    encryptName={encryptName}
  />
);

export const renderCheckbox = ({ input, checked, label, handleClick }) => (
  <Checkbox {...input} checked={checked} handleClick={handleClick}>
    {label}
  </Checkbox>
);

export const renderRadioButton = ({
  input,
  readOnly,
  defaultValue,
  label,
  meta: { error }
}) => (
  <label>
    <input type="radio" {...input} value={defaultValue} />
    {label}
  </label>
);

export const renderRadioButtonGroup = ({
  input,
  readOnly,
  options,
  defaultValue,
  label,
  meta: { error }
}) => (
  <>
    {options.map((option, i) => {
      return (
        <label key={i}>
          <input type="radio" {...input} value={option.defaultValue} />
          {option.label}
        </label>
      );
    })}
    {error && <div className="error">{error}</div>}
  </>
);

export const renderCreditCard = ({
  input,
  last4Digits,
  cardType,
  selected,
  selectCard,
  cardExpiry,
  expired
}) => {
  return <CreditCard
    last4Digits={last4Digits}
    cardType={cardType}
    selected={selected}
    selectCard={selectCard}
    cardExpiry={cardExpiry}
    expired={expired}
  />
};
