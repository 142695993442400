import React from "react";
import { connect } from "react-redux";
import { SCContactUs, ContactUsContainer } from "./css";
import { HeaderCustomer, Navigation, Button } from "@payright/web-components";

import {
  CUSTOMER_MENU_ITEMS,
  SUB_MENU_ACTIONS
} from "../../util/constants/types";
import Footer from "../Footer";
import { reduxForm, Field } from "redux-form";
import {
  renderHidden,
  renderTextArea,
  renderInput
} from "../Common/FormFields";
import { Notifications } from "../Notifications/Notifications";

let ContactUsForm = props => {
  const {
    handleSubmit,
    submitting,
    showMessages,
    error,
    submitSucceeded,
    notification
  } = props;
  return (
    <SCContactUs>
      <div className="profile-content">
        <Navigation
          breakpoint="wide"
          portalType="customer"
          menuItems={CUSTOMER_MENU_ITEMS}
          activePage="account"
          notifications={Notifications(notification)}
          accountDetailsActions={SUB_MENU_ACTIONS}
        />
        <HeaderCustomer title="Contact Us" />
        <ContactUsContainer>
          <h3 className="page-title">Contact Us</h3>
          {(submitSucceeded || error) && (
            <div className="alerts">{showMessages(submitSucceeded, error)}</div>
          )}
          <h5>
            Got a question? We'd love to hear from you. Send us a message and
            we'll respond as soon as possible.
          </h5>
          <div className="form-content">
            <form className="addPayment" onSubmit={handleSubmit}>
              <Field name="fullName" component={renderHidden} />
              <Field
                name="phoneMobile"
                placeholder="Best contact number"
                label="Best contact number"
                type="number"
                component={renderInput}
              />
              <Field
                placeholder="Go ahead, we're listening..."
                name="enquiry"
                label=""
                type="text"
                className="enquiry"
                component={renderTextArea}
              />
              <Button
                colour="blue"
                type="submit"
                className="save-btn"
                disabled={submitting}
              >
                {submitting ? <>Submitting...</> : <>Submit</>}
              </Button>
            </form>
          </div>
        </ContactUsContainer>
      </div>
      <Footer />
    </SCContactUs>
  );
};

ContactUsForm = reduxForm({
  form: "ContactUs",
  enableReinitialize: true
})(ContactUsForm);

export default connect(state => ({
  initialValues: state.pageData.customer
}))(ContactUsForm);
