import {
    FETCH_PLANDETAIL_BEGIN,
    FETCH_PLANDETAIL_SUCCESS,
    FETCH_PLANDETAIL_FALIURE,
} from "../actions/planDetailAction";

const initialState = {
    planDetailData: '',
    loading: false,
    error: null,

}

export default function planDetailReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case FETCH_PLANDETAIL_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            }

        case FETCH_PLANDETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                planDetailData: action.payload.planDetail
            }

        case FETCH_PLANDETAIL_FALIURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                planDetailData: ''
            }
        default:
            return state;
    }
}