import axios from '../util/payrightAxios';
import encrypt from '../util/eCrypt';
import { SubmissionError } from 'redux-form';
import {
  CHANGE_PAYMENT_METHOD_SUCCESS,
  ADD_NEW_PAYMENT_METHOD_SUCCESS,
  ADD_PAYMENT_METHOD,
  CHANGE_PAYMENT_METHOD,
  PAY_NOW_BUTTON_CLICK,
  GO_BACK_TO_MAKE_PAYMENT,
  MAKE_PAYMENT_LOAD,
  CHANGE_CARD,
  PAYMENT_SUCCESS,
  API_FAILURE,
} from '../actions/types';
import history from '../history';
import Payment from 'payment';
import { FetchCustomerDetails } from './index';
import { eventTracking } from './eventTracking';

export const handleAddCardSubmit = (values, dispatch) => {

  // Encrpt values
  if (typeof values.cardNumber !== 'undefined') {
    values.cardNumber = values.cardNumber.replace(/\s/g, '');
    values.number = encrypt(values.cardNumber);
    values.cardType = Payment.fns.cardType(values.cardNumber);
  }
  if (typeof values.cvnNumber !== 'undefined') {
    values.cvn = encrypt(values.cvnNumber);
    values.cvnNumber = null;
  }

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const customerId = localStorage.getItem('customerId');
  const authToken = localStorage.getItem('authToken');
  const url = `${apiBaseUrl}/customer/${customerId}/payment_method/Eway`;

  axios.defaults.headers.common['auth-token'] = authToken;

  return axios(url, {
    method: 'POST',
    data: values,
  }).then(function (response) {

    dispatch({ type: ADD_NEW_PAYMENT_METHOD_SUCCESS, payload: response.data });
    eventTracking({category: 'Payments', action: 'Added a new card'});

  }).catch((error) => {

    const validationsErrors = getErrorMessage(error.response.data.message, 'Please update the details and resubmit it again');

    window.scrollTo(0, 0);
    throw new SubmissionError(validationsErrors)
  });
}

export const handleCloudPaymentSubmit = (values, dispatch) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const customerId = localStorage.getItem('customerId');
  const authToken = localStorage.getItem('authToken');
  const url = `${apiBaseUrl}/customer/${customerId}/payment_method/Cloud_Payment`;

  axios.defaults.headers.common['auth-token'] = authToken;

  return axios(url, {
    method: 'POST',
    data: values,
  }).then(function (response) {

    dispatch({ type: ADD_NEW_PAYMENT_METHOD_SUCCESS, payload: response.data });
    eventTracking({category: 'Payments', action: 'Added a new card'});

  }).catch((error) => {

    const validationsErrors = getErrorMessage(error.response.data.message, 'Please update the details and resubmit it again');

    window.scrollTo(0, 0);
    throw new SubmissionError(validationsErrors)
  });
}

export const handlePaymentSubmit = (values, dispatch) => {

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const customerId = localStorage.getItem('customerId');
  const authToken = localStorage.getItem('authToken');
  const url = `${apiBaseUrl}/customer/${customerId}/payment`;

  axios.defaults.headers.common['auth-token'] = authToken;
 
  return axios(url, {
    method: 'POST',
    data: values,
  }).then(function (response) {

    dispatch({ type: PAYMENT_SUCCESS, payload: response.data });
    dispatch(FetchCustomerDetails());
    eventTracking({category: 'Payments', action: 'Made a payment'});

  }).catch((error) => {

    const validationsErrors = getErrorMessage(error.response.data.message, 'Please check your account details and available funds and try again');

    dispatch({ type: API_FAILURE, payload: error });
    throw new SubmissionError(validationsErrors)
  });
}

export const handleChangeCardSubmit = (values, dispatch) => {

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const customerId = localStorage.getItem('customerId');
  const authToken = localStorage.getItem('authToken');
  const url = `${apiBaseUrl}/customer/${customerId}/payment_method/${values.paymentMethodId}`;

  axios.defaults.headers.common['auth-token'] = authToken;

  return axios(url, {
    method: 'PUT',
  }).then(function (response) {
 
      dispatch({ type: CHANGE_PAYMENT_METHOD_SUCCESS, payload: response.data });
      eventTracking({category: 'Payments', action: 'Changed the card'});
  }).catch((error) => {

    const validationsErrors = getErrorMessage(error.response.data.message, 'Please update the details and resubmit it again');

    window.scrollTo(0, 0);
    throw new SubmissionError(validationsErrors);
  });

}

export const handleSelectCard = (paymentMethodId) => {


}

export const setCurrentSelectedCard = (paymentId) => {

  return {
    type: CHANGE_CARD,
    paymentId
  }

}

export const handleChangePaymentMethod = () => {
  return {
    type: CHANGE_PAYMENT_METHOD
  }
}

export const handleAddNewCard = () => {
  return {
    type: ADD_PAYMENT_METHOD
  }
}

export const handlePayNowClick = (paymentId) => {
  return {
    type: PAY_NOW_BUTTON_CLICK,
    paymentId
  }
}

export const handleGoBackToMakePayment = () => {
  return {
    type: GO_BACK_TO_MAKE_PAYMENT,
  }
}

export const makePaymentLoad = () => {
  return {
    type: MAKE_PAYMENT_LOAD
  }
}

export const goBackToDashBoard = () => {
  history.push('/dashboard');
}

export const goBackToMakePayment = () => {
  history.push('/payments');
}

export const getErrorMessage = (response, errorMsg) => {
  try {
    let message = JSON.parse(response);
    message._error = errorMsg;
    return message;
  } catch (e) {
    return {
      _error: response
    };
  }
}
