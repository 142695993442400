import React, { useState } from 'react';
import SCForgotDetails from "./css";
import axios from '../../util/payrightAxios';
import { reduxForm, Field } from 'redux-form';
import {
    Button,
    IconLogo,
    MessageBox
} from '@payright/web-components';
import { renderInput } from '../Common/FormFields';
import history from '../../history';
import {
    SUCCESSFUL_FORGOT_PASSWORD_TITLE,
    SUCCESSFUL_FORGOT_PASSWORD_MESSAGE,
    FORGOT_PASSWORD_CONTACT_US_MESSAGE
} from '../../util/constants/types';

const ForgotPassword = (props) => {

    let pageTitle = 'Forgot Password';
    if (props.createPassword) {
        pageTitle = 'Create a new password';
    }
    const [forgotPasswordMessageId, setforgotPasswordMessageId] = useState('');
    const [forgotPasswordErrors, setforgotPasswordErrors] = useState('');

    const handleForgotPassword = (e) => {
        e.preventDefault();
        const userName = e.target[0].value;
        // const email = e.target[1].value;

        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        const url = `${apiBaseUrl}/customer/forgot_password`;

        return axios(url, {
            method: 'POST',
            data: {
                userName,
            }
        }).then(response => {
            setforgotPasswordMessageId(response.data.data);
        }).catch((error) => {
            setforgotPasswordErrors('Invalid user name. Please try again or contact us at admin@payright.com.au');
        });

    };

    const { pristine, submitting } = props;
    const title = SUCCESSFUL_FORGOT_PASSWORD_TITLE;
    const message = SUCCESSFUL_FORGOT_PASSWORD_MESSAGE;
    const contactUsMessage = FORGOT_PASSWORD_CONTACT_US_MESSAGE;

    const handleRedirect = () => {
        history.push('/');
    }

    return (
        <SCForgotDetails>
            <div className="ecommerce-image" />
            <div className="login-wrapper">
                <div className="login-header">
                    <div className="logo">
                        <IconLogo width={180} height={74} />
                    </div>
                    <h4>{pageTitle}</h4>
                </div>
                {forgotPasswordMessageId !== '' ?
                    <div>
                        <MessageBox
                            title={title}
                            message={
                                <>
                                    <div>{message}</div>
                                    <br />
                                    <div>{contactUsMessage}</div>
                                </>
                            }
                            handleOnClick={handleRedirect} />
                    </div> :
                    <form className="ForgotPassword" onSubmit={handleForgotPassword}>
                        {forgotPasswordErrors && <div className="validation-msg-box">{forgotPasswordErrors}</div>}

                        <Field
                            name="userName"
                            label="User Name"
                            type="text"
                            component={renderInput}
                            placeholder="admin@payright.com.au" />

                        <div className="buttonClass">
                            <Button
                                withShadow
                                maxWidth="50%"
                                type="submit"
                                handleClick={handleRedirect}
                            >Cancel
                           </Button>
                            <span className="filler">
                                <h5></h5>
                            </span>
                            <Button
                                withShadow
                                maxWidth="50%"
                                type="submit"
                                disabled={pristine || submitting}
                            >Submit
                        </Button>
                        </div>

                    </form>
                }
            </div>
        </SCForgotDetails>
    )
}

export default reduxForm({
    form: 'ForgotPassword',
})(ForgotPassword);