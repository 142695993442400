export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';

export const API_FAILURE = 'API_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const FETCH_CUSTOMER = 'FETCH_CUSTOMER';
export const FETCH_PAYMENT_METHODS = 'FETCH_PAYMENT_METHODS';

export const PROFILE_UPDATE = 'PROFILE_UPDATE';

export const CHANGE_CARD = 'CHANGE_CARD';
export const CHANGE_PAYMENT_METHOD_SUCCESS = 'CHANGE_PAYMENT_METHOD_SUCCESSFULL';
export const ADD_NEW_PAYMENT_METHOD_SUCCESS = 'ADD_NEW_PAYMENT_METHOD_SUCCESS';
export const CHANGE_PAYMENT_METHOD = 'CHANGE_PAYMENT_METHOD';
export const ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD';

export const PAY_NOW_BUTTON_CLICK = 'PAY_NOW_BUTTON_CLICK';
export const MAKE_PAYMENT_LOAD = 'MAKE_PAYMENT_LOAD';
export const GO_BACK_TO_MAKE_PAYMENT = 'GO_BACK_TO_MAKE_PAYMENT';

export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';

/** Plans related */
export const FETCH_PLANS_BEGIN = "FETCH_PLANS_BEGIN";
export const FETCH_PLANS_SUCCESS = "FETCH_PLANS_SUCCESS";
export const FETCH_PLANS_FALIURE = "FETCH_PLANS_FALIURE";

/** Transaction related */
export const FETCH_TRANSACTION_BEGIN = "FETCH_TRANSACTION_BEGIN";
export const FETCH_TRANSACTION_SUCCESS = "FETCH_TRANSACTION_SUCCESS";
export const FETCH_TRANSACTION_FAILURE = "FETCH_TRANSACTION_FALIURE";

/** Notification related */
export const FETCH_NOTIFICATION_BEGIN = "FETCH_NOTIFICATION_BEGIN";
export const FETCH_NOTIFICATION_SUCCESS = "FETCH_NOTIFICATION_SUCCESS";
export const FETCH_NOTIFICATION_FAILURE = "FETCH_NOTIFICATION_FAILURE";
/** Init Data  */
export const INIT_DATA_READY = "INIT_DATA_READY";

/** Customer Enquiry */
export const CUSTOMER_ENQUIRY_SENT = 'CUSTOMER_ENQUIRY_SENT';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_COMPLETED = 'RESET_PASSWORD_COMPLETED';

/** Statement related */

export const FETCH_STATEMENT_BEGIN = "FETCH_STATEMENT_BEGIN";
export const FETCH_STATEMENT_SUCCESS = "FETCH_STATEMENT_SUCCESS";
export const FETCH_STATEMENT_FAILURE = "FETCH_STATEMENT_FAILURE";
