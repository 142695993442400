import React from "react";
import { PayrightThemeProvider } from "@payright/web-components";
import { connect } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router";

import Login from "./Login";
import Dashboard from "./Dashboard";
import Plan from "./Plans/Plan";
import ViewPlan from "./Plans/ViewPlan";
import Profile from "./Profile";
import Logout from "./Logout";
import ContactUs from "./ContactUs";
import MyTransactions from "./Transactions/TransactionsList";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import PaymentContainer from "./Payment";
import CloudPayments from "./Payment/CloudPayment";
import NeedHelp from "./NeedHelp";
import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";
import history from "../history";
import Statements from "./Statements";
import NotFound from "./NotFound";
import Maintenance from "./Maintenance";

interface AppProps {
  history: History;
  isSignedIn: boolean;
}


// TODO Unused const ProtectedRoute arrow function
// const ProtectedRoute = ({ component: Component, isSignedIn, ...rest }) => (
//   <Route
//     {...rest}
//     render={props =>
//       isSignedIn === true ? <Component {...props} /> : <Redirect to="/" />
//     }
//   />
// );

// After certain hours clear the local storage.
const clearLocalStorage = () => {
  let hours = 24;
  let saved = localStorage.getItem("saved");
  if (
    saved !== null &&
    new Date().getTime() - parseInt(saved) > hours * 60 * 60 * 1000
  ) {
    localStorage.clear();
  }
};
// Clear storage
clearLocalStorage();

const initializeTracking = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_CODE);
  // Setting User ID
  if (localStorage.getItem("customerId") !== null) {
    ReactGA.set({ userId: localStorage.getItem("customerId") });
  }
  history.listen((location, action) => {
    // Setting User ID
    if (localStorage.getItem("customerId") !== null) {
      ReactGA.set({ userId: localStorage.getItem("customerId") });
    }
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

  // Hot Jar tracking
  hotjar.initialize(
    parseInt(process.env.REACT_APP_HOTJAR_ID),
    parseInt(process.env.REACT_APP_HOTJAR_SNIPPET_VERSION)
  );
};
// Initialize tracking
initializeTracking();

const App = ({ history, isSignedIn }: AppProps) => {
  return (
    <ConnectedRouter history={history}>
      <PayrightThemeProvider>
        <Switch>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/profile" component={Profile} />
          <Route path="/my-plans" component={Plan} />
          <Route path="/view-plan/:id" component={ViewPlan} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/my-transactions" exact component={MyTransactions} />
          <Route path="/payments" exact component={PaymentContainer} />
          <Route path="/logout" component={Logout} />
          <Route path="/cloud_payments" exact component={CloudPayments} />
          <Route path="/need-help" exact component={NeedHelp} />
          <Route path="/maintenance" exact component={Maintenance} />
          <Route path="/statements" component={Statements} />
          <Route path={["/", "/login"]} exact component={Login} />
          <Route path="/forgot-password" exact component={ForgotPassword} />
          <Route
            path="/create-password"
            extact
            render={(props) => <ForgotPassword createPassword={true} />}
          />
          <Route path="*" component={NotFound} />
        </Switch>
      </PayrightThemeProvider>
    </ConnectedRouter>
  );
};
const mapStateToProps = (state) => {
  return {
    isSignedIn: localStorage.getItem("isSignedIn"),
  };
};

export default connect(mapStateToProps, null)(App);
