export const USER_PASSWORD_STATUS = "User Change Required";
export const TRANSACTION_DEFAULT_FILTER_VALUE = 30;

export const PASSWORD_SUCCESSFUL_MESSAGE = "Password Updated Successfully!!";
export const PASSWORD_NEXT_LOGIN =
  "Use this new password at the time of next login.";

// Paging Configuration
export const RECORDS_PER_PAGE = 10;
export const LOAD_PER_PAGE = 10;

// Not Found Records in Tables
export const TRANSACTION_NOT_FOUND =
  "No transactions found within selected days, please select another date range.";
export const PLAN_NOT_FOUND = "No Plans Found";

//Forgot Password
export const SUCCESSFUL_FORGOT_PASSWORD_TITLE = "SMS has been sent!";
export const SUCCESSFUL_FORGOT_PASSWORD_MESSAGE =
  "A temporary password has been sent to your registered mobile number. Use that password with your user name to generate new password.";

// Tooltip: Profile page
export const TOOLTIP_NON_EDITABLE_FIELDS =
  "Please call us on 1300 338 496 to change this detail";

// Dashboard Text
export const SUCCESS_ALERT_TITLE = "Successful!";
export const ERROR_ALERT_TITLE = "Oops..! That didn't go through well";
export const PAYMENT_FAILURE_TITLE =
  "Oops..! Your payment has not gone through.";
export const GENERAL_ALERT_SUCCESS_MSG = "Record created successfully.";
export const PAGE_SUBTITLE = "Welcome to Payright";
export const DASHBOARD_PAYMENT_UPTO_DATE_TITLE = "Your payments are up to date";
export const DASHBOARD_PAYMENT_UPTO_DATE_CONTENT =
  "Want to pay off your plans sooner? You can make additional payments on your remaining plans at any time.";
export const DASHBOARD_PAYMENT_UPTO_DATE_IMAGE_PATH =
  "https://payright.com.au/wp-content/uploads/why_browsing-into-buying.png";
export const DASHBOARD_ANNOUNCEMENT_APP_TITLE = "Get Payright in your pocket";
export const DASHBOARD_ANNOUNCEMENT_APP_CONTENT =
  "Apply for pre-approval before you buy, browse Payright partners and manage your account whenever, wherever.";

export const LOADING_TEXT = "Loading....";

// Payment Page
export const PAYMENT_COMPLETED_TEXT = "Payment Completed !";
export const PAYMENT_COMPLETED_DESCRIPTIOn =
  "Your payment has been processed. You can make another payment at any time.";
export const BACK_TO_DASHBOARD = "Back to Dashboard";
export const MAKE_ANOTHER_PAYMENT = "Make another payment";
export const CLEAR_OVER_DUE_MESSAGE =
  "You can make additional payments once overdue payments have been made";
export const NO_DEFAULT_PAYMENT_TEXT = "No default Payment";
export const DEFAULT_PAYMENT_DIRECT_DEBIT_MESSAGE =
  "Your default payment option is set to direct debit. You will need to select or add a credit/debit card to make an immediate payment to your Payright account.";
export const CHANGED_CARD_SUCCESSFULLY_MESSAGE =
  "You have changed to a new card.";
export const PAYMENT_PROCESSING_FEE_TEXT_BEFORE = `A payment processing fee of `;
export const PAYMENT_PROCESSING_FEE_TEXT_AFTER = `will also be applied against your account.`;
export const ADDITIONAL_REGULAR_REPAYMENT_LABEL = "Additonal Repayment";
export const ONCE_OFF_PAYMENT_LABEL = "Any Amount";
export const MAKE_PAYMENT_TITLE = "Make a Payment";
export const ADDITIONAL_TEXT_FOR_UNSCHEDULE_PAYMENT = `This amount includes a payment processing fee of `;
export const MAKE_PAYMENT_SUB_TITLE =
  "Make a payment to your Payright loan account";
export const UNSCHEDULE_REGULAR_PAYMENT_NOTE =
  "NOTE:  This is an additional repayment, and does not replace your next scheduled payment on the ";

// Change Payment method Page
export const CHANGE_PAYMENT_METHOD_PAGE_TITLE = "Select Payment Method";
export const CHANGE_PAYMENT_METHOD_PAGE_SUB_TITLE =
  "Make a payment to your Payright loan account";
export const ADD_NEW_CARD_BUTTON = "Add new card";
export const CHANGE_CARD_BUTTON = "Change Card";
export const CARD_ADDED_SUCCESSFULLY_MESSAGE = "Card Added Successfully.!";
export const SUBMITTING_TEXT = "Submitting...";
export const DIRECT_DEBIT = "Direct Debit";
export const NOTHING_TO_PAY = "You don't have anything to pay.";

//header and footer company details for statement
export const COMPANY_ABN_AU = "Devizo Finance AU Pty Ltd ABN 21 622 104 527";
export const COMPANY_NZBN_NZ = "Devizo Finance NZ Ltd NZBN 9429047240339";
export const COMPANY_BANK_DETAILS_AU = "ABN  ABN 21 622 104 527";
export const COMPANY_BANK_DETAILS_NZ = "NZBN 9429047240339";

// Add Payment Page
export const CVN_TOOL_TIP = "Three digits in the back of the card.";

// Footer Links
export const FOOTER_LINKS_AU = [
  {
    name: "Financial Hardship",
    link: "https://www.payright.com.au/financial-hardship/",
  },
  {
    name: "Product Suitability",
    link: "https://payright.com.au/product-suitability/",
  },
  {
    name: "Privacy Policy",
    link: "https://www.payright.com.au/privacy-policy/",
  },
  {
    name: "Terms & Conditions",
    link: "https://www.payright.com.au/terms-and-conditions/",
  },
  {
    name: "Complaints & Feedback",
    link: "https://www.payright.com.au/complaints/",
  },
];

// Footer Links
export const FOOTER_LINKS_NZ = [
  {
    name: "Financial Hardship",
    link: "https://www.payright.co.nz/financial-hardship/",
  },
  {
    name: "Product Suitability",
    link: "https://payright.co.nz/product-suitability/",
  },
  {
    name: "Privacy Policy",
    link: "https://www.payright.co.nz/privacy-policy/",
  },
  {
    name: "Terms & Conditions",
    link: "https://www.payright.co.nz/terms-and-conditions/",
  },
  {
    name: "Complaints & Feedback",
    link: "https://www.payright.co.nz/complaints/",
  },
];
