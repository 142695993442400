import React from 'react';

import { SCAlertBox } from './alertBoxCss';
import {
    IconAttention
  } from '@payright/web-components';
 
interface AlertProps {
    content: string;
}
const AlertBox = ({content}: AlertProps) => {
    return(
        <SCAlertBox>
            <div className='icon'>
                <IconAttention />
            </div>
            <div className='content'>
                {content}
            </div>
        </SCAlertBox>
    );
}

export default AlertBox;