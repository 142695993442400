import LogRocket from 'logrocket';
import {
  SIGN_IN,
  SIGN_OUT,
  FETCH_CUSTOMER,
  API_FAILURE,
  INIT_DATA_READY,
  CUSTOMER_ENQUIRY_SENT,
  RESET_PASSWORD_COMPLETED,
} from './types';

import {
  getErrorMessage
} from './paymentActions';

import axios from '../util/payrightAxios';
import history from '../history';
import { SubmissionError } from 'redux-form';

export const signIn = (userId) => {
  return {
    type: SIGN_IN,
    payload: userId
  };
};

export const signOut = () => {
  return {
    type: SIGN_OUT
  };
};

export const loadPageData = () => async (dispatch, getState) => {
  dispatch(FetchCustomerDetails());
  dispatch(FetchConfigurations());
}

export const FetchConfigurations = () => async (dispatch, getState) => {


  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const url = `${apiBaseUrl}/configuration`;

  //axios.defaults.headers.common['auth-token'] = authToken;

  axios(url, {
    method: 'GET',
  }).then(function (response) {
    dispatch({ type: INIT_DATA_READY, payload: response.data });
  }).catch((error) => {

    dispatch(apiFailure(error.response.data, dispatch));
  });

}

export const FetchCustomerDetails = () => async (dispatch, getState) => {

  const customerId = getState().auth.customerId == null ? localStorage.getItem('customerId') : getState().auth.customerId;
  const authToken = getState().auth.authToken == null ? localStorage.getItem('authToken') : getState().auth.authToken;

  if (typeof customerId === 'undefined' || customerId === null) {
    dispatch({ type: SIGN_OUT });
    history.push('/');
  }
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const url = `${apiBaseUrl}/customer/${customerId}`;

  axios.defaults.headers.common['auth-token'] = authToken;

  axios(url, {
    method: 'GET',
  }).then(function (response) {
    const customer = response.data.data.customer;
    LogRocket.identify(customer.id, {
      name: customer.firstName + ' ' + customer.lastName,
      email: customer.email,
    });
    dispatch({ type: FETCH_CUSTOMER, payload: response.data });
  }).catch((error) => {
    dispatch(apiFailure(error.response.data, dispatch));
  });
};

export const CustomerEnquiry = (values, dispatch) => {

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const customerId = localStorage.getItem('customerId');
  const authToken = localStorage.getItem('authToken');
  const url = `${apiBaseUrl}/customer/${customerId}/enquiry`;

  axios.defaults.headers.common['auth-token'] = authToken;
  return axios(url, {
    method: 'POST',
    data: values,
  }).then(function (response) {

    dispatch({ type: CUSTOMER_ENQUIRY_SENT, payload: response.data });

  }).catch((error) => {

    const validationsErrors = getErrorMessage(error.response.data.message, 'Please update the details and resubmit it again');

    window.scrollTo(0, 0);
    throw new SubmissionError(validationsErrors)
  });
}

export const apiFailure = (payload, dispatch) => {

  if (payload.statusCode === 401) {
    dispatch({ type: SIGN_OUT });
    history.push('/');
  }

  return {
    type: API_FAILURE,
    payload: payload,
  }
}

export const handleResetClosePopup = () => {
  return {
    type: RESET_PASSWORD_COMPLETED
  };
}

