import React from "react";
import {
  Navigation,
} from "@payright/web-components";
import { NotFoundHeader, NotFoundContent } from "./css";
import {
  CUSTOMER_MENU_ITEMS,
  SUB_MENU_ACTIONS,
} from "../../util/constants/types";
import Footer from "../Footer";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Notifications } from "../Notifications/Notifications";

const NotFound = (props) => {
    
    return (
        <div>
            <Navigation
                breakpoint="wide"
                portalType={props.isSignedIn ? "customer" : "ecommerce"}
                menuItems={props.isSignedIn ? CUSTOMER_MENU_ITEMS : null}
                activePage=""
                notifications={Notifications(props.notification) || null}
                accountDetailsActions={props.isSignedIn ? SUB_MENU_ACTIONS : null}
                userAccount={props.isSignedIn}
            />
            <NotFoundHeader>Page Not Found</NotFoundHeader>
            <NotFoundContent>Sorry the page you are looking for cannot be found, try using the menu above</NotFoundContent>
            <Footer></Footer>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn
    };
};

export default withRouter(
  connect(mapStateToProps, null)(NotFound)
);

