import {
  theme,
  styled,
  media,
} from '@payright/web-components';

const ResetPassword = styled.div`
    display: flex;
   .resetPassword-wrapper {
         width: 100%;
        .alerts {
          max-width: 700px;
          min-width: 550px;
          margin-top: 115px;
          vertical-align:middle;

          ${media.max.medium} {
            min-width: 100px;
            max-height: 300px;
            margin-top: 90px;
          }
          
           ${media.min.medium} {
            min-width: 300px;
            max-height: 300px;
            margin-top: 90px;
           }
        }
        .buttonClass {
            margin-top: 50px;
            ${media.max.medium} {
            margin-top: 30px;
          }
        }
        .resetPassword-header {
          display: flex;
          justify-content: center;
          ${media.max.medium} {
          height:36px;
             font-size:1.0rem;
             font-weight:bold;
          }
          margin-bottom: 3em;
          h4 {
            font-weight: normal;
            justify-content: flex-start;
            padding-left: 0.86em;
            display: flex;
            align-items: center;

            ${media.max.medium} {
              font-size: 1.266em;
              width: unset;
              color: ${props => theme.colours.plum.base};
              
            }
          }
        }// ends header class

         ${media.max.medium} {
            button {
             height:36px;
            }
          }
        
        .input-field {
            margin-bottom: 2em;
            justify-content: center;
            ${media.max.medium} {
              input {
              height:36px;
              }
              
            }
           
        }
        .validation-msg-box {
            background-color: #ffd2d3;
            border-radius: 5px;
            padding: 0.68em;
            color: #ff3b3b;
            margin-bottom: 0.5em;
            width: 100%;
          }
      }
      
    .messageBox {
        border-radius: 5px;
        padding: 2.66em;
        .title {
          color: ${props => props.theme.colours.blue.base};
          margin-bottom: 1em;
        }
        .message {
          font-size: 0.93em;
          line-height: 1.86;
        }
    }
     
    ${media.max.wide} {
    padding: 1.6em;
    }

    ${media.max.medium} {
      
      padding: 1.6em;
        .resetPassword-header {
          border-bottom: 1px solid ${props => theme.colours.grey.light};
          padding-bottom: 1em;
          svg {
            width: 130px;
            height: 33px;
          }
          ${media.max.large} {
            
            svg {
              width: 115px;
            }
          }
          h4 {
          }
        }
      }
  `;
export default ResetPassword;