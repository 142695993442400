import * as React from "react";
import PlanDetailDashboard from "./PlanDashboard";
import PlanDetailPage from "./PlanDetail";
import { connect } from "react-redux";
import { fetchPlanDetail } from "../../actions/planDetailAction";
import {
  CUSTOMER_MENU_ITEMS,
  SUB_MENU_ACTIONS
} from "../../util/constants/types";
import Loader from "../Common/Loader";
import { SCCustomerNavigation } from "./css";
import history from "../../history";
import { HeaderCustomer, Navigation } from "@payright/web-components";

const title = "Plan Details";

class ViewPlan extends React.Component<any, any> {
  handleMakeAPayemnt() {
    history.push("/payments");
  }

  componentDidMount() {
    this.props.dispatch(fetchPlanDetail(this.props.match.params.id));
  }

  render() {
    const { error, loading, planDetail } = this.props;

    // Object for plan Dashboard
    const planDashBoardData = {
      planName: planDetail.name,
      storeName: planDetail.storeName,
      paymentFrequency: planDetail.estimateRepaymentRemaining,
      paidAmount: planDetail.paidAmount,
      remainingBalance: planDetail.remainingBalance,
      planStatus: planDetail.status && planDetail.status.toLowerCase(),
      icon: ""
    };

    // TODO Unused let variable
    // let showMakeaPaymentButton;

    // if (planDetail.status === "Active") {
    //   showMakeaPaymentButton = (
    //     <SCHeaderButton maxWidth="209px" handleClick={this.handleMakeAPayemnt}>
    //       Make a Payment
    //     </SCHeaderButton>
    //   );
    // }

    if (error != null) {
      return <div>Error! {error.message}</div>;
    }

    if (loading) {
      return <Loader text="Loading...." />;
    }

    return (
      <SCCustomerNavigation>
        <Navigation
          portalType="customer"
          menuItems={CUSTOMER_MENU_ITEMS}
          activePage="/my-plans"
          accountDetailsActions={SUB_MENU_ACTIONS}
        />
        <HeaderCustomer
          viewPreviousLink={true}
          previousPagePath="/my-plans"
          title={title}
        />
        <PlanDetailDashboard planDashBoardData={planDashBoardData} />
        <PlanDetailPage planDetailPageData={planDetail} />
      </SCCustomerNavigation>
    );
  }
}

const mapStateToProps = state => {
  return {
    planDetail: state.planDetail.planDetailData,
    loading: state.planDetail.loading,
    error: state.planDetail.error
  };
};

export default connect(mapStateToProps)(ViewPlan);
