import {
    styled,
    media
} from '@payright/web-components';

export const SCContactUs = styled.div`
  
`;

export const ContactUsContainer = styled.div`
    background-color: #fff;
    border: 1px solid #F2F2F2;
    -webkit-box-shadow: 3px 3px 5px 6px #ccc;
    -moz-box-shadow:    3px 3px 5px 6px #ccc; 
    box-shadow:         3px 3px 5px 6px #ccc;
    border-radius: 10px;
    margin-top:5em;
    padding: 3em;
    margin-bottom: 2em;
    width: 50%
    margin-left: auto;
    margin-right: auto;

    ${media.max.large} {
      width: 90%;
    }

    .page-title {
        color: #531dff;
        padding-bottom: 1em;
    }

    .input-field {
        padding-bottom: 1em;
    }
    .form-content {
        margin-top: 1.5em;
    }
    .alerts {
        margin-bottom: 2em;
    }
    .save-btn {
        max-width:20% 
        ${media.max.large} {
            max-width:50%
        }
    }
`;