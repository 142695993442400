export const TransactionMainRow = (transactionMainRowArray) => {
    const transactionMainRowData = transactionMainRowArray.map(transactionListData => {
        const returnValueMainRow = transactionListData.transactionList;
        const subRowData = transactionListData.transactionsParts;
        if (subRowData !== undefined) {
            returnValueMainRow.expand = true;
            if (returnValueMainRow.paymentAmount < 0) {
                returnValueMainRow.cssClass = 'ReversePaymentClassName';
            } else {
                returnValueMainRow.cssClass = '';
            }
        } else {
            returnValueMainRow.expand = false;
            returnValueMainRow.cssClass = '';
        }
        return returnValueMainRow
    });
    return transactionMainRowData;
}