import * as React from "react";
import Moment from "moment";
import {
  Grid,
  GridColumn as Column
} from "@progress/kendo-react-grid";
import "@progress/kendo-react-data-tools";
import "@progress/kendo-react-buttons";
import "@progress/kendo-react-dropdowns";
import "@progress/kendo-react-inputs";
import "@progress/kendo-react-dateinputs";
import "@progress/kendo-react-animation";
import "@progress/kendo-react-popup";
import "@progress/kendo-drawing";
import { Statement } from "./css";
import logo from "./logo.png";
import { COMPANY_ABN_AU, COMPANY_NZBN_NZ } from "../../util/constants/messages";

type exportStatementRows = {
  dateApplied?: string;
  estFees?: string;
  fromDate?: string;
  openingBalance?: string;
  otherFees?: string;
  paymentPurpose?: string;
  planName?: string;
  principalFees?: string;
  toDate?: string;
  key?: string;
  openingBalanceForFirstRow?: string;
  ID?: string;
};

type exportCustomerStatementRow = {
  totalBalanceOwing?: string;
  fullName?: string;
  street?: string;
  email?: string;
  suburb?: string;
  state?: string;
  postcode?: string;
  approvalLimit?: string;
  availableCredit?: string;
  paymentFrequency?: string;
  allowFurtherBusiness?: string;
};

type exportNextPaymentRow = {
  nextPaymentDate?: string;
  repaymentAmount?: string;
};

type exportPDFStatement = {
  exportStatementRows: exportStatementRows[];
  exportCustomerStatementRow: exportCustomerStatementRow;
  exportNextPaymentRow: exportNextPaymentRow;
};

class ExportPDF extends React.Component<exportPDFStatement> {
  grid;
  image;
  render() {
    const region = process.env.REACT_APP_CURRENT_REGION;

    let statements = this.props.exportStatementRows;
    const customer = this.props.exportCustomerStatementRow;
    const nextPayments = this.props.exportNextPaymentRow;
    let closingBalance;
    let nextpaymentdate;
    let nextpaymentamount;

    closingBalance = parseFloat(customer.totalBalanceOwing).toFixed(2);

    nextpaymentdate = nextPayments.nextPaymentDate;
    if (nextpaymentdate === "") {
      nextpaymentdate = "N/A";
    } else {
      nextpaymentdate = Moment(nextpaymentdate).format("DD/MM/YYYY");
    }

    nextpaymentamount = nextPayments.repaymentAmount;
    if (nextpaymentamount === "") {
      nextpaymentamount = 0;
    } else {
      nextpaymentamount = parseFloat(nextpaymentamount).toFixed(2);
    }

    let previousDate = "";
    let dateNow = Moment().format("DD/MM/YYYY");

    let startDate = " ";
    let toDate = " ";
    if (statements[0] !== null) {
      startDate = Moment(statements[0].fromDate).format("DD/MM/YYYY");
      toDate = Moment(statements[0].toDate).format("DD/MM/YYYY");
    }

    const customCellOtherFees = props => {
      if (props.dataItem.otherFees !== 0 || props.dataItem.otherFees !== "") {
        return (
          <td className="OtherFees" colSpan={props.colSpan} style={props.style}>
            $ {props.dataItem.otherFees}
          </td>
        );
      } else {
        return (
          <td colSpan={props.colSpan} style={props.style}>
            {props.dataItem.otherFees}
          </td>
        );
      }
    };
    const customCellEstFees = props => {
      if (props.dataItem.estFees !== 0) {
        return (
          <td className="estFees" colSpan={props.colSpan} style={props.style}>
            $ {props.dataItem.estFees}
          </td>
        );
      } else {
        return (
          <td colSpan={props.colSpan} style={props.style}>
            {props.dataItem.estFees}
          </td>
        );
      }
    };
    const customCellPrincipalAmount = props => {
      if (props.dataItem.principalFees !== 0) {
        return (
          <td
            className="principalAmount"
            colSpan={props.colSpan}
            style={props.style}
          >
            $ {props.dataItem.principalFees}
          </td>
        );
      } else {
        return (
          <td colSpan={props.colSpan} style={props.style}>
            {props.dataItem.principalFees}
          </td>
        );
      }
    };

    const customCellDate = props => {
      if (Date.parse(props.dataItem.dateApplied)) {
        if (props.dataItem.paymentPurpose === "Opening Balance") {
          return (
            <td colSpan={props.colSpan} className="date" style={props.style}>
              {Moment(props.dataItem.dateApplied).format("DD/MM/YYYY")}
            </td>
          );
        }
        if (previousDate !== props.dataItem.dateApplied) {
          previousDate = props.dataItem.dateApplied;

          return (
            <td colSpan={props.colSpan} className="date" style={props.style}>
              {Moment(props.dataItem.dateApplied).format("DD/MM/YYYY")}
            </td>
          );
        } else {
          previousDate = props.dataItem.dateApplied;

          return (
            <td
              colSpan={props.colSpan}
              className="date"
              style={props.style}
            ></td>
          );
        }
      } else {
        return (
          <td colSpan={props.colSpan} className="date" style={props.style}></td>
        );
      }
    };

    const customCellBalance = props => {
      return (
        <td className="balance" colSpan={props.colSpan} style={props.style}>
          $ {props.dataItem.openingBalance}
        </td>
      );
    };

    if (
      !statements.filter(function(elem) {
        return elem.key === "key1";
      }).length
    ) {
      statements = [
        {
          ID: "",
          dateApplied: statements[0].dateApplied,
          estFees: "",
          otherFees: "",
          planName: "",
          paymentPurpose: "Opening Balance",
          principalFees: "",
          openingBalance: statements[0].openingBalanceForFirstRow
        },
        ...statements
      ];
    }

    statements.map(item => {
      let principalFeesFloatValue = parseFloat(item.principalFees).toFixed(2);
      let otherFeesFloatValue = parseFloat(item.otherFees).toFixed(2);
      let estFeesFloatValue = parseFloat(item.estFees).toFixed(2);

      if (otherFeesFloatValue === "0.00") {
        item.otherFees = "";
      }
      if (estFeesFloatValue === "0.00") {
        item.estFees = "";
      }

      if (principalFeesFloatValue === "0.00") {
        item.principalFees = "";
      }
    });

    return (
      <Statement>
        <header className="pdfheader">
          <div className="logo extra-padding pdf-font-size">
            <div>
              <img src={logo} alt="Logo"/>
            </div>

            <br></br>
            {region === "au" ? (
              <span>{COMPANY_ABN_AU}</span>
            ) : (
              <span>{COMPANY_NZBN_NZ}</span>
            )}
            <br></br>
            <span>Trading as Payright</span>
          </div>
          <div className="pdftopright extra-padding">
            <h2>STATEMENT</h2>
          </div>
        </header>

        <div className="pdfcustomer">
          <div className="pdfmleft pdf-left-margin">
            <p>{customer.fullName}</p>
            <p>{customer.street}</p>
            <p>
              {customer.suburb} {customer.state} {customer.postcode}
            </p>
            <br></br>
            <p>{customer.email}</p>
          </div>
          <div className="pdfmright pdf-margin">
            <p>
              Statement Period: {startDate} to {toDate}
            </p>
            <p>Date Created: {dateNow}</p>
            <p>
              Approved Limit: $
              {customer.allowFurtherBusiness === "No"
                ? 0
                : parseFloat(customer.approvalLimit).toFixed(2)}
            </p>
            <p>
              Available Credit: $
              {customer.allowFurtherBusiness === "No"
                ? 0
                : parseFloat(customer.availableCredit).toFixed(2)}
            </p>
            <p>Repayment Frequency: {customer.paymentFrequency}</p>
            <p>Next Payment Date: {nextpaymentdate}</p>
            <p>Next Repayment Amount: ${nextpaymentamount}</p>
          </div>
        </div>
        <div className="pdf pdf-font-size pdf-margin-left">
          <Grid ref={grid => (this.grid = grid)} data={statements}>
            <Column
              field="dateApplied"
              title="Payment Date"
              className="date"
              width="50px"
              cell={customCellDate}
            />
            <Column
              field="planName"
              title="Plan ID"
              className="planId"
              width="72px"
            />
            <Column
              field="paymentPurpose"
              title="Payment Type"
              className="purpose"
              width="151px"
              footerCell={props => (
                <td
                  className="closingBalance"
                  colSpan={props.colSpan}
                  style={props.style}
                >
                  Closing Balance:
                </td>
              )}
            />
            <Column
              field="principalFees"
              title="Principal Amount"
              width="69px"
              cell={customCellPrincipalAmount}
            />
            <Column
              field="estFees"
              title="Establishment Fees"
              width="62px"
              cell={customCellEstFees}
            />
            <Column
              field="otherFees"
              title="Other Fees*"
              width="39px"
              cell={customCellOtherFees}
            />
            <Column
              field="openingBalance"
              title="Outstanding Balance"
              width="60px"
              format="{0:c2}"
              cell={customCellBalance}
              footerCell={props => (
                <td colSpan={props.colSpan} style={props.style}>
                  ${" "}
                  {closingBalance > "0" && closingBalance > "0.00"
                    ? "-" + closingBalance
                    : closingBalance}
                </td>
              )}
            />
          </Grid>
        </div>
        <div
          className="other pdf-font-size pdf-margin-left pdf-max-width"
          style={{
            width: "95%"
          }}
        >
          <p
            style={{
              textDecorationColor: "240050"
            }}
          >
            <b>*</b> 'Other Fees' include the{" "}
            <b>monthly account keeping fee (a total of $3.50</b> spread across
            all payments within the month) and a{" "}
            <b>payment processing fee of $2.95</b> for each payment. These fees,
            and any late fees do not reduce the total of your outstanding
            balance
          </p>
        </div>
      </Statement>
    );
  }
}

export default ExportPDF;
