import _ from 'lodash';
export const FilterResult = (plans, filterState) => {
    
    let filteredPlans;

    filteredPlans = plans.filter(plan => {
       return ( _.includes(filterState,plan.statusCust));
    });

    return filteredPlans;
}