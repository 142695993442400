import axios from "../util/payrightAxios";


export function fetchPlanDetail(planId) {

    return (dispatch, getState) => {
            
        const authToken = getState().auth.authToken == null ? localStorage.getItem('authToken') : getState().auth.authToken ;
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        const url = `${apiBaseUrl}/customer/plans/${planId}`;
        axios.defaults.headers.common['auth-token'] = authToken

        dispatch(fetchPlanDetailBegin());
        return axios({ 
            url: url,
            method:"get",
            
        })
        .then(response => {
            dispatch(fetchPlanDetailSuccess(response.data.data.plan));
            return response.data.data.plan;
        })
        .catch(error => {
            dispatch(fetchPlanDetailFaliure(error));
       });

    }
}

export const FETCH_PLANDETAIL_BEGIN = "FETCH_PLANDETAIL_BEGIN";
export const FETCH_PLANDETAIL_SUCCESS = "FETCH_PLANDETAIL_SUCCESS";
export const FETCH_PLANDETAIL_FALIURE = "FETCH_PLANDETAIL_FALIURE";

export const fetchPlanDetailBegin = () => ({
    type: FETCH_PLANDETAIL_BEGIN
});

export const fetchPlanDetailSuccess = planDetail => ({
   type: FETCH_PLANDETAIL_SUCCESS,
   payload: {planDetail}
});

export const fetchPlanDetailFaliure = error => ({
    type: FETCH_PLANDETAIL_FALIURE,
    payload: {error},
});