import {
    FETCH_TRANSACTION_BEGIN,
    FETCH_TRANSACTION_SUCCESS,
    FETCH_TRANSACTION_FAILURE
} from "../actions/types";

const initialState = {
    items: [],
    loading: false,
    error: null
}

export default function transactionReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case FETCH_TRANSACTION_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            }
        case FETCH_TRANSACTION_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.transactions
            }
        case FETCH_TRANSACTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                items: []
            }
        default:
            return state;
    }
}