import React from "react";

import { SCCustomerDetails } from "./css";
import { Field } from "redux-form";
import {
  renderInput,
  renderDropDown,
} from "../Common/FormFields";
import { TOOLTIP_NON_EDITABLE_FIELDS } from "../../util/constants/messages";
import { NEWZEALAND_REGIONS } from "../../util/constants/forms";

const PersonalDetails = ({ config }) => {
  const currentRegion = process.env.REACT_APP_CURRENT_REGION;
  let showMiddleName;
  let stateLabel;
  if (currentRegion === "nz") {
    showMiddleName = (
      <Field
        placeholder="Middle Name"
        name="middleName"
        label="Middle Name"
        type="text"
        readOnly={true}
        className="middle-name"
        tooltip={TOOLTIP_NON_EDITABLE_FIELDS}
        component={renderInput}
      />
    );
    stateLabel = "Region"; // formerly 'Town/City' label
    config.states = NEWZEALAND_REGIONS; // override default options from web-components
  } else {
    stateLabel = "State";
  }

  return (
    <SCCustomerDetails>
      <div className="title-firstname-wrapper">
        <Field
          placeholder="Title"
          name="title"
          label="Title"
          type="text"
          maxWidth="15%"
          readOnly={true}
          className="title"
          tooltip={TOOLTIP_NON_EDITABLE_FIELDS}
          component={renderInput}
        />

        <Field
          placeholder="First Name"
          name="firstName"
          label="First Name"
          type="text"
          maxWidth="34%"
          readOnly={true}
          className="first-name"
          tooltip={TOOLTIP_NON_EDITABLE_FIELDS}
          component={renderInput}
        />

        {showMiddleName}

        <Field
          placeholder="Last Name"
          name="lastName"
          label="Last Name"
          type="text"
          readOnly={true}
          className="last-name"
          tooltip={TOOLTIP_NON_EDITABLE_FIELDS}
          component={renderInput}
        />
      </div>
      <div className="title-firstname-wrapper">
        <Field
          name="email"
          label="Email"
          type="text"
          readOnly={true}
          className="email"
          tooltip={TOOLTIP_NON_EDITABLE_FIELDS}
          component={renderInput}
        />

        <Field
          name="confirmEmail"
          label="Confirm Email"
          type="text"
          readOnly={true}
          className="confirmEmail"
          tooltip={TOOLTIP_NON_EDITABLE_FIELDS}
          component={renderInput}
        />
      </div>
      <div className="title-firstname-wrapper">
        <Field
          name="phoneMobile"
          label="Customer Mobile No."
          type="text"
          readOnly={true}
          tooltip={TOOLTIP_NON_EDITABLE_FIELDS}
          className="phone"
          component={renderInput}
        />

        <Field
          name="formatedDateOfBirth"
          label="Date of Birth"
          type="datepicker"
          readOnly={true}
          className="phone"
          tooltip={TOOLTIP_NON_EDITABLE_FIELDS}
          component={renderInput}
        />
      </div>
      <hr id="details-hr" />
      <div className="address-body">
        <Field
          placeholder="Street"
          name="street"
          label="Street Address"
          type="text"
          className="street"
          component={renderInput}
        />
        <div className="address-wrapper">
          <Field
            placeholder="Suburb"
            name="suburb"
            label="Suburb"
            type="text"
            className="suburb"
            component={renderInput}
          />

          <Field
            placeholder="--Please select--"
            name="state"
            label={stateLabel}
            type="select"
            className="state"
            options={config.states}
            component={renderDropDown}
          />

          <Field
            placeholder="Postcode"
            name="postcode"
            label="Postcode"
            type="text"
            className="postcode"
            component={renderInput}
          />

          {/* <Field
					   placeholder='--Please select--'
					   name="residentialStatus" 
					   label="Residential Status" 
					   type="select"
					   className="residentialStatus"
					   options={config.residentialStatues}
					   component={renderDropDown} /> */}
        </div>
      </div>
    </SCCustomerDetails>
  );
};

export default PersonalDetails;
