import * as React from "react";
import {
  IconAccount,
  IconLogout,
  IconLogoSmall,
  IconQuestion,
  IconPayment
} from "@payright/web-components";
export const CUSTOMER_MENU_ITEMS = [
  { name: "Dashboard", link: "/dashboard" },
  { name: "My Plans", link: "/my-plans" },
  { name: "Transactions", link: "/my-transactions" },
  { name: "Statements", link: "/statements" },
  { name: "Make a Payment", link: "/payments" }
];
const region = process.env.REACT_APP_CURRENT_REGION;

let needHelpLink;
if (region === "au") {
  needHelpLink = "https://www.payright.com.au/faqs/";
} else {
  needHelpLink = "https://www.payright.co.nz/faqs/";
}

export const SUB_MENU_ACTIONS = [
  {
    icon: <IconAccount />,
    iconColour: "blue",
    label: "Account Profile",
    link: "/profile"
  },
  {
    icon: <IconLogoSmall />,
    label: "Contact Payright",
    link: "/contact-us"
  },
  {
    icon: <IconPayment />,
    label: "Update Card Details",
    link: "/payments#change-card"
  },
  {
    icon: <IconQuestion />,
    label: "Need Help?",
    link: needHelpLink,
    target: "_blank"
  },
  {
    icon: <IconLogout />,
    label: "Logout",
    iconColour: "red",
    labelColour: "red",
    link: "/logout"
  }
];

export const PLAN_STATUS_OPTIONS = {
  Active: false,
  Approved: true,
  Pending: true,
  Approved2: true,
  "Pending ID": false,
  "Active 9.2": true,
  "9.2": false
};

export const PLAN_TABLE_HEADER = [
  { name: "Plan Name", id: "planName.name", sortable: true },
  { name: "Status", id: "statusCust", sortable: true },
  { name: "Payments Remaining", id: "payRemain", sortable: true },
  { name: "Paid ($)", id: "paidLoanAmount", sortable: true },
  { name: "Remaining ($)", id: "balanceRemaining", sortable: true },
  { name: "", id: "buttonUrl", sortable: false }
];

export const TRANSACTION_TABLE_HEADER = [
  { name: "Date Processed", id: "requestCreated", sortable: true },
  { name: "Payment Description", id: "paymentPurpose", sortable: true },
  { name: "Payment Reference", id: "paymentReference", sortable: true },
  { name: "Payment Type", id: "paymentStatus", sortable: false },
  { name: "Amount ($)", id: "paymentAmount", sortable: true },
  { name: "More Details", id: "action", sortable: false }
];

export const EXTRATRANSACTION_TABLE_HEADER = [
  { name: "Date", id: "dateApplied", sortable: false },
  { name: "Transaction Type", id: "transactionReason", sortable: false },
  { name: "Relates to", id: "paymentPlanName", sortable: false },
  { name: "Amount ($)", id: "paidAmount", sortable: false }
];

export const TRANSACTION_FILTER = [
  { name: "Last 30 Days", value: 30 },
  { name: "Last 60 Days", value: 60 },
  { name: "Last 90 Days", value: 90 },
  { name: "Last 180 Days", value: 180 }
];

export const USER_PASSWORD_STATUS = "User Change Required";
export const TRANSACTION_DEFAULT_FILTER_VALUE = 30;

export const PASSWORD_SUCCESSFUL_TITLE = "Password Reset!";
export const PASSWORD_NEXT_LOGIN =
  "Please use this new password on your next login. ";

// Paging Configuration
export const RECORDS_PER_PAGE = 10;
export const LOAD_PER_PAGE = 10;

// Not Found Records in Tables
export const TRANSACTION_NOT_FOUND =
  "No transactions found within selected days, please select another date range.";
export const PLAN_NOT_FOUND = "No Plans Found";

//Forgot Password
export const SUCCESSFUL_FORGOT_PASSWORD_TITLE = "SMS has been sent!";
export const SUCCESSFUL_FORGOT_PASSWORD_MESSAGE =
  "A temporary password has been sent to your mobile number. Use that password with your email address to login and create a new password.";
export const FORGOT_PASSWORD_CONTACT_US_MESSAGE = "Didn’t receive an SMS? Call 1300 338 496";

//Date range selected Error
export const FROM_DATE_LESS_THAN_TO_DATE =
  "From date should be less than To date.";
